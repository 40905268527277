import React from 'react';
import { Checkbox, Divider, Form } from 'antd';

import LegalLink from 'components/views/LegalInformation/Components/LegalLink';

import { ROUTES } from 'consts/routes';
import { BORROWER_NOMENCLATURE } from 'redux/app/constants';
import {
  useCustomerIdentityIsComplete,
  useEnterApplicantDataManuallyData
} from 'hooks/data';
import { useSelector } from 'react-redux';
import { selectIsForQRCode } from 'redux/app/selectors';
import { FONT_SIZE_SMALL } from 'theme/variables';

function Consent({ applicantType = BORROWER_NOMENCLATURE }) {
  const customerIdentityIsComplete =
    useCustomerIdentityIsComplete(applicantType);
  const isForQRCode = useSelector(selectIsForQRCode);
  const isTheFirstStepForQRCode = isForQRCode && !customerIdentityIsComplete;
  const applicantDataManually = useEnterApplicantDataManuallyData(
    BORROWER_NOMENCLATURE
  );

  const customStyle = {
    lineHeight: 'unset',
    height: 0,
    fontSize: FONT_SIZE_SMALL
  };

  if (isTheFirstStepForQRCode || !applicantDataManually) {
    return null;
  }

  return (
    <>
      <Divider style={{ marginTop: 0 }} />
      <Form.Item
        name="verified"
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: 'This field is required.'
          }
        ]}
      >
        <Checkbox style={{ fontSize: FONT_SIZE_SMALL }}>
          By checking “Submit” below, you are providing written instruction to
          Service Finance Company, LLC to obtain your consumer credit report
          from one or more consumer reporting agencies to evaluate your request
          for prequalification. You agree that the information that you are
          providing is true, correct and accurate to the best of your knowledge,
          and that you are at least 18 years of age. You understand that by
          submitting this request for prequalification that Service Finance may
          perform one or more soft credit inquiries to consumer reporting
          agencies which will not affect your credit score. If you choose to
          continue your application, then Service Finance will request a full
          credit report from one or more consumer reporting agencies, which will
          be a “hard” credit pull which can affect your credit score and can be
          seen by third parties. A prequalification approval is not a guaranty
          of a full application approval. Please read the following important
          notices from Service Finance before submitting your request for
          prequalification:{' '}
          <LegalLink route={ROUTES.STATEMENT} style={customStyle} />,{' '}
          <LegalLink route={ROUTES.DISCLOSURES} style={customStyle} />,{' '}
          <LegalLink route={ROUTES.COPPA_DISCLOSURE} style={customStyle} />,{' '}
          <LegalLink route={ROUTES.PRIVACY_POLICY} style={customStyle} />, and
          for California residents,{' '}
          <LegalLink
            route={ROUTES.CONSUMER_RIGHTS_NOTICE}
            style={customStyle}
          />
          . You acknowledge and agree that Service Finance may share your
          prequalification decision and terms of available financing offers if
          you qualify with the Dealer to facilitate the purchase of the goods
          and/or services and the finance transaction for their purchase, and to
          market to you in connection with this potential purchase.
        </Checkbox>
      </Form.Item>
    </>
  );
}

export default Consent;

// By checking this box, you agree that you have read and acknowledged
//           the following: <LegalLink route={ROUTES.STATEMENT} />,{' '}
//           <LegalLink route={ROUTES.DISCLOSURES} />,{' '}
//           <LegalLink route={ROUTES.COPPA_DISCLOSURE} />,{' '}
//           <LegalLink route={ROUTES.PRIVACY_POLICY} />.
