import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectApplyFormResult,
  selectStoppedCurrentApplication,
  selectRetrieveApplicationData
} from 'redux/app/selectors';
import ApplyMain from './Form';
import ResultMessage from './ResultMessage';
import StopInCurrentView from './ResultMessage/StopInCurrentView';
import RetrievingData from './ResultMessage/RetrievingData';
import { GETTING_DATA_STATUS } from 'redux/app/constants';

function Apply() {
  const applyFormResult = useSelector(selectApplyFormResult);
  const retrieveApplicationData = useSelector(selectRetrieveApplicationData);
  const stoppedCurrentApplication = useSelector(
    selectStoppedCurrentApplication
  );

  if (stoppedCurrentApplication) {
    return <StopInCurrentView />;
  }

  if (retrieveApplicationData === GETTING_DATA_STATUS.IN_PROGRESS) {
    return <RetrievingData />;
  }

  return applyFormResult ? <ResultMessage /> : <ApplyMain />;
}

export default Apply;
