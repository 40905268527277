import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { useIsMobile } from 'hooks/responsive';
import { buildAssociationCallReportPayload } from 'utils/functions';

import { Input, Typography } from 'antd';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';

import { Button } from 'components/common/Buttons';

import { reloadCallReport, setSearchText } from 'redux/app/actions';

import {
  selectAssociationCallReportIsFetching,
  selectAssociationReloadCallReportIsFetching,
  selectSearchText
} from 'redux/app/selectors';

import { APPLY_DEALER_AUTH_BASE_URL } from 'config/variables';
import { FONT_SIZE_SMALL } from 'theme/variables';
import { FORM_FIELDS_NAMES } from '../../FindDealerForm';

const { Text } = Typography;

export function Reload({ form }) {
  const dispatch = useDispatch();
  const { associationNumber } = useParams();
  const isMobile = useIsMobile();
  const isFetching = useSelector(selectAssociationReloadCallReportIsFetching);

  return (
    <Button
      type="secondary"
      loading={isFetching}
      icon={<SyncOutlined />}
      disabled={!form.getFieldValue(FORM_FIELDS_NAMES.ZIP_CODE)}
      onClick={() => {
        const values = form.getFieldsValue(true);
        const payload = buildAssociationCallReportPayload(
          +values?.zipCode,
          values?.distance,
          associationNumber
        );
        if (!isEmpty(payload)) {
          dispatch(reloadCallReport({ ...payload, reload: true }));
        }
      }}
    >
      {isMobile ? '' : 'Reload'}
    </Button>
  );
}

export const ApplyNowButton = ({ dealerNumber }) => {
  const isMobile = useIsMobile();

  return (
    <Button
      style={{ ...(isMobile && { width: '100%' }) }}
      type="secondary"
      onClick={() =>
        window.open(`${APPLY_DEALER_AUTH_BASE_URL}${dealerNumber}`)
      }
    >
      Apply Now
    </Button>
  );
};

export const ContactInfo = ({ contact }) => {
  return (
    <div>
      <Text strong style={{ fontSize: FONT_SIZE_SMALL, display: 'block' }}>
        {contact.email?.email}
      </Text>
      <Text style={{ fontSize: FONT_SIZE_SMALL, display: 'block' }}>
        {contact?.phone?.display}
      </Text>
    </div>
  );
};

export function Search({ style, size = 'small', ...props }) {
  const dispatch = useDispatch();
  const searchText = useSelector(selectSearchText);

  return (
    <Input
      prefix={<SearchOutlined />}
      placeholder="Search"
      value={searchText}
      onChange={e => {
        dispatch(setSearchText(e.target.value));
      }}
      allowClear
      style={{ padding: '5px 10px', fontSize: FONT_SIZE_SMALL, ...style }}
      size={size}
      {...props}
    />
  );
}

export function DataSourceTotal({ total, size = 'small', style, ...props }) {
  if (!total) {
    return false;
  }

  return (
    <Text
      style={{
        ...(size === 'small' && { fontSize: FONT_SIZE_SMALL }),
        ...style
      }}
      {...props}
    >
      Total: <Text strong>{total}</Text>
    </Text>
  );
}
