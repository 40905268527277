import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { buildAssociationCallReportPayload } from 'utils/functions';
import { useIsMobile } from 'hooks/responsive';

import { Alert, Divider, Form, Skeleton } from 'antd';

import TwoColumns from 'components/common/Content/TwoColumns';
import HomeDefaultMessage from '../Home/DefaultMessage';
import DealersSet from './DealersSet';
import FindDealerForm from './FindDealerForm';
import NoData from './DealersSet/Components/NoData';
import { ImageWithValidation } from 'components/common/Imagen';

import {
  selectAssociationCallReport,
  selectAssociationCallReportIsFetching,
  selectAssociationNumber,
  selectHaveDealersData,
  selectHaveErrorInAssociationData
} from 'redux/app/selectors';

import {
  getCallReport,
  resetApp,
  setCurrentAssociationNumber
} from 'redux/app/actions';

import { RESOURCE_BASE_URL } from 'config/variables';

function AssociationEntryContent({ form }) {
  const data = useSelector(selectAssociationCallReport);
  const haveErrorInAssociationData = useSelector(
    selectHaveErrorInAssociationData
  );
  const haveDealersData = useSelector(selectHaveDealersData);
  const isFetching = useSelector(selectAssociationCallReportIsFetching);

  if (haveErrorInAssociationData && !data) {
    return (
      <>
        <Alert
          style={{ marginBottom: 5 }}
          type="error"
          message="An error occurred while trying to find dealers. Please try again and if the problem persist contact us."
        />
      </>
    );
  }

  if (isFetching) {
    return <Skeleton />;
  }

  if (data && !haveDealersData) {
    return (
      <NoData message="No results were found for the selected search criteria." />
    );
  }

  if (haveDealersData) {
    return <DealersSet form={form} />;
  }

  return <HomeDefaultMessage />;
}

function Associations() {
  const dispatch = useDispatch();

  const currentAssociationNumber = useSelector(selectAssociationNumber);
  const { associationNumber } = useParams();
  const isMobile = useIsMobile();

  const [form] = Form.useForm();

  const imageSetting = {
    source: RESOURCE_BASE_URL + `Images/map.jpeg`,
    position: '55% 35%'
  };

  useEffect(() => {
    if (associationNumber !== currentAssociationNumber) {
      dispatch(resetApp());
      dispatch(setCurrentAssociationNumber(associationNumber));

      // const { zipCode, distance } = form.getFieldsValue(true);
      // if (zipCode && distance) {
      //   const payload = buildAssociationCallReportPayload(
      //     +zipCode,
      //     distance,
      //     associationNumber
      //   );
      //   dispatch(getCallReport(payload));
      // }
    }
  }, []);

  return (
    <TwoColumns
      title="Please enter your location"
      md={20}
      imageSrc={imageSetting.source}
      imageDirection={imageSetting.position}
      overflowContent="none"
      extraLogo={
        <ImageWithValidation
          src={
            RESOURCE_BASE_URL +
            `Associations/${associationNumber}/${
              isMobile ? 'logo_mobile' : 'logo'
            }.png`
          }
          alt={`association-${associationNumber}-logo`}
          width={isMobile ? '35px' : '160px'}
        />
      }
    >
      <>
        <FindDealerForm form={form} />
        <Divider />
        <AssociationEntryContent form={form} />
      </>
    </TwoColumns>
  );
}

export default Associations;
