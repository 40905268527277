import React, { memo, useEffect, useState } from 'react';

import { FormItem } from 'components/common/Form';
import { InputNumber, Space } from 'antd';
import MonthlyIncomeMessage from './MonthlyIncomeMessage';
import LabelInfo from '../../Components/LabelInfo';

const currencyFormatter = value => {
  if (value.trim() === '') {
    return '';
  }
  const formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'decimal'
  }).format(value);

  return formattedNumber;
};

const currencyParser = (val, locale = 'en-US') => {
  if (val === '') {
    return null;
  }
  const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
  const numerals = [
    ...new Intl.NumberFormat(locale, {
      useGrouping: false,
      maximumFractionDigits: 2
    }).format(9876543210)
  ].reverse();
  const index = new Map(numerals.map((d, i) => [d, i]));
  const group = new RegExp(
    `[${parts.find(d => d.type === 'group').value}]`,
    'g'
  );
  const decimal = new RegExp(
    `[${parts.find(d => d.type === 'decimal').value}]`
  );
  const numeral = new RegExp(`[${numerals.join('')}]`, 'g');
  const indexFunction = d => index.get(d);

  const result = val
    .trim()
    .replace(group, '')
    .replace(decimal, '.')
    .replace(numeral, indexFunction);

  return result ? +result : NaN;
};

function MonthlyIncome({
  label,
  fieldsPrefix,
  required = false,
  required_rules,
  form
}) {
  const [annualIncome, setAnnualIncome] = useState(0);
  const [showIncomeMessage, setShowIncomeMessage] = useState(true);

  const handleOnChange = value => {
    setAnnualIncome(value * 12);
  };

  const handleTurnOnShowMessage = value => {
    setShowIncomeMessage(!showIncomeMessage);
  };

  let monthlyIncomeRules = [];

  if (required) {
    monthlyIncomeRules.push(required_rules);
  }

  useEffect(() => {
    if (form) {
      const monthlyIncome = form.getFieldValue(`${fieldsPrefix}MonthlyIncome`);
      if (monthlyIncome) {
        setAnnualIncome(monthlyIncome * 12);
      }
    }
  }, []);

  return (
    <Space direction="vertical" size={0} wrap style={{ width: '100%' }}>
      <FormItem
        label={
          <LabelInfo
            text="Monthly Income"
            condition={!showIncomeMessage && annualIncome}
            handleFunction={handleTurnOnShowMessage}
          />
        }
        name={`${fieldsPrefix}MonthlyIncome`}
        rules={monthlyIncomeRules}
        initialValue=""
        // hasFeedback
        // validateStatus="warning"
        // help="Need to be completed"
      >
        <InputNumber
          style={{ width: '100%' }}
          addonBefore={'$'}
          autoComplete="off"
          onChange={handleOnChange}
          placeholder="Monthly Income"
          formatter={currencyFormatter}
          parser={currencyParser}
          value=""
        />
      </FormItem>
      <MonthlyIncomeMessage
        annualIncome={annualIncome}
        condition={annualIncome && showIncomeMessage}
        handleOnClose={handleTurnOnShowMessage}
      />
    </Space>
  );
}

export function Amount({
  title,
  label,
  placeholder,
  fieldsPrefix,
  required = false,
  required_rules,
  handleOnChange,
  form
}) {
  let amountRules = [];

  if (required) {
    amountRules.push(required_rules);
  }

  return (
    <FormItem
      label={label}
      name={`${fieldsPrefix}${title}`}
      rules={amountRules}
    >
      <InputNumber
        style={{ width: '100%' }}
        addonBefore={'$'}
        autoComplete="off"
        onChange={handleOnChange}
        placeholder={placeholder}
        formatter={currencyFormatter}
        parser={currencyParser}
        // max={3000000}
      />
    </FormItem>
  );
}

export default memo(MonthlyIncome);
