export const ROUTES = {
  HOME: '/',
  APPLY_FORM: '/apply',
  DEALER_ID: '/d/:dealerId',
  CUSTOMER_ID: '/c/:dealerId',
  QR_CODE: '/qr/:dealerId',
  BD_CODE: '/bd/:dealerId',
  BE_CODE: '/be/:dealerId',
  ASSOCIATION_NUMBER: '/a/:associationNumber',
  STATEMENT: '/statement',
  DISCLOSURES: '/disclosures',
  COPPA_DISCLOSURE: '/coppa_disclosure',
  PRIVACY_POLICY: '/privacy_policy',
  CONSUMER_RIGHTS_NOTICE: '/consumer_rights_notice',
  NOT_AUTHORIZED: '/403',
  NOT_FOUND: '/404',
  VERIFICATION_WP: '/Verification/:requestId/Mobile',
  VERIFICATION_WOP: '/Verification',
  ERROR: '/error',
  MESSAGE: '/message'
};
