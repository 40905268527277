import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  APPLICANT_NOMENCLATURE_PREFIX_MAP,
  APPLICANT_PREFIX,
  APPLICATION_DETAILS_PREFIX,
  CO_APPLICANT_PREFIX,
  GET_CUSTOMER_IDENTITY,
  GET_CUSTOMER_IDENTITY_FAILED,
  GET_CUSTOMER_IDENTITY_STOP,
  GET_VERIFY_PRODUCT_BY_DEALER,
  PUT_VERIFY_PRODUCT_BY_DEALER,
  SALES_REP_PREFIX,
  SUBMIT_APPLY_FORM,
  VALIDATE_ADDRESS,
  PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL,
  GET_CUSTOMER_IDENTITY_DONE,
  SAVE_CUSTOMER_IDENTITY_ADDRESS,
  APPLY_FROM_QR_CODE_NOMENCLATURE,
  BORROWER_NOMENCLATURE,
  SET_ERROR_MESSAGE,
  GETTING_DATA_STATUS,
  STATUS_COMPLETED,
  APPLY_FROM_IDENTITY_VERIFICATION_NOMENCLATURE,
  SET_MESSAGE,
  MESSAGE_SUCCESS_STATUS_NOMENCLATURE,
  MESSAGE_ERROR_STATUS_NOMENCLATURE,
  GET_CALL_REPORT,
  RELOAD_CALL_REPORT
} from './constants';

import {
  submitApplyFormSuccess,
  submitApplyFormFailed,
  validateAddressSuccess,
  validateAddressFailed,
  submitApplyForm,
  showAddressValidation,
  getVerifyProductByDealerSuccess,
  getVerifyProductByDealerFailed,
  putVerifyProductByDealerSuccess,
  putVerifyProductByDealerFailed,
  getVerifyProductByDealer,
  getCustomerIdentitySuccess,
  getCustomerIdentityFailed,
  enterApplicantDataManually,
  setCustomerIdentityErrorMessage,
  getCustomerIdentityDone,
  getCustomerIdentity,
  putMobileVerificationAsSuccessfulFailed,
  putMobileVerificationAsSuccessfulSuccess,
  showAddressListToSelect,
  closeAddressListToSelect,
  showCoApplicantModal,
  initRetrieveApplicationData,
  setForQRCode,
  savePreviousData,
  initRetrieveApplicationDataSuccess,
  stoppedCurrentApplication,
  initRetrieveApplicationDataFailed,
  setErrorMessage,
  setMessage,
  setForVerificationCode,
  resetVerificationParams,
  getCallReportSuccess,
  getCallReportFailed
} from './actions';

import {
  getVerifyProductByDealerNumber,
  postPullCheckRequest,
  putAddressValidationVerify,
  putVerifyProductByDealerNumber,
  getCustomerIdentityRequest,
  putMobileVerificationAsSuccessfulRequest,
  getCallReportRequest
} from 'api';
import {
  makeSelectCustomerIdentityRequestId,
  makeSelectRequestId,
  selectApplyFrom,
  selectApplyFormValues,
  selectAreAddressesConfirmed,
  selectIsForQRCode,
  selectRetrieveApplicationData,
  selectIsForBDCode,
  selectIsForBECode
} from './selectors';
import {
  formatApplicant,
  formatApplicationDetails,
  formatCustomer,
  formatSalesMan
} from 'utils/functions';
import { selectDealerNumber } from 'redux/tokens/selectors';
import { saveDealerNumber } from 'redux/tokens/actions';
import { replace } from 'connected-react-router';
import { ROUTES } from 'consts/routes';
import {
  WS_NOTIFICATION_CUSTOMER_IDENTITY_PROCESS,
  WS_NOTIFICATION_RECEIVED
} from 'redux/WebSockets/constants';
import { isEmpty } from 'lodash';
import { regularHeimdalAxios } from 'api/axios';
import { DEFAULT_WRONG_MESSAGE, apiRequest } from 'redux/sagas';
import { HTTP_STATUS } from 'api/axios/interceptors';

const selectCustomerIdentityRequestId = makeSelectCustomerIdentityRequestId();
const selectRequestId = makeSelectRequestId();

function* processCustomerFormData(prefix) {
  const values = yield select(selectApplyFormValues);

  const data = [formatCustomer(prefix, values)];

  return data;
}

function* processApplyFormData() {
  const dealerNumber = yield select(selectDealerNumber);
  const values = yield select(selectApplyFormValues);

  const applyFrom = yield select(selectApplyFrom);

  const applicants = [formatApplicant(APPLICANT_PREFIX, values)];
  const coApplicant = formatApplicant(CO_APPLICANT_PREFIX, values);

  applicants[0].SSN = applicants[0].SSN.replace('XXX-XX-', '000-00-');

  if (coApplicant) {
    coApplicant.SSN = coApplicant.SSN.replace('XXX-XX-', '000-00-');
    applicants.push(coApplicant);
  }

  const salesPerson = formatSalesMan(SALES_REP_PREFIX, values);
  const applicationDetails = formatApplicationDetails(
    APPLICATION_DETAILS_PREFIX,
    values
  );

  const data = {
    Applicants: applicants,
    DealerNumber: dealerNumber,
    applyFrom,
    ...salesPerson,
    ...applicationDetails
  };

  return data;
}

function* submitApplyFormSaga() {
  yield takeEvery(SUBMIT_APPLY_FORM, function* () {
    const applyData = yield processApplyFormData();

    const response = yield call(apiRequest, postPullCheckRequest, [applyData]);

    if (!response || !response.isSuccess || !response.data) {
      yield put(submitApplyFormFailed(response));
      return;
    }

    yield put(submitApplyFormSuccess(response.data));
  });
}

function* validateAllAddressesSaga() {
  yield takeEvery(VALIDATE_ADDRESS, function* ({ payload }) {
    const areAddressesConfirmed = yield select(selectAreAddressesConfirmed);
    let allValid = areAddressesConfirmed;

    const applyFrom = yield select(selectApplyFrom);
    const dealerNumber = yield select(selectDealerNumber);

    if (isEmpty(areAddressesConfirmed)) {
      const response = yield call(apiRequest, putAddressValidationVerify, [
        { ...payload, applyFrom, dealerNumber }
      ]);

      if (!response || !response.isSuccess || !response.data) {
        yield put(validateAddressFailed(response));
        return;
      }
      const data = response.data;
      yield put(validateAddressSuccess(data.address));

      allValid = data.address.every(
        item => item?.flags?.IsVerified && item?.flags?.IsEquals
      );
    }
    if (allValid) {
      yield put(showAddressValidation(false));
      yield put(submitApplyForm());
      return;
    }
    yield put(showAddressValidation(true));
  });
}

function* verifyProductByDealerSaga() {
  yield takeLatest(GET_VERIFY_PRODUCT_BY_DEALER, function* ({ payload }) {
    const defaultErrorMsg =
      'Warning! We apologize for the inconvenience. Please review the number entered and try again. Please call 866-254-0497 option 4 for immediate assitance with your account if the number was enterded correctly.';

    const applyFrom = yield select(selectApplyFrom);

    const response = yield call(apiRequest, getVerifyProductByDealerNumber, [
      payload,
      applyFrom
    ]);

    if (!response?.isSuccess) {
      yield put(getVerifyProductByDealerFailed(defaultErrorMsg));
      return;
    }

    yield put(getVerifyProductByDealerSuccess(response.data));
    yield put(saveDealerNumber(payload));
  });
}

function* putVerifyDealerNumberDataSaga() {
  yield takeLatest(PUT_VERIFY_PRODUCT_BY_DEALER, function* ({ payload }) {
    const dealerNumber = yield select(selectDealerNumber);

    if (!dealerNumber) {
      return;
    }

    const applyFrom = yield select(selectApplyFrom);

    const data = {
      dealerNumber: dealerNumber.toString(),
      producId: payload
    };

    const response = yield call(apiRequest, putVerifyProductByDealerNumber, [
      { ...data, applyFrom }
    ]);

    if (!response || !response.isSuccess || !response.data) {
      let defaultErrorMsg =
        "We're sorry! You have not chosen the right product for the Dealer you are applying to.";

      if (response.status === HTTP_STATUS.NOT_FOUND) {
        defaultErrorMsg = DEFAULT_WRONG_MESSAGE;
      }

      yield put(putVerifyProductByDealerFailed(defaultErrorMsg));
      yield put(getVerifyProductByDealer(dealerNumber));
      return;
    }

    yield put(putVerifyProductByDealerSuccess(response.data));
    yield put(replace(ROUTES.APPLY_FORM));
  });
}

function* getCustomerIdentitySaga() {
  yield takeLatest(
    GET_CUSTOMER_IDENTITY,
    function* ({ payload: { applicantType, requestId, requiredId = true } }) {
      const [{ FirstName, LastName, SSN, PhoneNumber, DOB }] =
        yield processCustomerFormData(
          APPLICANT_NOMENCLATURE_PREFIX_MAP[applicantType]
        );
      const applyFrom = yield select(selectApplyFrom);
      const dealerNumber = yield select(selectDealerNumber);

      if (!requestId && requiredId) {
        requestId = yield select(state =>
          selectRequestId(state, applicantType)
        );
      }

      const confData = {
        applicantType,
        applyFrom,
        dealerNumber
      };

      const requestData = requestId
        ? { requestId }
        : {
            ...confData,
            FirstName,
            LastName,
            ssn: SSN,
            phoneNumber: PhoneNumber,
            DOB
          };

      const response = yield call(apiRequest, getCustomerIdentityRequest, [
        {
          ...requestData
        }
      ]);

      if (!response || !response.isSuccess || !response.data) {
        yield put(
          setCustomerIdentityErrorMessage({
            applicantType,
            message:
              'Sorry, the required information could not be found. Enter it manually.'
          })
        );
        yield put(getCustomerIdentityFailed({ applicantType, ...response }));
        return;
      }

      const data = response.data;

      const isExpired = data.flags?.isExpired;
      const isCompleted = data.flags?.isCompleted;

      if (isExpired || isCompleted) {
        yield put(getCustomerIdentityDone({ applicantType, ...data }));
        return;
      }

      yield put(getCustomerIdentitySuccess({ applicantType, ...data }));
    }
  );
}

function* getCustomerIdentityFailedSaga() {
  yield takeLatest(
    GET_CUSTOMER_IDENTITY_FAILED,
    function* ({ payload: { applicantType, ...errorData } }) {
      const isForQRCode = yield select(selectIsForQRCode);
      const isForBDCode = yield select(selectIsForBDCode);
      const isForBECode = yield select(selectIsForBECode);

      try {
        var message = "We're sorry, we can't find the information.";

        if (errorData.errorMessage) {
          message = `${errorData.errorMessage.title}. ${errorData.errorMessage.description}`;
        } else {
          if (errorData.showToUser) {
            message = errorData.message;
          }
        }

        yield put(setCustomerIdentityErrorMessage({ applicantType, message }));

        if (!isForQRCode || !isForBDCode || isForBECode) {
          yield put(enterApplicantDataManually({ applicantType, value: true }));
        }
      } catch (error) {
        yield put(
          setCustomerIdentityErrorMessage({
            applicantType,
            message: 'Sorry an error occurred. Contact Us.'
          })
        );
        if (!isForQRCode || !isForBDCode || isForBECode) {
          yield put(enterApplicantDataManually({ applicantType, value: true }));
        }
      }
    }
  );
}

function* getCustomerIdentityStopedSaga() {
  yield takeEvery(GET_CUSTOMER_IDENTITY_STOP, function* () {
    yield call(regularHeimdalAxios.cancel);
  });
}

function* notificationReceivedSaga() {
  yield takeLatest(
    WS_NOTIFICATION_RECEIVED,
    function* ({ notification: { elementNumber, notificationId } }) {
      const applicantType = yield select(state =>
        selectCustomerIdentityRequestId(state, elementNumber)
      );

      if (
        notificationId === WS_NOTIFICATION_CUSTOMER_IDENTITY_PROCESS &&
        applicantType
      ) {
        yield put(
          getCustomerIdentity({ applicantType, requestId: elementNumber })
        );
      }
    }
  );
}

function* applyFromQRCode(response) {
  const data = response.data;
  const { dealerNumber, applicantType = BORROWER_NOMENCLATURE } = data.identify;

  yield put(setForQRCode());
  yield put(
    savePreviousData({
      applicantType: applicantType.toString(),
      ...data
    })
  );
  yield put(
    initRetrieveApplicationData({
      applicantType: applicantType.toString(),
      ...data
    })
  );
  if (dealerNumber) {
    yield put(saveDealerNumber(dealerNumber));
  }

  yield put(putMobileVerificationAsSuccessfulSuccess(response.data));
  yield put(replace(ROUTES.APPLY_FORM));
}

function* applyFromIdentityVerificationCode(response) {
  yield put(setForVerificationCode());
  yield put(
    setMessage({
      status: MESSAGE_SUCCESS_STATUS_NOMENCLATURE,
      title: 'Identity verification was success.',
      subTitle: 'Identity verification has been successfully completed.'
    })
  );
}

function* redirectToMessagePage(response) {
  const isSuccessful = response?.data?.flags?.isSuccessful;

  const message = isSuccessful
    ? {
        status: MESSAGE_SUCCESS_STATUS_NOMENCLATURE,
        title: 'Identity Verification was successful.',
        subTitle: 'Mobile Identity verification completed.'
      }
    : {
        status: MESSAGE_ERROR_STATUS_NOMENCLATURE,
        title: 'Identity Verification was unsuccessful.',
        subTitle: 'Customer Identity could not be verified at this time.'
      };

  yield put(
    setMessage({
      status: message.status,
      title: message.title,
      subTitle: message.subTitle
    })
  );
  yield put(resetVerificationParams());
}

function* putMobileVerificationAsSuccessfulSaga() {
  yield takeEvery(
    PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL,
    function* ({ payload: { requestId, vfp } }) {
      const response = yield call(
        apiRequest,
        putMobileVerificationAsSuccessfulRequest,
        [
          {
            requestId,
            vfp
          }
        ]
      );

      if (!response || !response.data?.identify?.applyFrom) {
        //as new application have to do if response is not correct, how to stand up application

        // call status and with this take decition
        yield call(redirectToMessagePage, response);
        yield put(putMobileVerificationAsSuccessfulFailed(response));
        return;
      }
      const data = response.data;
      const { applyFrom = APPLY_FROM_QR_CODE_NOMENCLATURE } = data.identify;

      switch (applyFrom) {
        case APPLY_FROM_QR_CODE_NOMENCLATURE:
          yield call(applyFromQRCode, response);
          break;
        // case APPLY_FROM_IDENTITY_VERIFICATION_NOMENCLATURE:
        //   yield call(applyFromIdentityVerificationCode, response);
        //   break;

        default:
          yield put(putMobileVerificationAsSuccessfulSuccess(response.data));
          yield call(redirectToMessagePage, response);
      }
    }
  );
}

function* getCustomerIdentityDoneSaga() {
  yield takeLatest(
    GET_CUSTOMER_IDENTITY_DONE,
    function* ({ payload: { applicantType, ...data } }) {
      if (data?.flags?.isExpired) {
        return;
      }

      const applyFrom = yield select(selectApplyFrom);
      const retrieveApplicationData = yield select(
        selectRetrieveApplicationData
      );

      if (
        retrieveApplicationData === GETTING_DATA_STATUS.OFF &&
        applyFrom === APPLY_FROM_QR_CODE_NOMENCLATURE &&
        data.status?.Id === STATUS_COMPLETED
      ) {
        yield put(stoppedCurrentApplication());
        return;
      }

      if (retrieveApplicationData === GETTING_DATA_STATUS.IN_PROGRESS) {
        if (data.status?.Id !== STATUS_COMPLETED) {
          yield put(initRetrieveApplicationDataFailed());
          yield put(
            setErrorMessage(
              'Imposible to continue the application due an error. Retrieving data failed by status.'
            )
          );
          return;
        }

        yield put(initRetrieveApplicationDataSuccess());
      }

      if (!isEmpty(data?.applicant?.addressSelection)) {
        yield put(showAddressListToSelect(applicantType));
        yield put(showCoApplicantModal(false));
      }

      yield put(enterApplicantDataManually({ applicantType, value: true }));
    }
  );
}

function* setCustomerIdentityAddressSaga() {
  yield takeLatest(
    SAVE_CUSTOMER_IDENTITY_ADDRESS,
    function* ({ payload: { applicantType } }) {
      yield put(closeAddressListToSelect());
      yield put(enterApplicantDataManually({ applicantType, value: true }));
    }
  );
}

function* setErrorMessageSaga() {
  yield takeEvery(SET_ERROR_MESSAGE, function* ({ payload }) {
    yield put(replace(ROUTES.ERROR));
  });
}

function* setMessageSaga() {
  yield takeEvery(SET_MESSAGE, function* ({ payload }) {
    yield put(replace(ROUTES.MESSAGE));
  });
}

function* getCallReportSaga() {
  yield takeLatest(
    [GET_CALL_REPORT, RELOAD_CALL_REPORT],
    function* ({ payload }) {
      const { reload = false, ...rest } = payload;

      const response = yield call(apiRequest, getCallReportRequest, [rest], {
        showSuccessMessage: reload,
        actionTitle: reload ? 'Update dealers data' : 'Get dealers data'
      });

      if (!response?.isSuccess) {
        yield put(getCallReportFailed(response));
        return;
      }
      yield put(getCallReportSuccess(response.data));
    }
  );
}

const sagas = [
  submitApplyFormSaga,
  validateAllAddressesSaga,
  verifyProductByDealerSaga,
  putVerifyDealerNumberDataSaga,
  getCustomerIdentitySaga,
  getCustomerIdentityFailedSaga,
  getCustomerIdentityStopedSaga,
  notificationReceivedSaga,
  putMobileVerificationAsSuccessfulSaga,
  getCustomerIdentityDoneSaga,
  setCustomerIdentityAddressSaga,
  setErrorMessageSaga,
  setMessageSaga,
  getCallReportSaga
];
export default sagas;
