import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Col, Row, Spin } from 'antd';
import TwoColumns, { imageSetting } from 'components/common/Content/TwoColumns';
import { Form } from 'components/common/Form';
import Portal from 'components/common/Portal';

import Consent from './Components/Consent';
import FormButtons from './FormButtons';

import { saveApplyFormValues, validateAddress } from 'redux/app/actions';
import ExitButton from 'components/views/utils';

import {
  APPLICANT_PREFIX,
  BORROWER_NOMENCLATURE,
  CO_APPLICANT_PREFIX
} from 'redux/app/constants';

import {
  selectApplyFormError,
  selectApplyFormIsFetching,
  selectApplyFormValues,
  selectIsForBDCode,
  selectIsValidatingAllAdresses,
  selectShowMultipleAddressSelection
} from 'redux/app/selectors';
import { formatAddressForValidation } from 'utils/functions';
import AddressValidationModal from './Modals/AddressValidationModal';
import { selectDealerNumber } from 'redux/tokens/selectors';
import ErrorsDetails from '../ResultMessage/ErrorDetails';

import { GUTTER_DEFAULT } from 'theme/variables';
import MultipleAddressSelectionModal from './Modals/MultipleAddressSelectionModal';

import Applicant from './Sections/Applicant';
import SalesRepInformation from './Sections/SalesRepInformation';
import ApplicationDetails from './Sections/ApplicationDetails';
import { useEnterApplicantDataManuallyData } from 'hooks/data';

const required_rules = {
  required: true,
  message: 'This field is required.'
};

function ApplyForm() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading = useSelector(selectApplyFormIsFetching);
  const isValidatingAllAdresses = useSelector(selectIsValidatingAllAdresses);
  const showMultipleAddressSelection = useSelector(
    selectShowMultipleAddressSelection
  );

  const values = useSelector(selectApplyFormValues);

  const handleOnValuesChange = (changedValues, previousValues) => {
    dispatch(saveApplyFormValues({ ...previousValues, ...changedValues }));
  };

  const handleOnFinish = values => {
    const toVerify = [formatAddressForValidation(APPLICANT_PREFIX, values)];

    const coApplicantAddress = formatAddressForValidation(
      CO_APPLICANT_PREFIX,
      values
    );

    if (coApplicantAddress) {
      toVerify.push(coApplicantAddress);
    }

    dispatch(validateAddress({ toVerify }));
  };

  const handleOnSubmit = () => {
    form.submit();
  };

  const handleValidatedAddressAccepted = useCallback(
    (prefix, address) => {
      const newValues = {
        [`${prefix}StreetAddress`]: address.address1,
        [`${prefix}UnitApt`]: address.address2 ? address.address2 : '',
        [`${prefix}City`]: address.city,
        [`${prefix}State`]: address.state,
        [`${prefix}ZipCode`]: address.zip
      };

      form.setFieldsValue(newValues);
      dispatch(saveApplyFormValues({ ...values, ...newValues }));
    },
    [dispatch, form, values]
  );

  return (
    <>
      <Spin
        spinning={isLoading || isValidatingAllAdresses}
        tip="Your data is being sent..."
      >
        <Form
          layout="vertical"
          scrollToFirstError
          form={form}
          initialValues={values}
          onFinish={handleOnFinish}
          onValuesChange={handleOnValuesChange}
          key={`form-${
            showMultipleAddressSelection ? '-addressSelection-inProgress' : ''
          }`}
        >
          <Row gutter={GUTTER_DEFAULT}>
            <Applicant required_rules={required_rules} form={form} />
            <SalesRepInformation required_rules={required_rules} form={form} />
            <ApplicationDetails required_rules={required_rules} form={form} />
            <Consent required_rules={required_rules} form={form} />
          </Row>
          <Portal id="form-actions">
            <FormButtons
              form={form}
              loading={isLoading || isValidatingAllAdresses}
              submitOnClick={handleOnSubmit}
              disabled={values ? !values.verified : true}
            />
          </Portal>
        </Form>
      </Spin>
      <AddressValidationModal
        onValidatedAddressAccepted={handleValidatedAddressAccepted}
      />
      <MultipleAddressSelectionModal />
    </>
  );
}

function ApplyMain() {
  const error = useSelector(selectApplyFormError);
  const dealerNumber = useSelector(selectDealerNumber);

  useEffect(() => {
    if (error) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [error]);

  return (
    <>
      <TwoColumns
        md={20}
        imageSrc={imageSetting.source}
        imageDirection={imageSetting.position}
        headerSubtitle={
          dealerNumber
            ? 'Tell us about yourself. Please enter the following information.'
            : null
        }
        actions={true}
        extraContent={
          error ? (
            <Alert
              style={{ marginBottom: 5 }}
              type="error"
              message="Your application could not be submitted for processing. Please try again and if the problem persist contact us."
            />
          ) : null
        }
      >
        {dealerNumber ? (
          <ApplyForm />
        ) : (
          <>
            <ErrorsDetails
              status="error"
              title={'Sorry, something gone wrong.'}
              subTitle={'Please go back to the beginning or contact us.'}
            />
            <Portal id="form-actions">
              <ExitButton />
            </Portal>
          </>
        )}
      </TwoColumns>
    </>
  );
}
export default memo(ApplyMain);
