import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Modal, Row } from 'antd';
import { Button } from 'components/common/Buttons';
import { SubTitle, Title } from 'components/common/Typography';
import { Information } from 'components/common/Text';
import Fieldset from '../../Components/Fieldset';

import SSN from '../../Components/SSN';
import Phone from '../../Components/Phone';
import DayOfBirth from '../../Components/DayOfBirth';
import FetchingCustomer from 'components/views/CustomerIdentityForm/FetchingCustomer';

import {
  useCustomerIdentityIsFetching,
  useEnterApplicantDataManuallyData
} from 'hooks/data';

import {
  addCoApplicant,
  enterApplicantDataManually,
  getCustomerIdentity,
  getCustomerIdentityStop,
  showCoApplicantModal
} from 'redux/app/actions';

import {
  selectIsForBDCode,
  selectIsForBECode,
  selectShowAddCoApplicantModal
} from 'redux/app/selectors';

import { APPLICANT_TITLE } from 'redux/app/constants';
import { FONT_SIZE_SMALL, FONT_WEIGHT, GUTTER_DEFAULT } from 'theme/variables';
import { FirstName, LastName } from '../../Components/Names';
import PhoneConcentVerified from '../../Components/PhoneConcentVerified';
import { v4 as uuidv4 } from 'uuid';
import { FormOutlined } from '@ant-design/icons';

function AddCoApplicantModal({
  required_rules,
  form,
  applicantType,
  fieldsPrefix,
  title
}) {
  const dispatch = useDispatch();
  const showAddCoApplicantModal = useSelector(selectShowAddCoApplicantModal);
  const [phoneConsentApproved, setPhoneConsentApproved] = useState(false);

  const isForBDCode = useSelector(selectIsForBDCode);
  const isForBECode = useSelector(selectIsForBECode);

  const customerIdentityIsFetching =
    useCustomerIdentityIsFetching(applicantType);
  const applicantDataManually =
    useEnterApplicantDataManuallyData(applicantType);

  const closeModal = () => {
    dispatch(addCoApplicant(false));
    dispatch(showCoApplicantModal(false));
  };

  const handleTurnManually = () => {
    dispatch(enterApplicantDataManually({ applicantType, value: true }));
  };

  const handleCancel = () => {
    if (customerIdentityIsFetching) {
      dispatch(getCustomerIdentityStop({ applicantType }));
      dispatch(enterApplicantDataManually({ applicantType, value: true }));
    }
    closeModal();
  };

  const handlePhoneApproved = () => {
    setPhoneConsentApproved(!phoneConsentApproved);
  };

  const handleSubmit = async e => {
    try {
      const firstFieldName = `${fieldsPrefix}FirstName`;
      const lastFieldName = `${fieldsPrefix}LastName`;
      const ssnFieldName = `${fieldsPrefix}SSN`;
      const phoneFieldName = `${fieldsPrefix}PhoneNumber`;
      const dateOfBirthFieldName = `${fieldsPrefix}DateOfBirth`;
      const {
        [firstFieldName]: firstName,
        [lastFieldName]: lastName,
        [ssnFieldName]: ssn,
        [phoneFieldName]: phoneNumber,
        [dateOfBirthFieldName]: dateOfBirth
      } = await form.validateFields([
        ssnFieldName,
        phoneFieldName,
        dateOfBirthFieldName
      ]);

      dispatch(getCustomerIdentity({ applicantType }));
    } catch (error) {
      console.log(`The error (`, error, `) has occurred. Please contact us.`);
    }
  };

  return (
    <Modal
      open={showAddCoApplicantModal}
      title={
        <Title style={{ marginBottom: '0px' }}>
          Let's begin by finding the information
        </Title>
      }
      closable={false}
      maskClosable={false}
      onCancel={handleCancel}
      destroyOnClose
      centered
      footer={[
        <Button
          onClick={handleSubmit}
          htmlType="button"
          type="primary"
          loading={customerIdentityIsFetching}
          disabled={!phoneConsentApproved}
          key={uuidv4()}
        >
          Find the {APPLICANT_TITLE[applicantType]}
        </Button>,
        <Button key="cancel" type="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      ]}
    >
      <Information>
        Enter the next fields to find the information or you can:
        <Button
          type="link"
          icon={<FormOutlined />}
          onClick={handleTurnManually}
          params={{ applicantType, value: !applicantDataManually }}
          style={{
            fontWeight: `${FONT_WEIGHT}`,
            fontSize: `${FONT_SIZE_SMALL}`,
            lineHeight: 0
          }}
        >
          Enter all required data manually
        </Button>
      </Information>
      <Fieldset title={title}>
        <FetchingCustomer resend applicantType={applicantType}>
          {!isForBDCode && !isForBECode && (
            <Row gutter={GUTTER_DEFAULT}>
              {/* First Name */}
              <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 16 }}>
                {/* FIRST NAME */}
                <FirstName
                  fieldsPrefix={fieldsPrefix}
                  required={true}
                  required_rules={required_rules}
                  form={form}
                />
              </Col>

              {/* Last Name */}
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <LastName
                  fieldsPrefix={fieldsPrefix}
                  required={true}
                  required_rules={required_rules}
                  form={form}
                />
              </Col>
            </Row>
          )}
          <Row gutter={GUTTER_DEFAULT}>
            {/* SSN */}
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <SSN
                fieldsPrefix={fieldsPrefix}
                required_rules={required_rules}
                form={form}
                //   extra={
                //     <SubTitle>
                //       By providing your social security number, Service Finance
                //       will attempt to find your information to expedite your
                //       request.
                //     </SubTitle>
                //   }
              />
            </Col>
            {/* Mobile Number */}
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <Phone
                label="Mobile Number"
                placeholder="Mobile Number"
                fieldsPrefix={fieldsPrefix}
                required_rules={required_rules}
                required={true}
                // extra={
                //   <SubTitle>
                //     You consent to Service Finance sending you a one-time text
                //     to the mobile phone number you have provided. Standard text
                //     messaging rates may apply.
                //   </SubTitle>
                // }
                form={form}
              />
            </Col>
            {!isForBDCode && !isForBECode && (
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <DayOfBirth
                  fieldsPrefix={fieldsPrefix}
                  required_rules={required_rules}
                  required={false}
                />
              </Col>
            )}
            <Col xs={{ span: 24 }}>
              <PhoneConcentVerified
                fieldsPrefix={fieldsPrefix}
                required={true}
                required_rules={required_rules}
                onChangeFunction={handlePhoneApproved}
              />
            </Col>
          </Row>
        </FetchingCustomer>
      </Fieldset>
    </Modal>
  );
}

function AddCoApplicant({
  required_rules,
  form,
  applicantType,
  fieldsPrefix,
  title
}) {
  return (
    <AddCoApplicantModal
      required_rules={required_rules}
      form={form}
      applicantType={applicantType}
      fieldsPrefix={fieldsPrefix}
      title={title}
    />
  );
}

export default memo(AddCoApplicant);
