import React, { memo, useCallback } from 'react';

import { Space } from 'antd';
import { Button } from 'components/common/Buttons';

import { isFunction } from 'lodash';
import { SIZE_BETWEEN_ELEMENTS } from 'theme/variables';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomerIdentity,
  resetDataCustomerIdentity
} from 'redux/app/actions';
import {
  useCustomerIdentityIsComplete,
  useCustomerIdentityIsFetching,
  useEnterApplicantDataManuallyData
} from 'hooks/data';
import {
  APPLICANT_PREFIX,
  APPLICANT_TITLE,
  BORROWER_NOMENCLATURE
} from 'redux/app/constants';
import {
  selectIsForBDCode,
  selectIsForBECode,
  selectIsForQRCode,
  selectPhoneConsentApproved,
  selectSkipForm
} from 'redux/app/selectors';
import Close from './Components/Close';
import { useIsMobile } from 'hooks/responsive';

export function FindCustomerDataButton({
  fieldsPrefix,
  form,
  applicantType,
  loading = false,
  ...props
}) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const handleSubmit = async e => {
    try {
      const firstFieldName = `${fieldsPrefix}FirstName`;
      const lastFieldName = `${fieldsPrefix}LastName`;
      const ssnFieldName = `${fieldsPrefix}SSN`;
      const phoneFieldName = `${fieldsPrefix}PhoneNumber`;
      const dateOfBirthFieldName = `${fieldsPrefix}DateOfBirth`;
      const phoneFieldVerifiedName = `${fieldsPrefix}PhoneVerified`;

      const {
        [firstFieldName]: firstName,
        [lastFieldName]: lastName,
        [ssnFieldName]: ssn,
        [phoneFieldName]: phoneNumber,
        [dateOfBirthFieldName]: dateOfBirth,
        [phoneFieldVerifiedName]: PhoneVerified
      } = await form.validateFields([
        firstFieldName,
        lastFieldName,
        ssnFieldName,
        phoneFieldName,
        dateOfBirthFieldName,
        phoneFieldVerifiedName
      ]);
      dispatch(resetDataCustomerIdentity({ applicantType }));
      dispatch(getCustomerIdentity({ applicantType, requiredId: false }));
    } catch (error) {
      console.log('An error has occurred. Please contact us.');
    }
  };

  const buttonText = isMobile
    ? 'Find'
    : `Find the ${APPLICANT_TITLE[BORROWER_NOMENCLATURE]}`;

  return (
    <Button
      onClick={handleSubmit}
      htmlType="button"
      type="primary"
      loading={loading}
      {...props}
    >
      {buttonText}
    </Button>
  );
}

function FormButtons({
  form,
  loading,
  submitOnClick,
  disabled,
  applicantType = BORROWER_NOMENCLATURE,
  fieldsPrefix = APPLICANT_PREFIX
}) {
  const customerIdentityIsComplete =
    useCustomerIdentityIsComplete(applicantType);
  const isForQRCode = useSelector(selectIsForQRCode);
  const isForBDCode = useSelector(selectIsForBDCode);
  const isForBECode = useSelector(selectIsForBECode);

  const skipForm = useSelector(selectSkipForm);

  const phoneConsentApproved = useSelector(selectPhoneConsentApproved);
  const customerIdentityIsFetching =
    useCustomerIdentityIsFetching(applicantType);

  const applicantDataManually =
    useEnterApplicantDataManuallyData(applicantType);

  const isTheFirstStepForQRCode = isForQRCode && !customerIdentityIsComplete;
  const isTheFirstStepForBDCode =
    isForBDCode && !customerIdentityIsComplete && !skipForm;
  const isTheFirstStepForBECode =
    isForBECode && !customerIdentityIsComplete && !skipForm;

  const handleSubmitOnClick = useCallback(
    e => {
      if (isFunction(submitOnClick)) {
        submitOnClick(e);
      }
    },
    [submitOnClick]
  );

  return (
    <Space size={SIZE_BETWEEN_ELEMENTS}>
      <Close />
      {isTheFirstStepForQRCode ||
      isTheFirstStepForBDCode ||
      isTheFirstStepForBECode ? (
        <FindCustomerDataButton
          fieldsPrefix={fieldsPrefix}
          form={form}
          applicantType={applicantType}
          loading={customerIdentityIsFetching}
          disabled={!phoneConsentApproved && !isForBDCode && !isForBECode}
        />
      ) : (
        <Button
          key="submit"
          htmlType="button"
          onClick={handleSubmitOnClick}
          type="primary"
          loading={loading}
          disabled={disabled || !applicantDataManually}
        >
          Submit
        </Button>
      )}
    </Space>
  );
}

export default memo(FormButtons);
