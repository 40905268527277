import React from 'react';
import { getMapByCoordinates } from 'redux/app/actions';
import { useDispatch } from 'react-redux';

import { EnvironmentOutlined } from '@ant-design/icons';
import { Button } from 'components/common/Buttons';
import { ACTION_COLOR, FONT_SIZE_SMALL, FONT_WEIGHT } from 'theme/variables';

function ShowInMap({ gpsCoordinates, address, isValid, style, ...props }) {
  const dispatch = useDispatch();

  if (!gpsCoordinates || !isValid) {
    return false;
  }

  const handleViewMap = e => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(getMapByCoordinates({ gpsCoordinates, address }));
  };

  return (
    <Button
      type="link"
      className="link-map"
      icon={<EnvironmentOutlined />}
      style={{
        color: ACTION_COLOR,
        fontSize: FONT_SIZE_SMALL,
        fontWeight: FONT_WEIGHT,
        ...style
      }}
      size="small"
      onClick={handleViewMap}
      {...props}
    >
      View map
    </Button>
  );
}

export default ShowInMap;
