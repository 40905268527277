import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { useIsMobile } from 'hooks/responsive';
import { buildAssociationCallReportPayload } from 'utils/functions';

import { Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { Button } from 'components/common/Buttons';
import { Form, FormItem } from 'components/common/Form';
import ZipCodeInput from 'components/views/Apply/Form/Components/ZipCodeInput';

import {
  selectAssociationCallReportIsFetching,
  selectAssociationFormInfo
} from 'redux/app/selectors';

import { getCallReport, setAssociationFormInfo } from 'redux/app/actions';

import { DISTANCE_OPTIONS } from 'redux/app/constants';

const { Option } = Select;

export const FORM_FIELDS_NAMES = { ZIP_CODE: 'zipCode', DISTANCE: 'distance' };

function FindDealerForm({ form }) {
  const dispatch = useDispatch();
  const { associationNumber } = useParams();
  const isMobile = useIsMobile();

  const isFetching = useSelector(selectAssociationCallReportIsFetching);
  const associationFormInfo = useSelector(selectAssociationFormInfo);
  const { zipCode, distance } = associationFormInfo;

  const handleOnChange = value => {
    dispatch(
      setAssociationFormInfo({ ...associationFormInfo, zipCode: value })
    );
  };
  const handleSelectOnChange = value => {
    dispatch(
      setAssociationFormInfo({ ...associationFormInfo, distance: value })
    );
  };

  const handleOnFinish = values => {
    const payload = buildAssociationCallReportPayload(
      +values?.zipCode,
      values?.distance,
      associationNumber
    );

    if (!isEmpty(payload)) {
      dispatch(getCallReport(payload));
    }
  };

  const options = DISTANCE_OPTIONS?.split(',')?.map(item => +item.trim());

  return (
    <Form
      style={{
        ...(isMobile && {
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          flexWrap: 'wrap'
        }),
        marginBottom: '20px'
      }}
      layout="inline"
      onFinish={handleOnFinish}
      form={form}
      initialValues={{ zipCode, distance }}
    >
      <FormItem
        key={FORM_FIELDS_NAMES.ZIP_CODE}
        name={FORM_FIELDS_NAMES.ZIP_CODE}
        style={{ ...(isMobile && { flex: '1 1 40%' }), marginRight: '5px' }}
      >
        <ZipCodeInput onChange={handleOnChange} />
      </FormItem>

      <FormItem
        key={FORM_FIELDS_NAMES.DISTANCE}
        name={FORM_FIELDS_NAMES.DISTANCE}
        style={{ ...(isMobile && { flex: '1 1 40%' }), marginRight: '5px' }}
      >
        <Select onChange={handleSelectOnChange}>
          {options?.map(item => (
            <Option key={item} value={item}>
              {item} Miles
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem style={{ marginRight: '0' }}>
        {isMobile ? (
          <Button
            type="primary"
            htmlType="submit"
            disabled={!zipCode}
            icon={<SearchOutlined />}
            style={{ flex: '0 0 10%' }}
          />
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            disabled={!zipCode}
            loading={isFetching}
          >
            Find a Dealer
          </Button>
        )}
      </FormItem>
    </Form>
  );
}

export default FindDealerForm;
