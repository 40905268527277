import { createSelector } from 'reselect';
import moment from 'moment';
import {
  APPLICANT_PREFIX,
  APPLY_FORM_RESPONSE_COLOR_BLACK,
  APPLY_FORM_RESPONSE_COLOR_GREEN,
  APPLY_FORM_RESPONSE_COLOR_ORANGE,
  APPLY_FORM_RESPONSE_COLOR_RED,
  CO_APPLICANT_PREFIX,
  IN_PROGRESS_STATUS_STARTING_TOOLTIP_TEXT,
  IN_PROGRESS_STATUS_UNKNOWN_TOOLTIP_TEXT,
  IN_PROGRESS_STATUS_TOOLTIP_TEXT,
  APPLICANT_NOMENCLATURE_PREFIX_MAP,
  COBORROWER_NOMENCLATURE,
  CALLED_WITH_SUCCESS,
  CALLED_WITH_ERROR,
  NOT_CALLED,
  LOADING_STATES,
  APPLY_FROM_CUSTOMER_NOMENCLATURE,
  APPLY_FROM_QR_CODE_NOMENCLATURE,
  APPLY_FROM_BD_CODE_NOMENCLATURE,
  APPLY_FROM_BE_CODE_NOMENCLATURE,
  CALLED_WITH_SUCCESS_BUT_NOT_VERIFIED
} from './constants';
import {
  formatAddressForValidation,
  formatAddressAsHTML
} from 'utils/functions';
import { findKey, isEmpty } from 'lodash';

const RESULT_SUCCESS = [
  APPLY_FORM_RESPONSE_COLOR_GREEN,
  APPLY_FORM_RESPONSE_COLOR_ORANGE
];

const RESULT_FAILED = [
  APPLY_FORM_RESPONSE_COLOR_RED,
  APPLY_FORM_RESPONSE_COLOR_BLACK
];

const selectAppDomain = () => state => state.get('app');

const selectApp = createSelector(selectAppDomain(), app => app.toJS());

const selectHasCoApplicant = createSelector(selectApp, app => {
  return app.hasCoApplicant;
});

const selectApplyFormIsFetching = createSelector(selectApp, app => {
  return app.applyFormIsFetching;
});

const selectApplyFormResult = createSelector(selectApp, app => {
  return app.applyFormResult;
});

const selectApplyFormError = createSelector(selectApp, app => {
  return app.applyFormError;
});

const selectSkipForm = createSelector(selectApp, app => {
  return app.skipForm;
});

const selectApplyFormResultIsSuccess = createSelector(
  selectApplyFormResult,
  result => {
    return (
      result && result.ColorBand && RESULT_SUCCESS.includes(result.ColorBand)
    );
  }
);

const selectApplyFormResultIsFailed = createSelector(
  selectApplyFormResult,
  result => {
    return result?.ColorBand && RESULT_FAILED.includes(result.ColorBand);
  }
);

const selectResultColorBand = createSelector(selectApplyFormResult, result => {
  return result?.ColorBand;
});

const selectResultScriptText = createSelector(selectApplyFormResult, result => {
  return result?.ScriptText || '';
});

const selectResultScriptText2 = createSelector(
  selectApplyFormResult,
  result => {
    return result?.ScriptText2 || '';
  }
);

const selectResultTitle = createSelector(selectApplyFormResult, result => {
  return result?.Title || '';
});

const selectApplyFormResultCallbackURL = createSelector(
  selectApplyFormResult,
  result => {
    return result ? result.CallBackURL : null;
  }
);

const selectIsValidatingAllAdresses = createSelector(
  selectApp,
  app => app.isValidatingAllAdresses
);

const selectValidatedAddresses = createSelector(selectApp, app => {
  return app ? app.validatedAddresses : null;
});

const selectIsApplicantAddressValid = createSelector(
  selectValidatedAddresses,
  validatedAddresses => {
    if (!validatedAddresses || validatedAddresses.length === 0) {
      return false;
    }

    const address = validatedAddresses[0];

    return address.flags.IsVerified && address.flags.IsEquals;
  }
);

const selectIsCoApplicantAddressValid = createSelector(
  selectValidatedAddresses,
  selectHasCoApplicant,
  (validatedAddresses, hasCoApplicant) => {
    if (
      !validatedAddresses ||
      validatedAddresses.length < 2 ||
      !hasCoApplicant
    ) {
      return false;
    }

    const address = validatedAddresses[1];

    return address.flags.IsVerified && address.flags.IsEquals;
  }
);

const selectApplyFormValues = createSelector(selectApp, app => {
  if (app && app.applyFormValues) {
    if (`${APPLICANT_PREFIX}DateOfBirth` in app.applyFormValues) {
      app.applyFormValues[`${APPLICANT_PREFIX}DateOfBirth`] = app
        .applyFormValues[`${APPLICANT_PREFIX}DateOfBirth`]
        ? moment(app.applyFormValues[`${APPLICANT_PREFIX}DateOfBirth`])
        : '';
    }

    if (`${CO_APPLICANT_PREFIX}DateOfBirth` in app.applyFormValues) {
      app.applyFormValues[`${CO_APPLICANT_PREFIX}DateOfBirth`] = app
        .applyFormValues[`${CO_APPLICANT_PREFIX}DateOfBirth`]
        ? moment(app.applyFormValues[`${CO_APPLICANT_PREFIX}DateOfBirth`])
        : '';
    }

    return app.applyFormValues;
  }

  return null;
});

const selectConfirmedAddresses = createSelector(
  selectApp,
  app => app.confirmedAddresses
);

const selectAreAddressesConfirmed = createSelector(
  selectConfirmedAddresses,
  confirmedAddresses => {
    if (confirmedAddresses) {
      return Object.values(confirmedAddresses).every(item => item.confirmed);
    }

    return false;
  }
);

const selectApplicantAddress = createSelector(selectApp, app => {
  if (app && app.applyFormValues) {
    const address = formatAddressForValidation(
      APPLICANT_PREFIX,
      app.applyFormValues
    );

    if (!address) {
      return null;
    }

    return formatAddressAsHTML(address);
  }

  return null;
});

const selectCoApplicantAddress = createSelector(selectApp, app => {
  if (app && app.applyFormValues) {
    const address = formatAddressForValidation(
      CO_APPLICANT_PREFIX,
      app.applyFormValues
    );

    if (!address) {
      return null;
    }

    return formatAddressAsHTML(address);
  }

  return null;
});

const selectShowAddressValidation = createSelector(
  selectApp,
  app => app.showAddressValidation
);

const selectApplicantValidatedAddress = createSelector(
  selectValidatedAddresses,
  validatedAddresses => {
    if (!validatedAddresses || validatedAddresses.length === 0) {
      return null;
    }

    const address = validatedAddresses[0].verified;

    return formatAddressAsHTML(address);
  }
);

const selectCoApplicantValidatedAddress = createSelector(
  selectValidatedAddresses,
  selectHasCoApplicant,
  (validatedAddresses, hasCoApplicant) => {
    if (
      !validatedAddresses ||
      validatedAddresses.length < 2 ||
      !hasCoApplicant
    ) {
      return null;
    }

    const address = validatedAddresses[1].verified;

    return formatAddressAsHTML(address);
  }
);

const selectHasAddressToChoose = createSelector(
  selectApplicantValidatedAddress,
  selectCoApplicantValidatedAddress,
  (applicantAddress, coApplicantAddress) => {
    return applicantAddress || coApplicantAddress;
  }
);

const selectVerifyProductByDealerData = createSelector(
  selectApp,
  app => app.verifyProductByDealer
);

const selectDealerDataToVerify = createSelector(
  selectVerifyProductByDealerData,
  verifyProductByDealerData =>
    verifyProductByDealerData && verifyProductByDealerData.toVerify
);

const selectIsFetchinVerifyProductByDealerData = createSelector(
  selectApp,
  app => app.isFetchingVerifyProductByDealer
);

const selectDealerNumberFormError = createSelector(
  selectApp,
  app => app.dealerNumberFormError
);

const selectDealerNumberFormVerifyDataError = createSelector(
  selectApp,
  app => app.dealerNumberFormVerifyDataError
);

const selectIsFetchingPutVerifyDealerData = createSelector(
  selectApp,
  app => app.isFetchingPutVerifyDealerData
);

const selectEnterApplicantDataManuallyData = (state, applicantType) => {
  const app = state.get('app');

  if (app) {
    const enterApplicantDataManuallyValue = app.get(
      'enterApplicantDataManuallyValue'
    );

    return enterApplicantDataManuallyValue?.toJS()[applicantType];
  }

  return null;
};

const makeSelectEnterApplicantDataManuallyData = () =>
  createSelector(
    selectEnterApplicantDataManuallyData,
    enterApplicantDataManuallyData => enterApplicantDataManuallyData
  );

const selectCustomerIdentityRequestId = (state, requestId) => {
  const app = state.get('app');
  if (app) {
    const customerIdentityInProgressRequest = app.get(
      'customerIdentityInProgressRequest'
    );

    if (!customerIdentityInProgressRequest) {
      return null;
    }

    const applicantType = findKey(
      customerIdentityInProgressRequest.toJS(),
      item => item.requestId === requestId
    );

    return applicantType;
  }

  return null;
};

const makeSelectCustomerIdentityRequestId = () =>
  createSelector(
    selectCustomerIdentityRequestId,
    customerIdentityRequestId => customerIdentityRequestId
  );

const selectCustomerIdentity = (state, applicantType) => {
  const app = state.get('app');

  if (app) {
    const customerIdentity = app.get('customerIdentity');
    const data = customerIdentity.get(applicantType.toString());

    return data?.toJS();
  }

  return null;
};

const makeSelectCustomerIdentity = () =>
  createSelector(selectCustomerIdentity, customerIdentity => customerIdentity);

const makeSelectCustomerIdentityIsComplete = () =>
  createSelector(selectCustomerIdentity, customerIdentity => {
    return customerIdentity?.flags?.isCompleted;
  });

const makeSelectCustomerIdentityIsExpired = () =>
  createSelector(selectCustomerIdentity, customerIdentity => {
    return customerIdentity?.flags?.isExpired;
  });

const makeSelectCustomerExpirationDate = () =>
  createSelector(selectCustomerIdentity, customerIdentity => {
    return customerIdentity?.expirationDate;
  });

const makeSelectCustomerIdentityAddressSelection = () =>
  createSelector(selectCustomerIdentity, customerIdentity => {
    if (isEmpty(customerIdentity?.applicant?.addressSelection)) {
      return null;
    }

    const addressSelection = customerIdentity.applicant.addressSelection;
    return addressSelection.filter(address => address.hasOwnProperty('id'));
  });

const selectCustomerIdentityIsFetching = (state, applicantType) => {
  const app = state.get('app');

  if (app) {
    const customerIdentityIsFetching = app
      .get('customerIdentityIsFetching')
      .toJS();
    const isFetching = customerIdentityIsFetching[applicantType];

    return !!isFetching;
  }

  return false;
};

const makeSelectCustomerIdentityIsFetching = () =>
  createSelector(selectCustomerIdentityIsFetching, isFetching => isFetching);

const selectCustomerIdentityError = (state, applicantType) => {
  const app = state.get('app');

  if (app) {
    const customerIdentityError = app.get('customerIdentityError').toJS();

    const errorMessage = customerIdentityError[applicantType];
    return errorMessage;
  }

  return null;
};

const makeSelectCustomerIdentityError = () =>
  createSelector(
    selectCustomerIdentityError,
    customerIdentityError => customerIdentityError
  );

const selectMobileVerificationState = createSelector(selectApp, app => {
  return app.mobileVerificationState;
});

const selectMobileVerificationAsSuccessfulIsFetching = createSelector(
  selectApp,
  app => {
    return app.putMobileVerificationAsSuccessfulIsFetching;
  }
);

const selectCustomerIdentityStatusId = (state, applicantType) => {
  const app = state.get('app');

  if (app) {
    const customerIdentity = app.get('customerIdentity');

    const data = customerIdentity.get(applicantType);

    if (!data) {
      return null;
    }

    return data?.toJS().status?.Id;
  }

  return null;
};

const makeSelectFetchingProgressStatusId = () =>
  createSelector(
    selectCustomerIdentityStatusId,
    customerIdentityStatusId => customerIdentityStatusId
  );

const selectCustomerIdentityStatusIdTitle = (state, applicantType) => {
  const app = state.get('app');

  if (app) {
    const customerIdentity = app.get('customerIdentity');

    const data = customerIdentity.get(applicantType);

    if (!data) {
      return IN_PROGRESS_STATUS_STARTING_TOOLTIP_TEXT;
    }

    const statusId = data?.toJS().status?.Id;

    return statusId
      ? IN_PROGRESS_STATUS_TOOLTIP_TEXT[statusId]
      : IN_PROGRESS_STATUS_UNKNOWN_TOOLTIP_TEXT;
  }

  return null;
};

const makeSelectFetchingProgressStatusTitle = () =>
  createSelector(
    selectCustomerIdentityStatusIdTitle,
    customerIdentityStatusIdTitle => customerIdentityStatusIdTitle
  );

const selectRequestId = (state, applicantType) => {
  const app = state.get('app');
  if (app) {
    const customerIdentityInProgressRequest = app.get(
      'customerIdentityInProgressRequest'
    );

    if (!customerIdentityInProgressRequest) {
      return null;
    }

    return customerIdentityInProgressRequest?.toJS()[applicantType]?.requestId;
  }

  return null;
};

const makeSelectRequestId = () =>
  createSelector(selectRequestId, requestId => requestId);

const selectPhoneProvided = (state, applicantType) => {
  const app = state.get('app');
  const applyFormValues = app?.get('applyFormValues')?.toJS();

  if (applyFormValues) {
    const prefix = APPLICANT_NOMENCLATURE_PREFIX_MAP[applicantType];
    return applyFormValues[`${prefix}PhoneNumber`];
  }

  return null;
};

const makeSelectPhoneProvided = () =>
  createSelector(selectPhoneProvided, phoneProvided => phoneProvided);

const selectShowMultipleAddressSelection = createSelector(
  selectApp,
  app => app.showMultipleAddressSelection
);

const selectAddCoApplicant = createSelector(
  selectApp,
  selectHasCoApplicant,
  (app, hasCoApplicant) =>
    !app.enterApplicantDataManuallyValue[COBORROWER_NOMENCLATURE] &&
    hasCoApplicant
);

const selectShowAddCoApplicantModal = createSelector(
  selectApp,
  selectAddCoApplicant,
  (app, addCoApplicant) => app.showAddCoApplicantModal && addCoApplicant
);

const makeSelectCustomerIdentityAlerts = () =>
  createSelector(selectCustomerIdentity, customerIdentity =>
    isEmpty(customerIdentity?.alerts) ? null : customerIdentity.alerts
  );

const selectVerificationParams = createSelector(
  selectApp,
  app => app.verificationParams
);

const selectMobileVerificationIsLoading = createSelector(
  selectMobileVerificationState,
  selectVerificationParams,
  (mobileVerificationState, verificationParams) => {
    return (
      !!verificationParams && LOADING_STATES.includes(mobileVerificationState)
    );
  }
);

const selectMobileVerificationSuccesful = createSelector(
  selectMobileVerificationState,
  mobileVerificationState => {
    return mobileVerificationState === CALLED_WITH_SUCCESS;
  }
);

const selectMobileVerificationSuccesfulNoVerificated = createSelector(
  selectMobileVerificationState,
  mobileVerificationState => {
    return mobileVerificationState === CALLED_WITH_SUCCESS_BUT_NOT_VERIFIED;
  }
);

const selectMobileVerificationHasError = createSelector(
  selectMobileVerificationState,
  selectVerificationParams,
  (mobileVerificationState, verificationParams) => {
    return mobileVerificationState === CALLED_WITH_ERROR;
  }
);

const selectMobileVerificationURLError = createSelector(
  selectMobileVerificationState,
  selectVerificationParams,
  (mobileVerificationState, verificationParams) => {
    return !verificationParams && mobileVerificationState === NOT_CALLED;
  }
);

const selectStoppedCurrentApplication = createSelector(selectApp, app => {
  return app.stoppedCurrentApplication;
});

const selectApplyFrom = createSelector(selectApp, app => app.applyFrom);

const selectIsForCustomer = createSelector(
  selectApplyFrom,
  applyFrom => applyFrom === APPLY_FROM_CUSTOMER_NOMENCLATURE
);

const selectIsForQRCode = createSelector(
  selectApplyFrom,
  applyFrom => applyFrom === APPLY_FROM_QR_CODE_NOMENCLATURE
);

const selectIsForBDCode = createSelector(
  selectApplyFrom,
  applyFrom => applyFrom === APPLY_FROM_BD_CODE_NOMENCLATURE
);

const selectIsForBECode = createSelector(
  selectApplyFrom,
  applyFrom => applyFrom === APPLY_FROM_BE_CODE_NOMENCLATURE
);

const selectRetrieveApplicationData = createSelector(
  selectApp,
  app => app.retrieveApplicationData
);

const selectPhoneConsentApproved = createSelector(
  selectApp,
  app => app.phoneConsentApproved
);

const selectErrorMessage = createSelector(selectApp, app => app.errorMessage);
const selectMessage = createSelector(selectApp, app => app.message);

//association call report
const selectAssociationCallReportIsFetching = createSelector(
  selectApp,
  app => app.associationCallReportIsFetching
);

const selectAssociationReloadCallReportIsFetching = createSelector(
  selectApp,
  app => app.associationReloadCallReportIsFetching
);
const selectAssociationCallReport = createSelector(
  selectApp,
  app => app.associationCallReport
);
const selectHaveDealersData = createSelector(
  selectAssociationCallReport,
  associationCallReport => {
    return !isEmpty(associationCallReport?.data?.items);
  }
);
const selectAssociationCallReportItems = createSelector(
  selectAssociationCallReport,
  associationCallReport => associationCallReport?.data?.items ?? []
);
const selectSearchText = createSelector(selectApp, app => app.searchText);
const selectAssociationFormInfo = createSelector(
  selectApp,
  app => app.associationFormInfo
);
const selectErrorCallReport = createSelector(
  selectApp,
  app => app.errorCallReport
);

const selectHaveErrorInAssociationData = createSelector(
  selectErrorCallReport,
  errorCallReport => !isEmpty(errorCallReport)
);

const selectAssociationNumber = createSelector(
  selectApp,
  app => app.associationNumber
);

const selectAddressMap = createSelector(selectApp, app => app.addressMap);

const selectShowAddressMap = createSelector(
  selectAddressMap,
  addressMap => addressMap?.open
);

const selectAddressMapData = createSelector(
  selectAddressMap,
  addressMap => addressMap?.data
);

export default selectApp;
export {
  selectHasCoApplicant,
  selectApplyFormIsFetching,
  selectApplyFormResult,
  selectApplyFormResultIsSuccess,
  selectApplyFormResultIsFailed,
  selectApplyFormResultCallbackURL,
  selectApplyFormError,
  selectIsValidatingAllAdresses,
  selectValidatedAddresses,
  selectApplicantAddress,
  selectApplicantValidatedAddress,
  selectCoApplicantAddress,
  selectCoApplicantValidatedAddress,
  selectHasAddressToChoose,
  selectApplyFormValues,
  selectIsApplicantAddressValid,
  selectIsCoApplicantAddressValid,
  selectShowAddressValidation,
  selectConfirmedAddresses,
  selectAreAddressesConfirmed,
  selectVerifyProductByDealerData,
  selectIsFetchinVerifyProductByDealerData,
  selectDealerNumberFormError,
  selectDealerNumberFormVerifyDataError,
  selectDealerDataToVerify,
  selectIsFetchingPutVerifyDealerData,
  selectResultColorBand,
  selectResultScriptText,
  selectResultScriptText2,
  selectResultTitle,
  makeSelectEnterApplicantDataManuallyData,
  makeSelectCustomerIdentity,
  makeSelectCustomerIdentityIsFetching,
  makeSelectCustomerIdentityError,
  makeSelectCustomerIdentityRequestId,
  selectMobileVerificationState,
  selectMobileVerificationAsSuccessfulIsFetching,
  makeSelectFetchingProgressStatusId,
  makeSelectFetchingProgressStatusTitle,
  makeSelectRequestId,
  makeSelectCustomerIdentityIsComplete,
  makeSelectCustomerIdentityIsExpired,
  makeSelectPhoneProvided,
  selectShowMultipleAddressSelection,
  makeSelectCustomerIdentityAddressSelection,
  selectShowAddCoApplicantModal,
  makeSelectCustomerIdentityAlerts,
  makeSelectCustomerExpirationDate,
  selectVerificationParams,
  selectMobileVerificationIsLoading,
  selectMobileVerificationSuccesful,
  selectMobileVerificationHasError,
  selectMobileVerificationURLError,
  selectIsForCustomer,
  selectIsForQRCode,
  selectApplyFrom,
  selectStoppedCurrentApplication,
  selectRetrieveApplicationData,
  selectErrorMessage,
  selectPhoneConsentApproved,
  selectMessage,
  selectIsForBDCode,
  selectIsForBECode,
  selectMobileVerificationSuccesfulNoVerificated,
  selectSkipForm,
  selectAssociationCallReportIsFetching,
  selectAssociationReloadCallReportIsFetching,
  selectAssociationCallReport,
  selectAssociationCallReportItems,
  selectSearchText,
  selectAssociationFormInfo,
  selectErrorCallReport,
  selectHaveErrorInAssociationData,
  selectAssociationNumber,
  selectHaveDealersData,
  selectShowAddressMap,
  selectAddressMapData
};
