import { createGlobalStyle } from 'styled-components';
import {
  FONT_BASE,
  ERROR_COLOR,
  SUCCESS_COLOR,
  INFO_COLOR,
  FONT_SIZE_SMALL,
  ACTION_COLOR,
  WIDTH_BREAKPOINT_SM,
  FONT_COLOR,
  FONT_WEIGHT,
  ACTION_COLOR_HOVER
} from './variables';

/* eslint no-unused-expressions: 0 */
const GlobalStyles = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-size: ${FONT_BASE};
  }

  body {
    background: #ededed;

    &.font-loading, 
    &.font-loading *,
    &.font-failed, 
    &.fonts-failed *  {
      font-display: swap;
      font-family: 'Open sans', Helvetica, Arial, sans-serif;
    }
    
    &.font-loaded, 
    &.font-loaded *  {
      font-display: swap;
      font-family: 'Roboto', 'Open sans', Helvetica, Arial, sans-serif;
    }
  }

  img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"],
a[href="https://www.google.com/intl/es-ES_US/help/terms_maps.html"],
.gmnoprint, 
.gm-style-cc,
.gm-iv-address,
.gm-iv-container {
    display: none !important;
}

  p, strong, h3, h4 {
    color: ${FONT_COLOR};
  }

  h1, h2, h3, h4, strong {
    font-weight: ${FONT_WEIGHT};
  }

  .customer-container-spin {
    /* margin-top: -50px; */
  }

  a.apply {
    color: ${ACTION_COLOR};
    font-weight: ${FONT_WEIGHT};

    &:hover {
      color: ${ACTION_COLOR_HOVER};
    }
  }

  b {
    color: ${FONT_COLOR};
    font-weight ${FONT_WEIGHT};
  }

  #root {
    height: 100%;
  }

  .ant-modal-mask {
    background: rgba(0,0,0,0.25) !important;
  }

  .react-pdf__Document {
    &.pdf-document {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      overflow: auto;
    }
  }


/* NOTIFICATIONS */
.ant-notification {
  
  .ant-notification-notice{
    padding: 16px;
    border-left-width: 4px;
    border-left-style: solid;

    &.notification-error {
      border-left-color: ${ERROR_COLOR};

      .ant-notification-notice-icon {
        color: ${ERROR_COLOR}
      }
    }
    &.notification-success {
      border-left-color: ${SUCCESS_COLOR};
      .ant-notification-notice-icon {
        color: ${SUCCESS_COLOR}
      }
    }
    &.notification-info {
      border-left-color: ${INFO_COLOR};
      .ant-notification-notice-icon {
        color: ${INFO_COLOR};
      }
    }
  }
}

/* BOX SHADOWS */
.box1 {
	-webkit-box-shadow: 0 10px 6px -6px #777;
	-moz-box-shadow: 0 10px 6px -6px #777;
	box-shadow: 0 10px 6px -6px #777;
}
.box2 {
	-webkit-box-shadow: 0 1px 2px #777;
	-moz-box-shadow: 0 2px 1px #777;
	box-shadow: 0 2px 1px #777;
}
.box3 {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.box4 {
	box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
}
.box5 {
	box-shadow: 0 0 1px rgba(34, 25, 25, 0.4);
}
.box6 {
	box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4);
}
.box7 {
	box-shadow: 0 4px 2px -3px;
}
.box8 {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.box9 {
	border-bottom: 0 none;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
}
.box10 {
	-webkit-box-shadow: 0 7px 4px #777;
	-moz-box-shadow: 0 7px 4px #777;
	box-shadow: 0 7px 4px #777;
}
.box11 {
	-webkit-box-shadow: 0 3px 2px #777;
	-moz-box-shadow: 0 3px 2px #777;
	box-shadow: 0 3px 2px #777;
}
.box12 {
	box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #B3B3B3;
}
.box13 {
	-webkit-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
	-moz-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
	box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
}
.box14 {
	-webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
	-moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
	box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.box15 {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
	border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
	border-style: none solid solid none;
	border-width: medium 1px 1px medium;
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
	-moz-border-bottom-colors: none;
	-moz-border-image: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
}
.box16 {
	box-shadow: 0 1px 1px 0 #C7C7C7 inset;
	background: none repeat scroll 0 0 #E9E9E9;
}

@keyframes blink {
    from { opacity: 1.0; }
    50% { opacity: 0.5; }
    to { opacity:0; }
}                                                                                         

@-webkit-keyframes blink {
    from { opacity: 1.0; }
    50% { opacity: 0.5; }
    to { opacity: 0; }
}

.blink_child .ant-checkbox-inner{
  border-color: ${ACTION_COLOR};
}

.blink_child {
  animation: blink 300ms infinite;
  -webkit-animation:blink 300ms infinite;
  animation-iteration-count: 5;
}



/* Tooltip */
  &.ant-tooltip .ant-tooltip-inner {
    font-size: ${FONT_SIZE_SMALL};
  }
 
  img.not-found-image {
    @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
      width: 100%;
      height: 100%;
    }
  }

/* Spin */

.ant-spin-blur {
  opacity: 0.2;
}
  
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.ant-form-item-has-error {
  .phone-input {
    border-color: red;

    &:focus, &:hover {
      border-color: #ff3029;
      box-shadow: 0 0 0 2px rgb(255 0 0 / 20%);
      border-right-width: 1px;
      outline: 0;
    }
  }
}

.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number, .ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  border-color: rgba(218,61,29,1);
}

.ant-input-number-group-wrapper-status-warning .ant-input-number-group-addon {
  border-color: rgba(218,61,29,1);
  color: rgba(218,61,29,1);
}

.ant-form-item-feedback-icon-warning {
  color: rgba(218,61,29,1);
}

.ant-form-item-explain-warning {
  color: rgba(218,61,29,1);
  font-size: 0.9em;
}

.ant-layout {
  height: 100%;
}

`;

export default GlobalStyles;
