import React from 'react';

import Result from 'components/common/Result';
import { ImageWithValidation } from 'components/common/Imagen';

import { RESOURCE_BASE_URL } from 'config/variables';
import { BACKGROUND_SOLID } from 'theme/variables';
import { CardContent } from 'components/common/Content';
import { SubTitle, Title } from 'components/common/Text';

const NO_DATA_TEXT = 'No addresses found at the entry location.';

function NoData({ style, message = NO_DATA_TEXT, ...props }) {
  const image = (
    <ImageWithValidation
      src={`${RESOURCE_BASE_URL}/Images/no_data.svg`}
      alt="no data showed image"
      width="auto"
      height={120}
    />
  );
  return (
    <CardContent
      bodyStyle={{ background: BACKGROUND_SOLID, padding: 0, ...style }}
      {...props}
    >
      <Result
        icon={image}
        title={<Title align="center">We're sorry!</Title>}
        subTitle={
          <SubTitle style={{ textAlign: 'center' }}>{message}</SubTitle>
        }
        style={{ padding: 20 }}
      />
    </CardContent>
  );
}

export default NoData;
