import React, { memo } from 'react';

import { Row, Col, Input } from 'antd';
import { FormItem } from 'components/common/Form';

import Content from 'components/common/Content';
import Phone from './Components/Phone';

import { GUTTER_DEFAULT } from 'theme/variables';
import { SALES_REP_PREFIX } from 'redux/app/constants';
import Email from './Components/Email';

function SalesRepInformationForm({
  title,
  fieldsPrefix = SALES_REP_PREFIX,
  required_rules,
  form
}) {
  return (
    <Content title={title ? title : null} bordered={false}>
      <Row gutter={GUTTER_DEFAULT}>
        {/* First Name */}
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <FormItem
            label="Full Name"
            name={`${fieldsPrefix}Name`}
            required
            rules={[required_rules]}
          >
            <Input autoComplete="off" placeholder="Full Name" />
          </FormItem>
        </Col>

        {/* Email Address */}
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <Email
            label="Email Address"
            fieldsPrefix={fieldsPrefix}
            placeholder="Email Address"
            form={form}
            required
            required_rules={required_rules}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <Phone
            label="Mobile Number"
            fieldsPrefix={fieldsPrefix}
            placeholder="Mobile Number"
            form={form}
          />
        </Col>
      </Row>
    </Content>
  );
}

export default memo(SalesRepInformationForm);
