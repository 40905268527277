import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'antd';
import { ContentNotFound } from 'components/common/Content';
import Map from './components';

import {
  selectAddressMapData,
  selectShowAddressMap
} from 'redux/app/selectors';

import { closeAddressMap } from 'redux/app/actions';
import { Title } from 'components/common/Text';

function ModalMap() {
  const dispatch = useDispatch();
  const showAddressMap = useSelector(selectShowAddressMap);
  const addressMapData = useSelector(selectAddressMapData);

  if (!addressMapData) {
    return false;
  }

  const { address = 'Unknown address', gpsCoordinates = null } = addressMapData;

  const closeMap = () => {
    dispatch(closeAddressMap());
  };

  return (
    <Modal
      className="map"
      open={showAddressMap}
      title={<Title>{address}</Title>}
      width={800}
      destroyOnClose
      centered={true}
      onCancel={closeMap}
      footer={null}
      bodyStyle={{ padding: 0 }}
    >
      {gpsCoordinates && gpsCoordinates.latitude && gpsCoordinates.longitude ? (
        <Map lat={gpsCoordinates.latitude} lng={gpsCoordinates.longitude} />
      ) : (
        <ContentNotFound text="Unable to display the map due to an issue with the address." />
      )}
    </Modal>
  );
}

export default ModalMap;
