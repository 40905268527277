import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from 'hooks/responsive';
import { useGetURLParams } from 'hooks/data';
import { replace } from 'connected-react-router';

import { Result, Spin } from 'antd';
import TwoColumns from 'components/common/Content/TwoColumns';

import { setVerificationParams } from 'redux/app/actions';

import verification_failed_img from 'images/verification_failed.svg';

import { useParams } from 'react-router-dom';
import { ROUTES } from 'consts/routes';
const MOBILE_VERIFICATION_PARAM = 'vfp';

const useProcessedRequestId = () => {
  const { requestId } = useParams();

  return requestId;
};

function Verification() {
  const dispatch = useDispatch();
  const requestId = useProcessedRequestId();
  const vfp = useGetURLParams().get(MOBILE_VERIFICATION_PARAM);

  const isMobile = useIsMobile();
  const image_width = isMobile ? 200 : 348;

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (vfp && requestId) {
      dispatch(setVerificationParams({ requestId, vfp }));
      dispatch(replace(ROUTES.VERIFICATION_WOP));
    } else {
      setHasError(true);
    }
  }, []);

  const errorMessage = {
    title: `We're sorry, the URL provided is not correct.`,
    subTitle: 'Please contact our company.',
    status: 'error',
    icon: (
      <img
        alt="Failed verification"
        src={verification_failed_img}
        width={image_width}
      />
    )
  };

  const loadingMessage = {
    title: `We are checking your verification.`,
    subTitle: 'Please wait a few seconds.',
    icon: <Spin size="large" spinning={true} />,
    status: 'info'
  };

  const MESSAGE = hasError ? errorMessage : loadingMessage;

  return (
    <TwoColumns md={18} showSecondColumn={false}>
      <Result
        title={MESSAGE.title}
        subTitle={MESSAGE.subTitle}
        icon={MESSAGE.icon}
        status={MESSAGE.status}
      />
    </TwoColumns>
  );
}

export default memo(Verification);
