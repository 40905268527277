import React from 'react';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { Button as ButtonAnt } from 'antd';

import {
  ACTION_COLOR,
  ACTION_COLOR_HOVER,
  ICON_SIZE,
  BUTTON_DISABLED_COLOR,
  DISABLE_COLOR,
  DISABLE_BORDER,
  FONT_SIZE_BUTTON,
  BORDER_BLACK,
  FONT_WEIGHT,
  FONT_SIZE
} from 'theme/variables';
import { uniqueId } from 'lodash';

export const SelectFocus = styled(ButtonAnt)`
  color: ${ACTION_COLOR};
  padding: 0;
  border: none;
  height: auto;
  box-shadow: none;
  &:focus {
    border: auto;
    outline: 1px solid ${BORDER_BLACK};
  }
  text-align: left;
`;

export const SelectFocusList = styled(ButtonAnt)`
  border: none;
  text-align: left;
  height: auto;
  box-shadow: none;
  &:focus {
    border: auto;
    outline: 1px solid ${BORDER_BLACK};
  }
`;

const ButtonClose = styled(ButtonAnt)`
  background: none;
  border: none;
  margin: -5px 0;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  transition: none;
  opacity: 0.8;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    color: #ffffff;
    text-decoration: none;
    background: none;
    box-shadow: none;
    outline: none;
    transition: none;
  }
`;

const CustomButton = styled(ButtonAnt)`
  &.ant-btn {
    font-size: ${FONT_SIZE};

    &.link-map > .anticon + span {
      margin-left: 2px;
    }
  }

  &.ant-btn-primary-disabled,
  &.ant-btn-primary.disabled,
  &.ant-btn-primary[disabled],
  &.ant-btn-primary-disabled:hover,
  &.ant-btn-primary.disabled:hover,
  &.ant-btn-primary[disabled]:hover,
  &.ant-btn-primary-disabled:focus,
  &.ant-btn-primary.disabled:focus,
  &.ant-btn-primary[disabled]:focus,
  &.ant-btn-primary-disabled:active,
  &.ant-btn-primary.disabled:active,
  &.ant-btn-primary[disabled]:active,
  &.ant-btn-primary-disabled.active,
  &.ant-btn-primary.disabled.active,
  &.ant-btn-primary[disabled].active[disabled] {
    background: ${DISABLE_COLOR};
    border: ${DISABLE_BORDER};
  }

  &.ant-btn-link:not[disabled] {
    color: ${ACTION_COLOR};
  }

  &:focus {
    outline: auto;
    outline-color: black;
  }

  &.ant-btn-primary {
    background: ${ACTION_COLOR};
    border-color: ${ACTION_COLOR};
    &:focus {
      border: 2px solid ${BORDER_BLACK};
    }
  }

  &.ant-btn-secondary.ant-btn {
    color: ${ACTION_COLOR};
    border-color: ${ACTION_COLOR};

    &:hover,
    &:focus {
      background: ${ACTION_COLOR};
      color: white;
    }
    &:focus {
      outline: auto;
      outline-color: ${BORDER_BLACK};
    }
  }
`;

const ButtonLinkStyled = styled(ButtonAnt)`
  &.ant-btn {
    font-size: ${FONT_SIZE};
    background: none;
    border: none;
    padding: 0;
    color: ${ACTION_COLOR};
    box-shadow: none;
    cursor: pointer;
    &:hover {
      cursor: pointer;
      color: ${ACTION_COLOR_HOVER};
    }
    font-weight: ${FONT_WEIGHT};

    .ant-btn-loading-icon .anticon {
      font-size: 14px;
    }

    &[disabled] {
      color: ${BUTTON_DISABLED_COLOR};
      cursor: not-allowed;
    }

    .anticon {
      font-size: ${ICON_SIZE};
      cursor: pointer;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &.ant-btn-disabled,
  &.ant-btn.disabled,
  &.ant-btn[disabled],
  &.ant-btn-disabled:hover,
  &.ant-btn.disabled:hover,
  &.ant-btn[disabled]:hover,
  &.ant-btn-disabled:focus,
  &.ant-btn.disabled:focus,
  &.ant-btn[disabled]:focus,
  &.ant-btn-disabled:active,
  &.ant-btn.disabled:active,
  &.ant-btn[disabled]:active,
  &.ant-btn-disabled.active,
  &.ant-btn.disabled.active,
  &.ant-btn[disabled].active {
    background: none;
  }
`;

export const Button = ({ onClick, params, ...props }) => {
  const handleClick = e => {
    if (onClick && typeof onClick === 'function') {
      onClick(e, params);
    }
  };

  return <CustomButton onClick={handleClick} {...props} />;
};

export const ButtonLink = ({ onClick, params, ...props }) => {
  const handleClick = e => {
    if (onClick && typeof onClick === 'function') {
      onClick(e, params);
    }
  };

  return <ButtonLinkStyled key={uniqueId} onClick={handleClick} {...props} />;
};

export const CloseButton = ({ onClick, ...props }) => {
  const handleClick = e => {
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <ButtonClose
      onClick={handleClick}
      type="secondary"
      icon={<CloseOutlined {...props} />}
    />
  );
};

export const Linked = styled(Link)`
  &,
  &:hover {
    color: ${ACTION_COLOR};
  }
`;

export default ButtonLink;
