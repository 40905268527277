import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBlank } from 'utils/functions';

import { Alert, Form, Input } from 'antd';
import { Button } from 'components/common/Buttons';
import { Title } from 'components/common/Text';

import { FormItem } from 'components/common/Form';

import { resetApp, getVerifyProductByDealer } from 'redux/app/actions';
import {
  selectDealerNumberFormError,
  selectIsFetchinVerifyProductByDealerData
} from 'redux/app/selectors';

function DealerNumberForm() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const dealerInputRef = useRef();
  const [disable, setDisable] = useState(true);

  const isFetchinVerifyProductByDealerData = useSelector(
    selectIsFetchinVerifyProductByDealerData
  );
  const dealerNumberFormError = useSelector(selectDealerNumberFormError);

  useEffect(() => {
    dispatch(resetApp());
    dealerInputRef.current.focus();
  }, []);

  const handleOnFinish = values => {
    dispatch(getVerifyProductByDealer(values.dealerNumber));
  };

  const handleOnChange = e => {
    setDisable(isBlank(form.getFieldValue('dealerNumber')));
  };

  return (
    <>
      {dealerNumberFormError ? (
        <Alert
          style={{ margin: '5px 0' }}
          type="error"
          message={dealerNumberFormError}
        />
      ) : null}
      <Title>Please confirm your Dealer Product</Title>
      <Form
        style={{ marginTop: '10px' }}
        layout="inline"
        form={form}
        scrollToFirstError
        onFinish={handleOnFinish}
      >
        <FormItem name="dealerNumber">
          <Input
            autoComplete="off"
            type="number"
            placeholder={'Provide a Dealer Number'}
            onChange={handleOnChange}
            ref={dealerInputRef}
          />
        </FormItem>
        <FormItem>
          <Button
            htmlType="submit"
            type="primary"
            disabled={disable}
            loading={isFetchinVerifyProductByDealerData}
          >
            Next
          </Button>
        </FormItem>
      </Form>
    </>
  );
}

export default memo(DealerNumberForm);
