import { Collapse as CollapseAntd } from 'antd';
import styled from 'styled-components';

const Collapse = styled(CollapseAntd)`
  &.ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 8px 12px;
      }
      .ant-collapse-content-box {
        padding: 12px;
      }
    }
  }
`;

export default Collapse;
