import React, { memo } from 'react';

import { FormItem } from 'components/common/Form';
// import { SpacedMaskedInput } from 'components/common/Inputs';
import { Input } from 'antd';

function ZipCode({
  required_rules,
  handleOnChange,
  required = true,
  fieldsPrefix,
  label = 'Zip Code',
  ...props
}) {
  const zipCodeProperties = {
    pattern: '^\\d{5}$'
  };

  let zipCodeRules = [
    {
      message: 'The zip code entered is not correct.',
      pattern: zipCodeProperties.pattern
    }
  ];

  if (required && required_rules) {
    zipCodeRules.push(required_rules);
  }

  return (
    <FormItem
      label={label}
      name={`${fieldsPrefix}ZipCode`}
      rules={zipCodeRules}
      required={required}
      validateTrigger={'onBlur'}
    >
      <Input
        autoComplete="off"
        placeholder="Zip Code"
        allowClear={true}
        onChange={handleOnChange}
        {...props}
      />
    </FormItem>
  );
}

export default memo(ZipCode);
