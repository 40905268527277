import React, { useEffect } from 'react';
import { replace } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useProcessedDealerId } from 'utils/hooks';

import TwoColumns from 'components/common/Content/TwoColumns';
import Result from 'components/common/Result';
import CustomSpin from 'components/common/Spin';

import { resetApp, setForCustomer, setForQRCode } from 'redux/app/actions';
import { saveDealerNumber } from 'redux/tokens/actions';

import { ROUTES } from 'consts/routes';

const Dealer = ({ forCustomer = false, forQRCode = false }) => {
  const dispatch = useDispatch();
  const dealerId = useProcessedDealerId();

  useEffect(() => {
    if (!dealerId) {
      dispatch(replace(ROUTES.HOME));
    }

    if (dealerId) {
      dispatch(resetApp());
      dispatch(saveDealerNumber(dealerId));
      dispatch(replace(ROUTES.APPLY_FORM));
    }

    if (forCustomer) {
      dispatch(setForCustomer());
    }
    if (forQRCode) {
      dispatch(setForQRCode());
    }
  }, [dealerId, forCustomer, forQRCode, dispatch]);

  return (
    <TwoColumns md={18} showSecondColumn={false}>
      <Result
        icon={
          <CustomSpin
            size="large"
            tip="Processing your request, please stand by..."
          />
        }
      />
    </TwoColumns>
  );
};

export default Dealer;
