import { useEffect, useState } from 'react';

export const ImageWithValidation = ({
  src,
  alt,
  fallback = null,
  ...props
}) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => setImageSrc(src);
    img.onerror = () => setImageSrc(fallback);
  }, [src, fallback]);

  if (!imageSrc) {
    return null;
  }

  return <img src={imageSrc} alt={alt} {...props} />;
};
