import { memo } from 'react';

const { InfoCircleFilled } = require('@ant-design/icons');
const { Tooltip } = require('antd');
const { Button } = require('components/common/Buttons');

function LabelInfo({
  text = false,
  condition,
  handleFunction,
  tooltip = 'Show Information'
}) {
  return (
    <>
      {text ? text : null}
      {condition ? (
        <Tooltip title={tooltip}>
          <Button
            size="small"
            type="link"
            shape="circle"
            icon={<InfoCircleFilled />}
            onClick={handleFunction}
          />
        </Tooltip>
      ) : null}
    </>
  );
}

export default memo(LabelInfo);
