import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useServiceWorker } from 'components/services/ServiceWorker';
import {
  logMessageInConsole,
  WARNING_NOTIFICATION_TYPE
} from 'utils/notifications';

const NewVersion = () => {
  const dispatch = useDispatch();

  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const [loading, setLoading] = useState(false);

  const updateNow = useCallback(
    e => {
      // Feedback to user when the app is updating.
      logMessageInConsole(
        'The application is updating...',
        WARNING_NOTIFICATION_TYPE
      );
      setLoading(true);
      updateAssets();
    },
    [dispatch, updateAssets]
  );

  const handleClick = e => {
    updateNow();
  };

  useEffect(() => {
    if (isUpdateAvailable && !loading) {
      updateNow();
    }
  }, [isUpdateAvailable, loading, updateNow]);

  return null;
};

export default NewVersion;
