import React from 'react';

import { Radio } from 'components/common/Inputs';
import Fieldset from '../../Components/Fieldset';
import { GUTTER_DEFAULT } from 'theme/variables';
import parse from 'html-react-parser';
import { SubTitle } from 'components/common/Typography';

const { Form, Row, Col } = require('antd');

function ValidItem({ fieldName, address, validAddress }) {
  return (
    <Form.Item
      name={fieldName}
      rules={[
        {
          required: true,
          message: 'This field is required.'
        }
      ]}
      required
    >
      <Radio.Group style={{ width: '100%' }}>
        <Row gutter={GUTTER_DEFAULT}>
          <Col xs={24} sm={12}>
            <Fieldset title="Current address">
              <Radio value="entered">
                <SubTitle>{parse(address)}</SubTitle>
              </Radio>
            </Fieldset>
          </Col>
          <Col xs={24} sm={12}>
            <Fieldset title="USPS Verified address">
              <Radio value="validated">
                <SubTitle>{parse(validAddress)}</SubTitle>
              </Radio>
            </Fieldset>
          </Col>
        </Row>
      </Radio.Group>
    </Form.Item>
  );
}

export default ValidItem;
