import { Button } from 'components/common/Buttons';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import { generatePath } from 'react-router-dom';

import { selectIsForBDCode, selectIsForBECode } from 'redux/app/selectors';
import { selectDealerNumber } from 'redux/tokens/selectors';

import { resetApp } from 'redux/app/actions';

import { ROUTES } from 'consts/routes';

function Close() {
  const dealerId = useSelector(selectDealerNumber);
  const isForBDCode = useSelector(selectIsForBDCode);
  const isForBECode = useSelector(selectIsForBECode);

  const url = isForBDCode
    ? generatePath(ROUTES.BD_CODE, { dealerId })
    : isForBECode === true
    ? generatePath(ROUTES.BE_CODE, { dealerId })
    : ROUTES.HOME;

  const dispatch = useDispatch();

  const handleCloseOnClick = () => {
    dispatch(resetApp());
    dispatch(replace(url));
  };

  return (
    <Button htmlType="button" onClick={handleCloseOnClick} type="secondary">
      Close
    </Button>
  );
}

export default memo(Close);
