import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import LayoutCentered from 'components/common/Content/LayoutCentered';
import FormBe from './FormBe';
import { LayerForm } from 'components/common/LayerForm';

import { resetApp, setForBeCode } from 'redux/app/actions';
import { useProcessedDealerId } from 'utils/hooks';
import { saveDealerNumber } from 'redux/tokens/actions';

function LandingPageForBe({ ...props }) {
  const dispatch = useDispatch();
  const dealerId = useProcessedDealerId();

  useEffect(() => {
    dispatch(resetApp());
    dispatch(saveDealerNumber(dealerId));
    dispatch(setForBeCode());
  }, []);

  return (
    <LayoutCentered type="formBe">
      <LayerForm>
        <FormBe />
      </LayerForm>
    </LayoutCentered>
  );
}

export default LandingPageForBe;
