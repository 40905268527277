import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';

import { Layout as AntLayout } from 'antd';

import {
  BACKGROUND_DEFAULT,
  BORDER_DEFAULT,
  COMPANY_NAME_BLACK,
  GRAY_BACKGROUND
} from 'theme/variables';
import Logo from 'components/common/Logo';

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CustomName = styled.span`
  color: ${props => (props.color ? props.color : COMPANY_NAME_BLACK)};
  display: inline;
`;

export const AppName = ({ ...props }) => {
  return <CustomName> Apply</CustomName>;
};

const Layout = ({ ...props }) => {
  const CustomLayout = styled(AntLayout)``;
  return <CustomLayout {...props} />;
};

// HEADER
const CustomHeader = styled(AntLayout.Header)`
  &.ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${BACKGROUND_DEFAULT};
    padding: 0 20px;
    border-bottom: ${BORDER_DEFAULT};
    height: 40px;
    font-size: 20px;
  }
`;

export const Header = ({ ...props }) => {
  return (
    <CustomHeader {...props}>
      <AppName />
      <Logo />
    </CustomHeader>
  );
};

// SIDER
const CustomSider = styled(AntLayout.Sider)`
  background: ${BACKGROUND_DEFAULT};

  &.pdf-viewer-sider {
    background: ${GRAY_BACKGROUND};
  }

  .ant-layout-sider-zero-width-trigger {
    top: 0;
    border-radius: 0;
  }
`;

export const Sider = ({ show, ...props }) => {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setCollapsed(show);
  }, [show]);

  return (
    <CustomSider
      {...props}
      collapsible
      collapsedWidth="0"
      collapsed={collapsed}
      trigger={null}
    >
      {props.children}
    </CustomSider>
  );
};

export const MainContainer = styled.div`
  flex: 1;
  height: 100%;
`;

// CONTENT
const CustomContent = styled(AntLayout.Content)`
  overflow: hidden;
  box-shadow: 0 5px 15px -10px #000000 inset;
`;

export class Content extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    return <CustomContent>{this.props.children}</CustomContent>;
  }
}

Layout.propTypes = {
  isContentLayout: PropTypes.bool,
  expanded: PropTypes.bool
};

Layout.defaultValues = {
  expanded: true
};

export default Layout;
