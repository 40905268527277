/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import tokensReducer from './tokens/reducer';
import appReducer from './app/reducer';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
const createRootReducer = (history, asyncReducers) => {
  return combineReducers({
    router: connectRouter(history),
    tokens: tokensReducer,
    app: appReducer,
    ...asyncReducers
  });
};

export default createRootReducer;
