import styled from 'styled-components';

import { Layout as LayoutAnt } from 'antd';
import {
  BACKGROUND_DEFAULT,
  BORDER_DEFAULT,
  WIDTH_BREAKPOINT_SM,
  LINE_HEIGHT
} from 'theme/variables';

export const LeftLayout = styled(LayoutAnt)`
  background: ${BACKGROUND_DEFAULT};
  height: 100%;
`;

export const LeftHeader = styled(LayoutAnt.Header)`
  height: auto;
  background: ${BACKGROUND_DEFAULT};
  line-height: ${LINE_HEIGHT};
  padding: 20px 20px 0;

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
    & {
      padding: 10px;
    }
  }
`;

export const LeftContent = styled(LayoutAnt.Content)`
  background: ${BACKGROUND_DEFAULT};
  padding: 0;
  overflow: hidden;
  height: 100%;

  &.left-content {
    padding: 0 20px;
  }

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
    &.left-content {
      padding: 0 10px;
    }
  }
`;

export const LeftFooter = styled(LayoutAnt.Footer)`
  background: ${BACKGROUND_DEFAULT};
  padding: 10px 20px;
  border-top: ${BORDER_DEFAULT};
  z-index: 9;

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
    padding: 10px;

    .inset {
      & {
        margin: 0 20px;
      }
    }
  }
`;

const Layout = styled(LayoutAnt)`
  height: 100%;
`;
export const Header = styled(LayoutAnt.Header)`
  &.pdf-viewer-toolbar {
    height: auto;
    line-height: ${LINE_HEIGHT};
    padding: 0;
    z-index: 999;
  }
`;
export const Content = styled(LayoutAnt.Content)``;
export const Footer = styled(LayoutAnt.Footer)``;

export default Layout;
