import React from 'react';
import styled from 'styled-components';

export const ContainerForBd = styled.div`
  position: relative;
  max-width: 1440px;
  width: 100%;
`;

export function ContainerFluid({ children, ...props }) {
  const { mg, pd, bg } = props;
  return (
    <ContainerForBd style={{ margin: mg, padding: pd, background: bg }}>
      {children}
    </ContainerForBd>
  );
}

export default ContainerFluid;
