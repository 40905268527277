import React, { memo, useCallback } from 'react';
import {
  selectConfirmedAddresses,
  selectHasCoApplicant,
  selectIsForQRCode
} from 'redux/app/selectors';
import { Button } from 'components/common/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCoApplicant,
  setConfirmedAddresseses,
  showCoApplicantModal
} from 'redux/app/actions';
import ApplicantForm from './ApplicantForm';
import {
  COBORROWER_NOMENCLATURE,
  CO_APPLICANT_PREFIX
} from 'redux/app/constants';
import AddCoApplicant from './Modals/AddCoApplicant';
import { useEnterApplicantDataManuallyData } from 'hooks/data';

function CoApplicantForm({ title, required_rules, form }) {
  const dispatch = useDispatch();
  const hasCoApplicant = useSelector(selectHasCoApplicant);
  const confirmedAddresses = useSelector(selectConfirmedAddresses);
  const applicantDataManually = useEnterApplicantDataManuallyData(
    COBORROWER_NOMENCLATURE
  );
  const isForQRCode = useSelector(selectIsForQRCode);

  const removeCoApplicantConfirmedAddress = useCallback(() => {
    let newConfirmedAddresses = { ...confirmedAddresses };
    delete newConfirmedAddresses[CO_APPLICANT_PREFIX];
    dispatch(setConfirmedAddresseses(newConfirmedAddresses));
  }, [confirmedAddresses, dispatch]);

  const handleAddCoApplicantOnClick = () => {
    dispatch(addCoApplicant(true));
    dispatch(showCoApplicantModal(true));
  };

  if (isForQRCode) {
    return null;
  }

  return (
    <>
      {hasCoApplicant ? (
        applicantDataManually ? (
          <ApplicantForm
            fieldsPrefix={CO_APPLICANT_PREFIX}
            required_rules={required_rules}
            form={form}
            applicantType={COBORROWER_NOMENCLATURE}
            title="Co-Applicant"
          />
        ) : null
      ) : (
        <Button type="secondary" onClick={handleAddCoApplicantOnClick}>
          Add Co-Applicant
        </Button>
      )}
      <AddCoApplicant
        required_rules={required_rules}
        form={form}
        applicantType={COBORROWER_NOMENCLATURE}
        fieldsPrefix={CO_APPLICANT_PREFIX}
        title="Co-Applicant"
      />
    </>
  );
}

export default memo(CoApplicantForm);
