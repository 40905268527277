import axios from 'axios';
import store from 'redux/store';
import { BASE_URL, BASE_URL_HEIMDAL } from 'config/variables';
import { responseOk, responseError } from './interceptors';

const TIME_OUT = 60000;
export const TIME_OUT_MESSAGE = 'timeout';

/**
 * Creates a cancellable axios instance
 * @param {object} config
 */
function createCancelableAxios(config) {
  let cancel;
  const instance = axios.create({
    ...config,
    cancelToken: new axios.CancelToken(c => {
      cancel = c;
    })
  });

  instance.cancel = message => {
    cancel(message);
    instance.defaults.cancelToken = new axios.CancelToken(c => {
      cancel = c;
    });
  };

  return instance;
}

axios.defaults.timeoutErrorMessage = TIME_OUT_MESSAGE;

axios.defaults.headers.post['Content-Type'] = 'application/json';

const regularAxios = createCancelableAxios({
  baseURL: BASE_URL,
  timeout: TIME_OUT
});
const regularHeimdalAxios = createCancelableAxios({
  baseURL: BASE_URL_HEIMDAL,
  timeout: TIME_OUT
});
const authorizedAxios = createCancelableAxios({
  baseURL: BASE_URL,
  timeout: TIME_OUT
});
const userAxios = createCancelableAxios({
  baseURL: BASE_URL,
  timeout: TIME_OUT
});

regularAxios.defaults.headers.common['Content-Type'] = 'application/json';
regularHeimdalAxios.defaults.headers.common['Content-Type'] =
  'application/json';
authorizedAxios.defaults.headers.common['Content-Type'] = 'application/json';
userAxios.defaults.headers.common['Content-Type'] = 'application/json';

authorizedAxios.interceptors.request.use(config => {
  const currentToken = store.getState().getIn(['tokens', 'currentToken']);

  config.headers.Authorization = `Bearer ${currentToken}`;

  return config;
});

userAxios.interceptors.request.use(config => {
  const userToken = store.getState().getIn(['tokens', 'userToken']);

  config.headers.Authorization = `Bearer ${userToken}`;

  return config;
});

export const configAxiosInstances = store => {
  // Axios interceptors for all API request as an unauthenticated user
  regularAxios.interceptors.response.use(responseOk, responseError(store));
  regularHeimdalAxios.interceptors.response.use(
    responseOk,
    responseError(store)
  );
  authorizedAxios.interceptors.response.use(responseOk, responseError(store));
  userAxios.interceptors.response.use(responseOk, responseError(store));
};

const isCancelledRequestException = axios.isCancel;

export {
  authorizedAxios,
  regularAxios,
  regularHeimdalAxios,
  userAxios,
  isCancelledRequestException
};
