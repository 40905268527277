import React from 'react';
import styled from 'styled-components';

import { Typography, Row } from 'antd';

import ContainerFluid from '../Content/ContainerFluid';
import { BigText } from '../Typography';

import interview from 'images/interview.jpg';
import { BREAKPOINT_LG } from 'theme/variables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${BREAKPOINT_LG}px) {
    flex-direction: column;
  }
`;

const ColumnImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
`;

const ColumnInfo = styled.div`
  box-sizing: border-box;
  text-align: left;
  padding: 20px;
`;

export const LayerAboutUs = () => {
  return (
    <Row style={{ background: '#fff' }}>
      <ContainerFluid mg="auto" pd="60px 0">
        <Wrapper>
          <ColumnImg>
            <img src={interview} alt="Service Finance Company" width={'340'} />
          </ColumnImg>
          <ColumnInfo>
            <Typography.Title level={2}>Our organization</Typography.Title>
            <BigText>
              <strong>Service Finance Company, LLC</strong> is a nationally
              licensed sales finance company and an approved FHA Title I Lender.
              We provide in excess of fifty financing solutions which include
              promotional and standard installment terms for home improvement
              contractors enrolled in the SFC Financing Program. Our program
              strives to provide our contractors with the flexibility they
              require to offer their customers the ability to finance their
              purchases of products such as: HVAC, Windows, Doors, Siding,
              Sunrooms, Flooring, Water Treatment, Plumbing, Solar, Roofing,
              Insulation and many more.
            </BigText>
          </ColumnInfo>
        </Wrapper>
      </ContainerFluid>
    </Row>
  );
};
