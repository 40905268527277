import styled from 'styled-components';
import { Table as TableAnt } from 'antd';
import { BORDER_DEFAULT, FONT_SIZE_SMALL } from 'theme/variables';

const Table = styled(TableAnt)`
  .ant-table-body {
    overflow-y: auto !important;
  }

  /*.ant-table-body:hover {
    overflow-y: scroll !important;
  } */

  table {
    border-spacing: 0 5px;
  }

  .ant-pagination.ant-pagination-mini .ant-select-selection-item {
    font-size: 14px;
  }

  .ant-table-row .ant-table-cell {
    border-top: ${BORDER_DEFAULT};
    border-bottom: ${BORDER_DEFAULT};
  }
  .ant-table-row .ant-table-cell:first-child {
    border-left: ${BORDER_DEFAULT};
  }

  .ant-table-row .ant-table-cell:last-child {
    border-right: ${BORDER_DEFAULT};
  }

  .ant-table-thead > tr > th {
    font-size: ${FONT_SIZE_SMALL};
  }

  &.align-top {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      vertical-align: text-top;
    }
  }

  .align-middle {
    vertical-align: middle !important;
  }
  /* .ant-table {
    height: 53vh;
    overflow-y: auto;
  } */
`;

export default Table;
