import React, { memo, useCallback, useRef } from 'react';

import { Row, Col, Input, Select } from 'antd';
import { FormItem } from 'components/common/Form';

import { stateData } from '../DataSet';
import ZipCode from './ZipCode';

import { GOOGLE_API_KEY } from 'config/variables';
import { GUTTER_DEFAULT } from 'theme/variables';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useDispatch } from 'react-redux';
import { saveApplyFormValues } from 'redux/app/actions';

const { Option } = Select;

function Address({
  fieldsPrefix,
  form,
  onAddressChange,
  required = false,
  required_rules
}) {
  const dispatch = useDispatch();
  let addressRules = [];
  const streetAddressInputRef = useRef(null);

  const handleOnChange = useCallback(() => {
    if (typeof onAddressChange === 'function') {
      onAddressChange();
    }
  }, [onAddressChange]);

  const handlePlaceSelected = useCallback(
    place => {
      let values = {};
      let streetAddress = '';

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number':
            streetAddress = `${component.long_name} ${streetAddress}`;
            break;
          case 'route':
            streetAddress += component.short_name;
            break;
          case 'postal_code':
            values[`${fieldsPrefix}ZipCode`] = component.long_name;
            break;
          case 'locality':
            values[`${fieldsPrefix}City`] = component.long_name;
            break;
          case 'administrative_area_level_1':
            values[`${fieldsPrefix}State`] = component.short_name;
            break;
          default:
            break;
        }
      }

      values[`${fieldsPrefix}StreetAddress`] = streetAddress;
      form.setFieldsValue(values);
      dispatch(
        saveApplyFormValues({ ...form.getFieldsValue(true), ...values })
      );
      handleOnChange();
    },
    [dispatch, fieldsPrefix, form, handleOnChange]
  );

  const { ref: antRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: handlePlaceSelected,
    options: {
      componentRestrictions: { country: ['us'] },
      fields: ['address_components', 'geometry'],
      types: ['address']
    },
    language: 'en'
  });

  const buildRef = c => {
    streetAddressInputRef.current = c;
    if (c) {
      antRef.current = c.input;
    }
  };

  if (required) {
    addressRules.push(required_rules);
  }

  return (
    <>
      <Row gutter={GUTTER_DEFAULT}>
        {/* Street Address */}
        <Col xs={{ span: 24 }} md={{ span: 18 }} lg={{ span: 16 }}>
          <FormItem
            label="Street Address"
            name={`${fieldsPrefix}StreetAddress`}
            rules={[required_rules]}
            required
          >
            <Input
              autoComplete="off"
              placeholder="Street Address"
              ref={buildRef}
              allowClear={true}
              onChange={handleOnChange}
            />
          </FormItem>
        </Col>

        {/* Unit/Apt. # */}
        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 4 }}>
          <FormItem label="Unit/Apt. #" name={`${fieldsPrefix}UnitApt`}>
            <Input
              autoComplete="off"
              placeholder="Unit/Apt. #"
              allowClear
              onChange={handleOnChange}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={GUTTER_DEFAULT}>
        {/* City */}
        <Col xs={{ span: 24 }} md={{ span: 9 }} lg={{ span: 8 }}>
          <FormItem
            label="City"
            name={`${fieldsPrefix}City`}
            rules={[required_rules]}
            required
          >
            <Input
              autoComplete="off"
              placeholder="City"
              allowClear
              onChange={handleOnChange}
            />
          </FormItem>
        </Col>

        {/* State */}
        <Col xs={{ span: 24 }} md={{ span: 9 }} lg={{ span: 8 }}>
          <FormItem
            label="State"
            name={`${fieldsPrefix}State`}
            rules={[required_rules]}
            required
          >
            <Select placeholder="Select State" onChange={handleOnChange}>
              {stateData.map((item, index) => {
                return (
                  <Option key={`state-${index}`} value={item.value}>
                    {item.text}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
        </Col>

        {/* Zip Code */}
        <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 4 }}>
          <ZipCode
            required_rules={required_rules}
            fieldsPrefix={fieldsPrefix}
          />
        </Col>
      </Row>
    </>
  );
}

export default memo(Address);
