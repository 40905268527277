import { makeActionCreator } from 'redux/utils';
import * as CONSTANTS from './constants';

// Websocket general actions
export const wsInit = makeActionCreator(CONSTANTS.WS_INIT);

export const wsConnected = makeActionCreator(CONSTANTS.WS_CONNECTED);

export const wsRetryConnection = makeActionCreator(
  CONSTANTS.WS_RETRY_CONNECTION
);

export const wsReConnecting = makeActionCreator(
  CONSTANTS.WS_RECONNECTING,
  'error'
);

export const wsReConnected = makeActionCreator(
  CONSTANTS.WS_RECONNECTED,
  'connectionId'
);

export const wsError = makeActionCreator(CONSTANTS.WS_ERROR, 'error');

export const wsClosed = makeActionCreator(CONSTANTS.WS_CLOSED);

export const wsStop = makeActionCreator(CONSTANTS.WS_STOP);

// Notifications

export const wsSendNotification = makeActionCreator(
  CONSTANTS.WS_SEND_NOTIFICATION,
  'notification'
);

export const wsNotificationReceived = makeActionCreator(
  CONSTANTS.WS_NOTIFICATION_RECEIVED,
  'notification'
);

// Open Item Data
export const wsOpenItemDataReceived = makeActionCreator(
  CONSTANTS.WS_OPEN_ITEM_DATA_RECEIVED,
  'payload'
);

// Close Item Data
export const wsCloseItemDataReceived = makeActionCreator(
  CONSTANTS.WS_CLOSE_ITEM_DATA_RECEIVED,
  'payload'
);

// Save Item received
export const wsSaveOpenItemDataReceivedSaga = makeActionCreator(
  CONSTANTS.WS_SAVE_OPEN_ITEM_DATA_RECEIVED,
  'payload'
);
