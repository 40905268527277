import React, { memo, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { useIsMobile } from 'hooks/responsive';

import { Row, Col } from 'antd';
import Background from 'components/common/Content/Background';
import Divider from 'components/common/Divider';
import Logo, { OtherLogo } from 'components/common/Logo';

import {
  BACKGROUND_COLOR_LIGHT,
  BORDER_RADIUS,
  MAIN_COLOR
} from 'theme/variables';
import {
  LeftLayout,
  LeftHeader,
  LeftContent,
  LeftFooter
} from 'components/layouts/HeaderContentFooter';
import { Title } from '../Typography';

import backgroundImg from 'images/background.jpeg';

const TwoColumnsRow = styled(Row)`
  height: 100%;
  margin: 0;
  position: relative;
`;

const TwoColumnsLeftCol = styled(Col)`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
`;

const TwoColumnsRightCol = styled(Col)``;

const RowOutsideBackground = styled(Row)`
  height: 0;
  position: relative;
`;

const ColOutsideBackground = styled(Col)`
  &::before {
    content: '';
    position: absolute;
    height: 100vh;
    width: 100%;
    background: ${MAIN_COLOR};
    top: ${props => (props.top ? `-${props.top}px` : 0)};
    z-index: -1;
  }
`;

const TopRightContainer = styled.div`
  background: ${BACKGROUND_COLOR_LIGHT};
  height: 10%;
  margin-bottom: 10px;
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
`;

const TwoColumnsImage = styled.div`
  height: calc(90% - 10px);
`;

export const imageSetting = {
  source: backgroundImg,
  position: '45% 30%'
};

function TwoColumns({
  headerTitle = '',
  headerSubtitle,
  title,
  children,
  imageSrc,
  imageDirection,
  md = 16,
  xs = 24,
  topRightContent,
  showOutsideBackground = true,
  showSecondColumn = true,
  actions,
  extraContent,
  extraLogo = false,
  overflowContent = 'auto',
  ...props
}) {
  const isMobile = useIsMobile();
  const rowRef = useRef(null);
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    if (rowRef) {
      setOffsetTop(rowRef.current.offsetTop);
    }
  }, [rowRef]);

  useEffect(() => {
    const resizeListener = () => {
      if (rowRef && offsetTop !== rowRef.current.offsetTop) {
        setOffsetTop(rowRef.current.offsetTop);
      }
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, [rowRef, offsetTop]);

  return (
    <>
      <div ref={rowRef}></div>
      {showOutsideBackground && (
        <RowOutsideBackground>
          <Col md={md} xs={xs}></Col>
          <ColOutsideBackground
            flex="auto"
            top={offsetTop}
          ></ColOutsideBackground>
        </RowOutsideBackground>
      )}
      <TwoColumnsRow>
        <TwoColumnsLeftCol
          md={showSecondColumn ? md : 24}
          xs={showSecondColumn ? xs : 24}
          {...props}
        >
          <LeftLayout>
            <LeftHeader>
              <Row justify="space-between">
                <Col>
                  <Logo title={headerTitle} subtitle={headerSubtitle} />
                </Col>
                {extraLogo && <Col>{extraLogo}</Col>}
              </Row>
              <Divider margin={'10px 0'} />
              {title && <Title>{title}</Title>}
              {extraContent}
            </LeftHeader>
            <LeftContent
              className="left-content"
              style={{ overflowY: overflowContent }}
            >
              {children}
            </LeftContent>
            {actions && (
              <LeftFooter className="inset">
                <Row gutter={[16, 24]}>
                  <Col id="form-actions">{actions}</Col>
                </Row>
              </LeftFooter>
            )}
          </LeftLayout>
        </TwoColumnsLeftCol>
        {!isMobile && showSecondColumn && (
          <TwoColumnsRightCol flex="auto" top={offsetTop}>
            <TopRightContainer>{topRightContent}</TopRightContainer>
            <TwoColumnsImage>
              <Background
                backgroundSrc={imageSrc}
                backgroundDirection={imageDirection}
              />
            </TwoColumnsImage>
          </TwoColumnsRightCol>
        )}
      </TwoColumnsRow>
    </>
  );
}

export default memo(TwoColumns);
