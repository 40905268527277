import React from 'react';
import { useSelector } from 'react-redux';

import { Col } from 'antd';
import Content from 'components/common/Content';
import ApplicantForm from '../ApplicantForm';

import {
  selectIsForBDCode,
  selectIsForBECode,
  selectIsForQRCode
} from 'redux/app/selectors';

import { APPLICANT_PREFIX, BORROWER_NOMENCLATURE } from 'redux/app/constants';

function Applicant({ required_rules, form }) {
  const isForQRCode = useSelector(selectIsForQRCode);
  const isForBDCode = useSelector(selectIsForBDCode);
  const isForBECode = useSelector(selectIsForBECode);

  const applicantComponent = (
    <ApplicantForm
      title="Applicant"
      required_rules={required_rules}
      form={form}
      applicantType={BORROWER_NOMENCLATURE}
      fieldsPrefix={APPLICANT_PREFIX}
    />
  );

  if (isForQRCode || isForBDCode || isForBECode) {
    return (
      <Col sm={{ span: 24 }} xl={{ span: 20 }} xxl={{ span: 16 }}>
        {applicantComponent}
      </Col>
    );
  }

  return (
    <Col sm={{ span: 24 }} xl={{ span: 20 }} xxl={{ span: 16 }}>
      <Content title="Applicants" bordered={false}>
        {applicantComponent}
      </Content>
    </Col>
  );
}

export default Applicant;
