import React from 'react';

import { useIsMobile } from 'hooks/responsive';

import ExceptionPage from '../ExceptionPage';
import notFoundImg from 'images/not_found.svg';

// prettier-ignore

const NotFound = () => {
  const isMobile = useIsMobile();

  return (
    <ExceptionPage
      icon={
        <img
          alt="Not authorized"
          src={notFoundImg}
          width={isMobile ? '85%' : 400}
        />
      }
      titlePage="Sorry, page not found."
      message={'The resource you are trying to access does not exist.'}
    />
  );
};

export default NotFound;
