import React from 'react';
import styled from 'styled-components';
import { Form as FormAnt } from 'antd';

import {
  ERROR_COLOR,
  FONT_SIZE_SMALL,
  FONT_WEIGHT,
  GRAY_BACKGROUND
} from 'theme/variables';
import { WarningOutlined } from '@ant-design/icons';

export const Form = styled(FormAnt)``;

export const FormItem = styled(FormAnt.Item)`
  margin-bottom: 10px;

  &.force-block > .ant-row.ant-form-item-row {
    flex-flow: column wrap;

    & > .ant-form-item-label {
      text-align: left;
    }
  }

  &.ant-form-item .ant-form-item-label > label {
    font-weight: ${FONT_WEIGHT};
  }

  &.ant-form-item-has-error .ant-form-item-explain {
    font-size: ${FONT_SIZE_SMALL};
  }

  .ant-form-item-extra {
    background: ${GRAY_BACKGROUND};
    padding: 5px 10px;
    font-style: italic;

    h1.ant-typography {
      line-height: 1.5;
    }
  }
`;

const FormErrorsMessageContainer = styled.div`
  color: ${ERROR_COLOR};
  margin-bottom: 10px;
  border-bottom: 1px solid ${ERROR_COLOR};
`;

export const FormErrorsMessage = ({ title = false, description }) => {
  return (
    <FormErrorsMessageContainer>
      <WarningOutlined style={{ color: ERROR_COLOR, marginRight: '10px' }} />
      <span>{description}</span>
    </FormErrorsMessageContainer>
  );
};
