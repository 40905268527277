import {
  ConsoleSqlOutlined,
  FormOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Button } from 'components/common/Buttons';
import { Information } from 'components/common/Text';
import {
  useCustomerIdentityIsComplete,
  useEnterApplicantDataManuallyData
} from 'hooks/data';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  enterApplicantDataManually,
  getCustomerIdentityStop
} from 'redux/app/actions';
import {
  selectIsForBDCode,
  selectIsForBECode,
  selectIsForQRCode
} from 'redux/app/selectors';
import { FONT_SIZE_SMALL, FONT_WEIGHT } from 'theme/variables';

function EnterDataOption({ applicantType, ...props }) {
  const dispatch = useDispatch();

  const isForQRCode = useSelector(selectIsForQRCode);
  const isForBDCode = useSelector(selectIsForBDCode);
  const isForBECode = useSelector(selectIsForBECode);

  const applicantDataManually =
    useEnterApplicantDataManuallyData(applicantType);

  const customerIdentityIsComplete =
    useCustomerIdentityIsComplete(applicantType);

  const handleSwitchManuallyOption = (e, { applicantType, value }) => {
    dispatch(getCustomerIdentityStop({ applicantType }));
    dispatch(enterApplicantDataManually({ applicantType, value }));
  };

  const manuallyDataOnSetup = {
    text: 'You can enter the information manually or you can:',
    action: 'Let Service Finance find the request data'
  };

  const manuallyDataOffSetup = {
    text: "Let's begin by finding the information or you can:",
    action: 'Enter the request data manually.'
  };

  const infoData = applicantDataManually
    ? manuallyDataOnSetup
    : manuallyDataOffSetup;

  if (isForQRCode || isForBDCode || isForBECode) {
    return null;
  }

  return !customerIdentityIsComplete ? (
    <Information {...props}>
      {infoData.text}
      <Button
        type="link"
        icon={applicantDataManually ? <SearchOutlined /> : <FormOutlined />}
        onClick={handleSwitchManuallyOption}
        params={{ applicantType, value: !applicantDataManually }}
        style={{
          padding: '0 0 0 5px',
          fontWeight: `${FONT_WEIGHT}`,
          fontSize: `${FONT_SIZE_SMALL}`,
          lineHeight: 0
        }}
      >
        {infoData.action}
      </Button>
    </Information>
  ) : null;
}

export default memo(EnterDataOption);
