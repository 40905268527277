import React, { memo, useState } from 'react';
import { FormItem } from 'components/common/Form';
import { Radio } from 'antd';

function TypeOfLoan({ required_rules, required, fieldsPrefix }) {
  const [typeValue, setTypeValue] = useState(0);
  const typeOfLoanRules = [];

  const handleOnChange = e => {
    setTypeValue(e.target.value);
  };

  if (required) {
    typeOfLoanRules.push({ required, message: 'Please pick an item.' });
  }

  return (
    <FormItem
      label="Type of Loan"
      name={`${fieldsPrefix}TypeOfLoan`}
      rules={typeOfLoanRules}
    >
      <Radio.Group onChange={handleOnChange}>
        <Radio value={1}>Solar</Radio>
        <Radio value={2}>Home Improvement</Radio>
      </Radio.Group>
    </FormItem>
  );
}

export default memo(TypeOfLoan);
