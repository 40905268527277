import { makeActionCreator } from 'redux/utils';
import {
  RESET_APP,
  ADD_COAPPLICANT,
  SUBMIT_APPLY_FORM,
  SUBMIT_APPLY_FORM_SUCCESS,
  SUBMIT_APPLY_FORM_FAILED,
  VALIDATE_ADDRESS,
  VALIDATE_ADDRESS_SUCCESS,
  VALIDATE_ADDRESS_FAILED,
  RESET_VALIDATED_ADDRESS,
  SAVE_APPLY_FORM_VALUES,
  SHOW_ADDRESS_VALIDATION,
  CONFIRMED_ADDRESSES,
  RESET_RESULTS,
  GET_VERIFY_PRODUCT_BY_DEALER,
  GET_VERIFY_PRODUCT_BY_DEALER_SUCCESS,
  GET_VERIFY_PRODUCT_BY_DEALER_FAILED,
  PUT_VERIFY_PRODUCT_BY_DEALER,
  PUT_VERIFY_PRODUCT_BY_DEALER_SUCCESS,
  PUT_VERIFY_PRODUCT_BY_DEALER_FAILED,
  ENTER_APPLICANT_DATA_MANUALLY,
  GET_CUSTOMER_IDENTITY,
  GET_CUSTOMER_IDENTITY_SUCCESS,
  GET_CUSTOMER_IDENTITY_FAILED,
  SET_CUSTOMER_IDENTITY_ERROR_MESSAGE,
  GET_CUSTOMER_IDENTITY_DONE,
  GET_CUSTOMER_IDENTITY_STOP,
  PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL,
  PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_SUCCESS,
  PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_FAILED,
  SHOW_ADDRESS_LIST_TO_SELECT,
  CLOSE_ADDRESS_LIST_TO_SELECT,
  SAVE_CUSTOMER_IDENTITY_ADDRESS,
  SHOW_ADD_COAPPLICANT_MODAL,
  SET_VERIFICATION_PARAMS,
  SET_FOR_CUSTOMER,
  SET_FOR_QR_CODE,
  SET_FOR_VERIFICATION_CODE,
  RESET_DATA_CUSTOMER_IDENTITY,
  STOPPED_CURRENT_APPLICATION,
  INIT_RETRIEVE_APPLICATION_DATA,
  INIT_RETRIEVE_APPLICATION_DATA_SUCCESS,
  INIT_RETRIEVE_APPLICATION_DATA_FAILED,
  SAVE_PREVIOUS_DATA,
  SET_ERROR_MESSAGE,
  SET_APPLICANT_CONSENT_APPROVED,
  SET_MESSAGE,
  SET_FOR_BD_CODE,
  RESET_VERIFICATION_PARAMS,
  SKIP_INPUT_FORM,
  SET_FOR_BE_CODE,
  GET_CALL_REPORT,
  GET_CALL_REPORT_SUCCESS,
  GET_CALL_REPORT_FAILED,
  RELOAD_CALL_REPORT,
  SET_SEARCH_TEXT,
  SET_ASSOCIATION_FORM_INFO,
  SET_CURRENT_ASSOCIATION_NUMBER,
  GET_MAP_BY_COORDINATES,
  GET_MAP_BY_COORDINATES_SUCCESS,
  GET_MAP_BY_COORDINATES_FAILED,
  CLOSE_ADDRESS_MAP
} from './constants';

export const resetApp = makeActionCreator(RESET_APP);

export const addCoApplicant = makeActionCreator(ADD_COAPPLICANT, 'payload');

export const saveApplyFormValues = makeActionCreator(
  SAVE_APPLY_FORM_VALUES,
  'payload'
);

export const submitApplyForm = makeActionCreator(SUBMIT_APPLY_FORM);
export const submitApplyFormSuccess = makeActionCreator(
  SUBMIT_APPLY_FORM_SUCCESS,
  'payload'
);
export const submitApplyFormFailed = makeActionCreator(
  SUBMIT_APPLY_FORM_FAILED,
  'error'
);

export const validateAddress = makeActionCreator(VALIDATE_ADDRESS, 'payload');
export const validateAddressSuccess = makeActionCreator(
  VALIDATE_ADDRESS_SUCCESS,
  'payload'
);
export const validateAddressFailed = makeActionCreator(
  VALIDATE_ADDRESS_FAILED,
  'error'
);

export const showAddressValidation = makeActionCreator(
  SHOW_ADDRESS_VALIDATION,
  'payload'
);
export const resetValidatedAddress = makeActionCreator(RESET_VALIDATED_ADDRESS);
export const setConfirmedAddresseses = makeActionCreator(
  CONFIRMED_ADDRESSES,
  'payload'
);

export const resetResults = makeActionCreator(RESET_RESULTS);

export const getVerifyProductByDealer = makeActionCreator(
  GET_VERIFY_PRODUCT_BY_DEALER,
  'payload'
);
export const getVerifyProductByDealerSuccess = makeActionCreator(
  GET_VERIFY_PRODUCT_BY_DEALER_SUCCESS,
  'payload'
);
export const getVerifyProductByDealerFailed = makeActionCreator(
  GET_VERIFY_PRODUCT_BY_DEALER_FAILED,
  'error'
);

export const putVerifyProductByDealer = makeActionCreator(
  PUT_VERIFY_PRODUCT_BY_DEALER,
  'payload'
);
export const putVerifyProductByDealerSuccess = makeActionCreator(
  PUT_VERIFY_PRODUCT_BY_DEALER_SUCCESS,
  'payload'
);
export const putVerifyProductByDealerFailed = makeActionCreator(
  PUT_VERIFY_PRODUCT_BY_DEALER_FAILED,
  'error'
);

export const enterApplicantDataManually = makeActionCreator(
  ENTER_APPLICANT_DATA_MANUALLY,
  'payload'
);

export const skipInputForm = makeActionCreator(SKIP_INPUT_FORM);

export const getCustomerIdentity = makeActionCreator(
  GET_CUSTOMER_IDENTITY,
  'payload'
);

export const getCustomerIdentitySuccess = makeActionCreator(
  GET_CUSTOMER_IDENTITY_SUCCESS,
  'payload'
);

export const getCustomerIdentityDone = makeActionCreator(
  GET_CUSTOMER_IDENTITY_DONE,
  'payload'
);

export const getCustomerIdentityStop = makeActionCreator(
  GET_CUSTOMER_IDENTITY_STOP,
  'payload'
);

export const getCustomerIdentityFailed = makeActionCreator(
  GET_CUSTOMER_IDENTITY_FAILED,
  'payload'
);

export const saveCustomerIdentityAddress = makeActionCreator(
  SAVE_CUSTOMER_IDENTITY_ADDRESS,
  'payload'
);

export const resetDataCustomerIdentity = makeActionCreator(
  RESET_DATA_CUSTOMER_IDENTITY,
  'payload'
);

export const setCustomerIdentityErrorMessage = makeActionCreator(
  SET_CUSTOMER_IDENTITY_ERROR_MESSAGE,
  'payload'
);

export const putMobileVerificationAsSuccessful = makeActionCreator(
  PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL,
  'payload'
);

export const putMobileVerificationAsSuccessfulSuccess = makeActionCreator(
  PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_SUCCESS,
  'payload'
);

export const putMobileVerificationAsSuccessfulFailed = makeActionCreator(
  PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_FAILED,
  'error'
);

export const showAddressListToSelect = makeActionCreator(
  SHOW_ADDRESS_LIST_TO_SELECT,
  'payload'
);

export const closeAddressListToSelect = makeActionCreator(
  CLOSE_ADDRESS_LIST_TO_SELECT
);

export const showCoApplicantModal = makeActionCreator(
  SHOW_ADD_COAPPLICANT_MODAL,
  'payload'
);

export const setVerificationParams = makeActionCreator(
  SET_VERIFICATION_PARAMS,
  'payload'
);
export const resetVerificationParams = makeActionCreator(
  RESET_VERIFICATION_PARAMS
);

export const setForCustomer = makeActionCreator(SET_FOR_CUSTOMER, 'payload');
export const setForQRCode = makeActionCreator(SET_FOR_QR_CODE, 'payload');
export const setForBdCode = makeActionCreator(SET_FOR_BD_CODE, 'payload');
export const setForBeCode = makeActionCreator(SET_FOR_BE_CODE, 'payload');
export const setForVerificationCode = makeActionCreator(
  SET_FOR_VERIFICATION_CODE,
  'payload'
);

export const stoppedCurrentApplication = makeActionCreator(
  STOPPED_CURRENT_APPLICATION
);

export const initRetrieveApplicationData = makeActionCreator(
  INIT_RETRIEVE_APPLICATION_DATA,
  'payload'
);

export const initRetrieveApplicationDataSuccess = makeActionCreator(
  INIT_RETRIEVE_APPLICATION_DATA_SUCCESS,
  'payload'
);

export const initRetrieveApplicationDataFailed = makeActionCreator(
  INIT_RETRIEVE_APPLICATION_DATA_FAILED,
  'payload'
);

export const savePreviousData = makeActionCreator(
  SAVE_PREVIOUS_DATA,
  'payload'
);

export const setErrorMessage = makeActionCreator(SET_ERROR_MESSAGE, 'payload');
export const setMessage = makeActionCreator(SET_MESSAGE, 'payload');
export const setPhoneConsentApproved = makeActionCreator(
  SET_APPLICANT_CONSENT_APPROVED,
  'payload'
);
//CALL REPORT
export const getCallReport = makeActionCreator(GET_CALL_REPORT, 'payload');
export const reloadCallReport = makeActionCreator(
  RELOAD_CALL_REPORT,
  'payload'
);
export const getCallReportSuccess = makeActionCreator(
  GET_CALL_REPORT_SUCCESS,
  'payload'
);
export const getCallReportFailed = makeActionCreator(
  GET_CALL_REPORT_FAILED,
  'payload'
);

export const setSearchText = makeActionCreator(SET_SEARCH_TEXT, 'payload');
export const setAssociationFormInfo = makeActionCreator(
  SET_ASSOCIATION_FORM_INFO,
  'payload'
);
export const setCurrentAssociationNumber = makeActionCreator(
  SET_CURRENT_ASSOCIATION_NUMBER,
  'payload'
);

export const getMapByCoordinates = makeActionCreator(
  GET_MAP_BY_COORDINATES,
  'payload'
);

export const closeAddressMap = makeActionCreator(CLOSE_ADDRESS_MAP, 'payload');
