import styled from 'styled-components';

import { Result as AntResult } from 'antd';
import {
  ERROR_COLOR,
  FONT_COLOR,
  FONT_SIZE,
  FONT_SIZE_SMALL,
  FONT_WEIGHT
} from 'theme/variables';

const Result = styled(AntResult)`
  & .ant-result-subtitle {
    color: ${FONT_COLOR};
  }

  &.only-text {
    padding: 0;

    .ant-result-icon {
      display: inline;
      margin: 0;
      text-align: left;

      .anticon {
        font-size: 20px;
      }
    }

    &.ant-result-error .ant-result-title,
    &.ant-result-error .ant-result-subtitle {
      color: ${ERROR_COLOR};
    }

    & .ant-result-subtitle {
      font-size: ${FONT_SIZE_SMALL};
      margin-left: 30px;
      color: ${FONT_COLOR};
    }

    .ant-result-title {
      font-weight: ${FONT_WEIGHT};
      font-size: ${FONT_SIZE};
      display: inline;
      margin-left: 10px;
    }

    .ant-result-title,
    .ant-result-extra,
    .ant-result-subtitle {
      text-align: left;
    }
  }
`;

export default Result;
