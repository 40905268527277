import React from 'react';
import styled from 'styled-components';
import { DatePicker as AntdDatePicker } from 'antd';
import { DATE_FORMAT } from 'theme/variables';
import IMask from 'imask';
import moment from 'moment';

const fromYear = moment().subtract(100, 'years').format('YYYY');
const younger = moment().subtract(18, 'years');

export function under18YearsOld(current) {
  const under18YearsOld = current && current > younger;
  const not80years = current && current < moment().subtract(100, 'years');
  return !!under18YearsOld || !!not80years;
}

const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31, maxLength: 2 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12, maxLength: 2 },
    YYYY: {
      from: fromYear,
      mask: IMask.MaskedRange,
      to: younger.format('YYYY'),
      maxLength: 2
    }
  },
  format: date => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: date => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT
});

const DatePicker = ({ ...props }) => {
  return (
    <AntdDatePicker
      format={DATE_FORMAT}
      onKeyDown={event => {
        const input = event.target;
        input.value = MASKED.resolve(input.value);
      }}
      picker="date"
      placeholder={DATE_FORMAT}
      disabledDate={under18YearsOld}
      {...props}
    />
  );
};

export default DatePicker;
