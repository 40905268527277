import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TwoColumns, { imageSetting } from 'components/common/Content/TwoColumns';
import Result from 'components/common/Result';

import Portal from 'components/common/Portal';
import { Space } from 'antd';
import { Button } from 'components/common/Buttons';
import { resetApp } from 'redux/app/actions';
import {
  selectApplyFormResultCallbackURL,
  selectApplyFormResultIsFailed,
  selectApplyFrom,
  selectResultColorBand,
  selectResultScriptText,
  selectResultScriptText2,
  selectResultTitle
} from 'redux/app/selectors';
import { selectApplyFormResultIsSuccess } from 'redux/app/selectors';

import failedBlackImg from 'images/result/failedBlackImg.png';
import failedRedImg from 'images/result/failedRedImg.png';
import successGreenImg from 'images/result/successGreenImg.png';
import successOrangeImg from 'images/result/successOrangeImg.png';

import { replace } from 'connected-react-router';
import { ROUTES } from 'consts/routes';
import {
  APPLY_FORM_RESPONSE_COLOR_BLACK,
  APPLY_FORM_RESPONSE_COLOR_GREEN,
  APPLY_FORM_RESPONSE_COLOR_ORANGE,
  APPLY_FORM_RESPONSE_COLOR_RED,
  APPLY_FROM_BD_CODE_NOMENCLATURE,
  APPLY_FROM_BE_CODE_NOMENCLATURE,
  APPLY_FROM_QR_CODE_NOMENCLATURE
} from 'redux/app/constants';
import { result_conf_to_show } from './variables';
import { Text, Title } from 'components/common/Typography';

function FormattedText({ title, message }) {
  return (
    <Space direction="vertical">
      {title && (
        <Text strong>
          <Title level={3}>{title}</Title>
        </Text>
      )}
      {message && (
        <Text>
          <Title level={4} style={{ fontWeight: 'normal' }}>
            {message}
          </Title>
        </Text>
      )}
    </Space>
  );
}

function ResultMessage() {
  const dispatch = useDispatch();
  const callbackUrl = useSelector(selectApplyFormResultCallbackURL);
  const applyFormResultIsSuccess = useSelector(selectApplyFormResultIsSuccess);
  const applyFormResultIsFailed = useSelector(selectApplyFormResultIsFailed);
  const resultColorBand = useSelector(selectResultColorBand);
  const resultScriptText = useSelector(selectResultScriptText);
  const resultScriptText2 = useSelector(selectResultScriptText2);
  const resultTitle = useSelector(selectResultTitle);
  const applyFrom = useSelector(selectApplyFrom);

  const showActions =
    (callbackUrl && applyFrom !== APPLY_FROM_QR_CODE_NOMENCLATURE) ||
    (callbackUrl && applyFrom !== APPLY_FROM_BE_CODE_NOMENCLATURE) ||
    (callbackUrl && applyFrom !== APPLY_FROM_BD_CODE_NOMENCLATURE);

  result_conf_to_show.title = resultTitle;
  result_conf_to_show.message = resultScriptText;
  result_conf_to_show.subtitle = resultScriptText2;

  if (applyFormResultIsSuccess) {
    result_conf_to_show.status = 'success';
  }

  if (applyFormResultIsFailed) {
    result_conf_to_show.status = 'error';
  }

  switch (resultColorBand) {
    case APPLY_FORM_RESPONSE_COLOR_GREEN:
      result_conf_to_show.img = successGreenImg;
      break;

    case APPLY_FORM_RESPONSE_COLOR_ORANGE:
      result_conf_to_show.img = successOrangeImg;
      break;

    case APPLY_FORM_RESPONSE_COLOR_RED:
      result_conf_to_show.img = failedRedImg;
      break;

    case APPLY_FORM_RESPONSE_COLOR_BLACK:
      result_conf_to_show.img = failedBlackImg;
      break;

    default:
      break;
  }

  const handleCloseOnClick = () => {
    dispatch(resetApp());
    dispatch(replace(ROUTES.HOME));
  };

  const handleContinueOnClick = () => {
    window.open(callbackUrl);
  };

  const renderStatusText = () => {
    return (
      <>
        {/* Success */}
        {/* <Title>
          Great news - you're pre-approved for an amount of $50 000.
        </Title> */}

        {/* Error */}
        {/* <Title>Credit report with error.</Title> */}

        {/* Orange */}
        {/* <Title>
          Prequalification pre-approved for $50 000 but may be review.
        </Title> */}

        {/* Failed */}
        {/* <Title>Prequalification not approved.</Title>

        <Title style={{ fontWeight: 'normal' }}>
          Please call (866) 254-0497 option 4 for more information. Reference ID
          1296115. Please do not resubmit.
        </Title>
        <Title style={{ fontWeight: 'normal', color: FONT_COLOR_DISABLED }}>
          {result_conf_to_show.subtitle}
        </Title> */}
      </>
    );
  };

  return (
    <TwoColumns
      md={20}
      imageSrc={imageSetting.source}
      imageDirection={imageSetting.position}
      actions={showActions}
    >
      <Result
        status={result_conf_to_show.status}
        title={
          <FormattedText
            title={result_conf_to_show.title}
            message={result_conf_to_show.message}
          />
        }
        subTitle={result_conf_to_show.subtitle}
        icon={
          result_conf_to_show.img ? (
            <img
              style={{ width: '200px' }}
              src={result_conf_to_show.img}
              alt="bla"
            />
          ) : null
        }
      />
      {showActions ? (
        <Portal id="form-actions">
          <Button
            htmlType="button"
            onClick={handleContinueOnClick}
            type="primary"
          >
            Continue to Full Application
          </Button>
        </Portal>
      ) : null}
    </TwoColumns>
  );
}

export default ResultMessage;
