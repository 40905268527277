import React from 'react';
import { replace } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import ButtonLink, { Button } from 'components/common/Buttons';

import { ROUTES } from 'consts/routes';

export default function ExitButton({
  type = 'primary',
  text = 'Exit',
  isLink = false,
  route = null,
  ...params
}) {
  const Component = isLink ? ButtonLink : Button;
  const dispatch = useDispatch();

  function handleOnClick() {
    dispatch(replace(route ? route : ROUTES.HOME));
  }

  return (
    <Component type={type} onClick={handleOnClick} {...params}>
      {text}
    </Component>
  );
}
