import { isEmpty, isNaN, isNumber } from 'lodash';
import moment from 'moment';
import {
  APPLICANT_NOMENCLATURE_PREFIX_MAP,
  APPLICANT_PREFIX,
  ASSOCIATION_SERVICE_NAME,
  BORROWER_NOMENCLATURE,
  CALL_REPORT_OPERATOR_TYPE,
  COBORROWER_NOMENCLATURE
} from 'redux/app/constants';
import { DATE_FORMAT } from 'theme/variables';

export function isBlank(value) {
  return (isEmpty(value) && !isNumber(value)) || isNaN(value);
}

export function formatCustomer(fieldsPrefix, values) {
  if (!Object.keys(values).some(prop => prop.includes(fieldsPrefix))) {
    return null;
  }

  const DOB = values[`${fieldsPrefix}DateOfBirth`]
    ? values[`${fieldsPrefix}DateOfBirth`].format(DATE_FORMAT)
    : null;

  const FirstName = values[`${fieldsPrefix}FirstName`] || null;
  const LastName = values[`${fieldsPrefix}LastName`] || null;

  return {
    FirstName,
    LastName,
    PhoneNumber: values[`${fieldsPrefix}PhoneNumber`],
    SSN: values[`${fieldsPrefix}SSN`],
    DOB
  };
}

export function formatApplicant(fieldsPrefix, values) {
  if (!Object.keys(values).some(prop => prop.includes(fieldsPrefix))) {
    return null;
  }

  const MiddleInitial = values[`${fieldsPrefix}MI`]
    ? values[`${fieldsPrefix}MI`]
    : '';

  const PhoneNumber = values[`${fieldsPrefix}PhoneNumber`];

  return {
    FirstName: values[`${fieldsPrefix}FirstName`],
    MiddleInitial,
    LastName: values[`${fieldsPrefix}LastName`],
    DateOfBirth: values[`${fieldsPrefix}DateOfBirth`].format(DATE_FORMAT),
    ...(PhoneNumber !== '___-___-____' && { PhoneNumber }),
    Email: values[`${fieldsPrefix}EmailAddress`],
    SSN: values[`${fieldsPrefix}SSN`],
    ApplicantType:
      fieldsPrefix === APPLICANT_PREFIX
        ? BORROWER_NOMENCLATURE
        : COBORROWER_NOMENCLATURE,
    ResidencyType: values[`${fieldsPrefix}Citizenship`],
    Address: {
      StreetAddress: values[`${fieldsPrefix}StreetAddress`],
      StreetAddress2: values[`${fieldsPrefix}UnitApt`],
      City: values[`${fieldsPrefix}City`],
      State: values[`${fieldsPrefix}State`],
      Zip: values[`${fieldsPrefix}ZipCode`]
    },
    Employer: {
      Income: values[`${fieldsPrefix}MonthlyIncome`]
    }
  };
}

export function formatSalesMan(fieldsPrefix, values) {
  var Salesman = {
    name: values[`${fieldsPrefix}Name`],
    email: values[`${fieldsPrefix}EmailAddress`],
    phone: values[`${fieldsPrefix}PhoneNumber`]
  };

  return !isEmpty(Salesman) ? { Salesman } : null;
}

export function formatApplicationDetails(fieldsPrefix, values) {
  return {
    RequestedAmount: values[`${fieldsPrefix}RequestedAmount`],
    TypeOfLoan: values[`${fieldsPrefix}TypeOfLoan`]
  };
}

export function formatAddressForValidation(fieldsPrefix, values) {
  if (!Object.keys(values).some(prop => prop.includes(fieldsPrefix))) {
    return null;
  }

  return {
    address1: values[`${fieldsPrefix}StreetAddress`],
    address2: isEmpty(values[`${fieldsPrefix}UnitApt`])
      ? ''
      : values[`${fieldsPrefix}UnitApt`],
    city: values[`${fieldsPrefix}City`],
    state: values[`${fieldsPrefix}State`],
    zip: values[`${fieldsPrefix}ZipCode`]
  };
}

/**
 * Formats address as HTML
 * @param {object} address
 * @returns
 */
export function formatAddressAsHTML(address) {
  if (!address) {
    return null;
  }

  const { address1, address2, city, state, zip } = address;

  const line1 = (address1, address2) => {
    return `<span style="white-space: nowrap; display:block">${
      address1 ? address1 : ''
    } ${address2 ? address2 : ''} </span>`;
  };

  const line2 = (city, state) => {
    return `<span style="white-space: nowrap; display:block">${
      city ? city : ''
    } ${state ? state : ''} </span>`;
  };

  return `${address1 || address2 ? line1(address1, address2) : ''}
      ${city || state ? line2(city, state) : ''}
      ${zip ? `<span>${zip}</span>` : ''}
    `;
}

export const formatPreviousCustomerIdentity = customerIdentity => {
  const { applicantType, identify: customerIdentify } = customerIdentity;
  const { firstName, lastName, dob, ssn, phoneNumber } = customerIdentify || {};

  const prefix = APPLICANT_NOMENCLATURE_PREFIX_MAP[applicantType];

  return {
    ...(firstName && { [`${prefix}FirstName`]: firstName }),
    ...(lastName && { [`${prefix}LastName`]: lastName }),
    ...(dob && {
      [`${prefix}DateOfBirth`]: dob ? moment(dob) : ''
    }),
    ...(ssn && { [`${prefix}SSN`]: `XXX-XX-${ssn}` }),
    ...(phoneNumber && { [`${prefix}PhoneNumber`]: phoneNumber })
  };
};

export const formatCustomerIdentity = customerIdentity => {
  const { applicantType, applicant: customerApplicant } = customerIdentity;

  const {
    firstName,
    middleInitial,
    lastName,
    dateOfBirth,
    ssn,
    residencyType: customerResidencyType,
    phoneNumber,
    employer: customerEmployer,
    email,
    address: customerAddress
  } = customerApplicant || {};
  const { Id: residencyType = '' } = customerResidencyType || {};
  const { income: monthlyIncome = 0 } = customerEmployer || {};

  const prefix = APPLICANT_NOMENCLATURE_PREFIX_MAP[applicantType];

  return {
    ...(firstName && { [`${prefix}FirstName`]: firstName }),
    ...(middleInitial && { [`${prefix}MI`]: middleInitial }),
    ...(lastName && { [`${prefix}LastName`]: lastName }),
    ...(dateOfBirth && {
      [`${prefix}DateOfBirth`]: dateOfBirth ? moment(dateOfBirth) : ''
    }),
    ...(email && { [`${prefix}EmailAddress`]: email }),
    ...(ssn && { [`${prefix}SSN`]: `XXX-XX-${ssn}` }),
    ...(residencyType && { [`${prefix}Citizenship`]: residencyType }),
    ...(phoneNumber && { [`${prefix}PhoneNumber`]: phoneNumber }),
    ...(monthlyIncome && { [`${prefix}MonthlyIncome`]: monthlyIncome }),
    ...formatCustomerIdentityAddress(applicantType, customerAddress || {})
  };
};

export const formatCustomerIdentityAddress = (
  applicantType,
  customerAddress
) => {
  const {
    address1: streetAddress,
    address2: unitApt,
    city,
    state,
    zip
  } = customerAddress || {};
  const prefix = APPLICANT_NOMENCLATURE_PREFIX_MAP[applicantType];

  return {
    ...(streetAddress && { [`${prefix}StreetAddress`]: streetAddress }),
    ...(unitApt && { [`${prefix}UnitApt`]: unitApt }),
    ...(city && { [`${prefix}City`]: city }),
    ...(state && { [`${prefix}State`]: state }),
    ...(zip && { [`${prefix}ZipCode`]: zip })
  };
};

/**
 * Encodes query data
 * @param {object} data
 * @returns {string}
 */
export function encodeQueryData(data) {
  const result = [];
  for (let d in data) {
    if (data[d] !== null && encodeURIComponent(data[d])) {
      result.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
  }

  return result.join('&');
}

//build association call report payload
export function buildAssociationCallReportPayload(
  zipCode,
  distance,
  associationNumber
) {
  let payload = {};
  if (zipCode && distance && associationNumber) {
    payload = {
      serviceName: ASSOCIATION_SERVICE_NAME,
      params: {
        zipCode,
        associationNumber
      },
      search: {
        filterBy: [
          {
            name: 'Distance',
            operator: CALL_REPORT_OPERATOR_TYPE.GREATER_THAN_OR_EQUAL_TO,
            value: distance * -1,
            group: 0
          },
          {
            name: 'Distance',
            operator: CALL_REPORT_OPERATOR_TYPE.LESS_THAN_OR_EQUAL_TO,
            value: distance,
            group: 1
          }
          // {
          //   name: 'Distance',
          //   operator: CALL_REPORT_OPERATOR_TYPE.LESS_THAN_OR_EQUAL_TO,
          //   value: distance,
          //   group: 0
          // }
        ]
      }
    };
  }
  return payload;
}
