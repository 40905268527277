import React from 'react';
import styled from 'styled-components';

import { List as ListAnt } from 'antd';

import {
  ACTION_COLOR,
  BORDER_ACTION,
  FONT_SIZE_SMALL,
  GRAY_BACKGROUND
} from 'theme/variables';
import { InfoCircleOutlined } from '@ant-design/icons';

const NotesContainer = styled.div`
  background: #fffbe6;
  border: 1px solid #ffe58f;
  margin-bottom: ${props => (props.reduced ? '0' : '10px')};
  padding: 5px 10px;
  display: flex;
  align-items: flex-start;

  .anticon {
    display: inline;
    margin: 3px 10px 0 0;
    color: #faad14;
  }
`;
const CustomListItem = ListAnt.Item;

export const NotesList = styled(ListAnt)`
  &.ant-list-sm .ant-list-item {
    padding: 0;
  }

  &.ant-list-split .ant-list-item {
    border-bottom: none;
  }
`;

export function Notes({ source, reduced = false }) {
  return (
    <NotesContainer reduced={reduced}>
      <InfoCircleOutlined />
      <NotesList
        size="small"
        dataSource={source}
        renderItem={item => (
          <NotesList.Item style={{ fontSize: FONT_SIZE_SMALL }}>
            {item}
          </NotesList.Item>
        )}
      />
    </NotesContainer>
  );
}

const List = styled(ListAnt)`
  .ant-list-pagination {
    margin-top: 0;
  }
`;

export default List;
