import { getCustomerIdentity, setErrorMessage } from 'redux/app/actions';
import {
  APPLICANT_TYPES,
  BORROWER_NOMENCLATURE,
  ENTER_APPLICANT_DATA_MANUALLY,
  GET_CUSTOMER_IDENTITY_DONE,
  GET_CUSTOMER_IDENTITY_FAILED,
  GET_CUSTOMER_IDENTITY_STOP,
  GET_CUSTOMER_IDENTITY_SUCCESS,
  INIT_RETRIEVE_APPLICATION_DATA,
  INIT_RETRIEVE_APPLICATION_DATA_FAILED,
  INIT_RETRIEVE_APPLICATION_DATA_SUCCESS,
  RESET_APP,
  TIMES
} from 'redux/app/constants';
import {
  INFO_NOTIFICATION_TYPE,
  logMessageInConsole
} from 'utils/notifications';

let customerIdentityIntervalId;

/**
 * Init get Customer Identity
 * @param {object} store
 */
const initGetCustomerIdentity = (store, applicantType) => {
  stopGetCustomerIdentityInterval();
  startGetCustomerIdentityInterval(store, applicantType);
};

/**
 * Clears get Customer Identity interval
 */
const stopGetCustomerIdentityInterval = () => {
  if (customerIdentityIntervalId) {
    clearInterval(customerIdentityIntervalId);
    customerIdentityIntervalId = null;
    logMessageInConsole(
      'Stopping customer identity interval',
      INFO_NOTIFICATION_TYPE
    );
  }
};

/**
 * Start Customer Identity Interval
 * @param {object} store
 */
const startGetCustomerIdentityInterval = (store, applicantType) => {
  logMessageInConsole(
    'Activating customer identity interval',
    INFO_NOTIFICATION_TYPE
  );

  if (!APPLICANT_TYPES.includes(applicantType?.toString())) {
    store.dispatch(
      setErrorMessage(
        'Imposible to continue the application due an error. Unknown applicant type.'
      )
    );
    return;
  }

  customerIdentityIntervalId = setInterval(() => {
    store.dispatch(getCustomerIdentity({ applicantType }));
  }, TIMES.QUARTER_MINUTE);
};

const middleware = store => next => action => {
  const { type } = action;

  switch (type) {
    case INIT_RETRIEVE_APPLICATION_DATA:
    case GET_CUSTOMER_IDENTITY_SUCCESS:
      const { applicantType } = action.payload;
      initGetCustomerIdentity(store, applicantType);
      break;
    case INIT_RETRIEVE_APPLICATION_DATA_SUCCESS:
    case INIT_RETRIEVE_APPLICATION_DATA_FAILED:
    case GET_CUSTOMER_IDENTITY_DONE:
    case GET_CUSTOMER_IDENTITY_FAILED:
    case GET_CUSTOMER_IDENTITY_STOP:
    case ENTER_APPLICANT_DATA_MANUALLY:
    case RESET_APP:
      stopGetCustomerIdentityInterval();
      break;

    default:
      break;
  }

  return next(action);
};

export default middleware;
