import React, { Component } from 'react';
import { Radio as RadioAnt } from 'antd';
import styled from 'styled-components';

const RadioGroupAnt = RadioAnt.Group;

export default class RadioGroup extends Component {
  handleOnChange = e => {
    this.props.onChange(this.props.property, e.target.name, e.target.value);
  };

  render() {
    return <RadioGroupAnt {...this.props} onChange={this.handleOnChange} />;
  }
}

export const Radio = styled(RadioAnt)`
  &.address-selection--radio {
    &.ant-radio-wrapper {
      padding: 20px;
      width: 100%;
    }
    &.ant-radio-wrapper-checked {
      background: #fafafa;
    }
  }
`;
