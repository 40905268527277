import React, { memo } from 'react';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { without } from 'lodash';

import TwoColumns, { imageSetting } from 'components/common/Content/TwoColumns';
import Portal from 'components/common/Portal';
import ExitButton from 'components/views/utils';
import LegalLink from './Components/LegalLink';
import Disclosures from './Components/Disclosure';

import { legalLinks, legalLinksName } from 'redux/app/constants';
import { ROUTES } from 'consts/routes';

import statement from 'assets/Statement.html';
import coppaDisclosure from 'assets/CoppaDisclosure.html';
import privacyPolicy from 'assets/PrivacyPolicy.html';
import { Dropdown, Menu, Space, Typography } from 'antd';
import { ACTION_COLOR, BORDER_DEFAULT, FONT_SIZE_SMALL } from 'theme/variables';
import { useIsMobile } from 'hooks/responsive';
import { DownOutlined } from '@ant-design/icons';
import { Button } from 'components/common/Buttons';
const { Link } = Typography;

const htmlFile = {
  [ROUTES.STATEMENT]: statement,
  [ROUTES.COPPA_DISCLOSURE]: coppaDisclosure,
  [ROUTES.PRIVACY_POLICY]: privacyPolicy
};

function RenderAnotherLinks({ locationPath, ...params }) {
  // const linksToShow = without(legalLinks, locationPath);
  const isMobile = useIsMobile();

  // const items = legalLinks.map((link, index) => ({ // version > 4.24
  //   ...(link !== locationPath && {
  //     key: index,
  //     label: <Link href={link}>Ant Design (Link)</Link>
  //   })
  // }));

  const renderItems = () => {
    const total = legalLinks.length;
    return (
      <Menu>
        {legalLinks.map(
          (link, index) =>
            link !== locationPath && (
              <Menu.Item
                style={
                  index < total - 1 ? { borderBottom: BORDER_DEFAULT } : null
                }
              >
                <Link href={link} style={{ color: ACTION_COLOR }}>
                  {legalLinksName[link]}
                </Link>
              </Menu.Item>
            )
        )}
      </Menu>
    );
  };

  if (isMobile) {
    return (
      <Dropdown overlay={renderItems()} placement="top" arrow>
        <Button type="secondary">
          <Space>
            More information
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  }

  return (
    <Space split="|" wrap={true} size={4} style={{ marginLeft: 10 }}>
      {legalLinks.map((link, index) => (
        <LegalLink
          disabled={link === locationPath}
          key={index}
          route={link}
          style={{ fontSize: FONT_SIZE_SMALL, height: 10, lineHeight: 1 }}
        />
      ))}
    </Space>
  );
}

function LegalContent({ route }) {
  switch (route) {
    case ROUTES.DISCLOSURES:
      return <Disclosures />;

    default:
      return parse(htmlFile[route]);
  }
}
function LegalInformation() {
  let location = useLocation();

  return (
    <>
      <TwoColumns
        md={20}
        imageSrc={imageSetting.source}
        imageDirection={imageSetting.position}
        actions={true}
      >
        <LegalContent route={location.pathname} />

        <Portal id="form-actions">
          <div
            style={{
              display: 'flex',
              justifyItems: 'space-between',
              alignItems: 'center'
            }}
          >
            <ExitButton
              type="primary"
              text="Go Back"
              route={ROUTES.APPLY_FORM}
              style={{ marginRight: '5px' }}
            />
            <RenderAnotherLinks locationPath={location.pathname} />
          </div>
        </Portal>
      </TwoColumns>
    </>
  );
}
export default memo(LegalInformation);
