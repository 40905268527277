import React, { useEffect, useRef, useState } from 'react';
import { useElementDistanceFromTop } from 'utils/hooks';
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';

import { Divider, Space, Typography } from 'antd';

import Table from 'components/common/Table';
import NoData from './NoData';
import ShowInMap from './ShowInMap';

import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGINATION_CONFIG
} from 'redux/app/constants';
import { FONT_SIZE_SMALL } from 'theme/variables';

import { ApplyNowButton, ContactInfo, DataSourceTotal, Search } from '.';
import { getDistanceText } from '../../utils';

const { Text } = Typography;

function DealersTable({ dataSource, dataSourceIsFetching }) {
  const [paginationInfo, setPaginationInfo] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    current: DEFAULT_PAGE
  });
  const [newElementRef, setNewElementRef] = useState(null);
  const elementRef = useRef();

  useEffect(() => {
    setNewElementRef(elementRef);
  }, [elementRef]);

  const height = useElementDistanceFromTop(newElementRef);

  const handlePageSizeChange = (current, pageSize) => {
    setPaginationInfo({
      ...paginationInfo,
      pageSize,
      current: DEFAULT_PAGE
    });
  };

  const renderAddress = (_, record) => {
    const { contact = {}, distance = null } = record;
    const { address = {} } = contact;

    const distanceText = getDistanceText(distance);
    const addressText = address?.fullAddress || 'Unknown address';

    return (
      <div>
        <Space>
          <Text strong style={{ fontSize: FONT_SIZE_SMALL, display: 'block' }}>
            {address?.city || 'Unknown city'}
            {distanceText[true] && ` - ${distanceText[true]}`}
          </Text>
          <ShowInMap
            address={addressText}
            gpsCoordinates={address?.gpsCoordinates}
            isValid={address?.isValid}
          />
        </Space>
        <Text style={{ fontSize: FONT_SIZE_SMALL, display: 'block' }}>
          {address?.address || 'Unknown address'}
        </Text>
        <Text style={{ fontSize: FONT_SIZE_SMALL }}>
          {address?.state || 'Unknown state'}{' '}
          {address?.zip || 'Unknown zip code'}
        </Text>
      </div>
    );
  };

  const allColumns = [
    {
      key: 'company',
      title: 'Company name',
      render: (_, record) => (
        <Text style={{ fontSize: FONT_SIZE_SMALL }}>{record.companyName}</Text>
      )
    },
    {
      key: 'distance-address',
      title: 'Address / Distance',
      width: '40%',
      render: renderAddress
    },
    {
      key: 'contact-info',
      title: 'Contact information ',
      render: (_, record) => <ContactInfo contact={record.contact} />
    },
    {
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <ApplyNowButton dealerNumber={record.dealerNumber} />
      ),
      width: '100',
      className: 'align-middle'
    }
  ];

  const handlePageChange = (page, pageSize) => {
    if (page !== paginationInfo.current) {
      setPaginationInfo({ ...paginationInfo, current: page });
    }
  };

  const pagination = {
    ...PAGINATION_CONFIG,
    pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
    pageSize: paginationInfo.pageSize,
    total: dataSource?.length,
    current: paginationInfo.currentPage,
    showTotal: total => {
      return (
        <>
          <DataSourceTotal total={total} />
          <Divider type="vertical" style={{ margin: '0px 0px 0px 15px' }} />
        </>
      );
    },
    onShowSizeChange: handlePageSizeChange,
    onChange: handlePageChange,
    defaultCurrent: DEFAULT_PAGE,
    defaultPageSize: DEFAULT_PAGE_SIZE,
    size: 'small',
    style: { fontSize: FONT_SIZE_SMALL, margin: '20px 0 10px' }
  };

  return (
    <>
      <Search
        style={{
          width: 370,
          position: 'absolute',
          top: '12px',
          zIndex: 999
        }}
      />
      {!isEmpty(dataSource) ? (
        <Table
          ref={elementRef}
          size="small"
          columns={allColumns}
          rowKey={() => uuidv4()}
          dataSource={dataSource}
          bordered={false}
          pagination={pagination}
          tableLayout="fixed"
          className="align-top"
          loading={dataSourceIsFetching}
          scroll={{
            y: height
          }}
        />
      ) : (
        <NoData />
      )}
    </>
  );
}

export default DealersTable;
