import React, { memo } from 'react';

import TwoColumns, { imageSetting } from 'components/common/Content/TwoColumns';

import DealerNumberForm from './DealerNumberForm';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';

import DealerDataToVerify from './DealerDataToVerify';
import { selectDealerNumber } from 'redux/tokens/selectors';
import HomeDefaultMessage from './DefaultMessage';

function DealerEntry() {
  const dealerNumber = useSelector(selectDealerNumber);

  if (dealerNumber) {
    return <DealerDataToVerify />;
  }

  return <DealerNumberForm />;
}

function Home() {
  return (
    <TwoColumns
      md={20}
      imageSrc={imageSetting.source}
      imageDirection={imageSetting.position}
      headerTitle=""
    >
      <DealerEntry />
      <Divider />
      <HomeDefaultMessage />
    </TwoColumns>
  );
}

export default memo(Home);
