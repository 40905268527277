import { Title } from 'components/common/Typography';
import React from 'react';
import styled from 'styled-components';
import { FONT_COLOR, FONT_SIZE, FONT_WEIGHT } from 'theme/variables';

const CustomFieldset = styled.fieldset`
  border-color: rgba(0, 0, 0, 0.06);
  border-width: 1px;
  border-style: groove;
  padding: 10px;
  margin-bottom: 10px;

  legend {
    border-bottom: none;
    padding: 0 5px;
    width: auto;
    font-weight: ${FONT_WEIGHT};
    font-size: ${FONT_SIZE};
    color: ${FONT_COLOR};
    margin: 0;
    white-space: nowrap;
  }

  & + & {
    margin-top: 10px;
  }
`;

const Fieldset = ({ title, children, ...props }) => {
  return (
    <CustomFieldset {...props}>
      <legend>
        <Title style={{ marginBottom: 0 }}>{title}</Title>
      </legend>
      {children}
    </CustomFieldset>
  );
};

export default Fieldset;
