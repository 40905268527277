import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useCustomerIdentityAddressSelection } from 'hooks/data';

import { Modal } from 'antd';
import { Button } from 'components/common/Buttons';
import { Text, Title } from 'components/common/Typography';
import { AddressCard } from 'components/common/Card';

import {
  closeAddressListToSelect,
  saveCustomerIdentityAddress
} from 'redux/app/actions';

import { selectShowMultipleAddressSelection } from 'redux/app/selectors';
import { Radio } from 'components/common/Radio';
import { Message } from 'components/common/Text';

function MultipleAddressSelection({ applicantType }) {
  const dispatch = useDispatch();
  const [addressValue, setAddressValue] = useState(-1);
  const multipleAddress = useCustomerIdentityAddressSelection(applicantType);

  const handleClose = () => {
    dispatch(closeAddressListToSelect());
  };

  const handleOnChange = e => {
    setAddressValue(e.target.value);
  };

  const handleSelect = () => {
    const customerAddress = multipleAddress.filter(
      address => address.id === addressValue
    )[0];
    dispatch(saveCustomerIdentityAddress({ applicantType, customerAddress }));
  };

  return (
    <Modal
      open
      title={<Title style={{ marginBottom: '0px' }}>Address selection</Title>}
      closable={false}
      maskClosable={false}
      centered
      onCancel={handleClose}
      bodyStyle={{ padding: 0 }}
      footer={[
        <Button
          key="select"
          type="primary"
          disabled={addressValue < 0}
          onClick={handleSelect}
        >
          Select
        </Button>
        // <Button key="cancel" type="secondary" onClick={handleClose}>
        //   Cancel
        // </Button>
      ]}
    >
      {!isEmpty(multipleAddress) ? (
        <>
          <Message closable={false} style={{ marginBottom: '10px' }}>
            {multipleAddress.length} addresses have been found. Please select an
            address to continue.
          </Message>
          <Radio.Group
            onChange={handleOnChange}
            value={addressValue}
            style={{ width: '100%', padding: ' 0 20px' }}
          >
            {multipleAddress.map((address, index) =>
              address.id >= 0 ? (
                <AddressCard
                  key={`address-${index}`}
                  bordered={false}
                  title={`Address #${index + 1}`}
                >
                  <Radio
                    value={address.id}
                    disabled={!address.fullAddress}
                    className="address-selection--radio"
                  >
                    {address.fullAddress ? (
                      address.fullAddress
                    ) : (
                      <Text type="danger">Invalid address</Text>
                    )}
                  </Radio>
                </AddressCard>
              ) : null
            )}
          </Radio.Group>
        </>
      ) : null}
    </Modal>
  );
}

function MultipleAddressSelectionModal() {
  const applicantType = useSelector(selectShowMultipleAddressSelection);

  return applicantType ? (
    <MultipleAddressSelection applicantType={applicantType} />
  ) : null;
}

export default memo(MultipleAddressSelectionModal);
