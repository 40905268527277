import React, { memo } from 'react';

import { Result, Spin } from 'antd';
import TwoColumns from 'components/common/Content/TwoColumns';

function RetrievingData() {
  return (
    <TwoColumns md={18} showSecondColumn={false}>
      <Result
        title="We are checking your verification and retrieving your data."
        subTitle="Please wait a few seconds."
        icon={<Spin size="large" spinning={true} />}
        status="info"
      />
    </TwoColumns>
  );
}

export default memo(RetrievingData);
