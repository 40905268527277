import { call } from '@redux-saga/core/effects';
import { fork, take } from 'redux-saga/effects';
import {
  showErrorNotificationFromResponse,
  showSuccessNotification
} from 'utils/notifications';
import { HTTP_STATUS } from 'api/axios/interceptors';
import { isCancelledRequestException } from 'api/axios';

export const DEFAULT_WRONG_MESSAGE =
  'Something happend and the operation could not be performed, please try again or contact us.';

/**
 * Saga to perform an API request
 *
 * @param {function} requestFunction
 * @param {Array} requestData
 * @param {object} config
 * @param {String} config.actionTitle
 * @param {boolean} config.showErrorMessage
 * @param {boolean} config.showSuccessMessage
 * @param {Number} config.retryOnErrorCount
 *
 * @returns {object}
 */
export function* apiRequest(requestFunction, requestData, config = {}) {
  if (typeof requestFunction !== 'function') {
    throw new Error('requestFunction must be a function');
  }

  if (!Array.isArray(requestData)) {
    throw new Error('requestData must be an Array');
  }

  if (typeof config !== 'object') {
    throw new Error('config must be an object');
  }

  const options = Object.assign(
    {
      showSuccessMessage: false,
      showErrorMessage: false,
      retryOnErrorCount: 5
    },
    config
  );

  let {
    actionTitle,
    showSuccessMessage,
    showErrorMessage,
    retryOnErrorCount,
    successMessageDescription,
    errorMessageDescription
  } = options;

  if (retryOnErrorCount < 0) {
    retryOnErrorCount = 0;
  }

  let latestError = null;
  let response = {};
  do {
    try {
      response = yield call(requestFunction, ...requestData);

      if (response && response.isSuccess && showSuccessMessage) {
        showSuccessNotification({ actionTitle, successMessageDescription });
      }
      return response;
    } catch (error) {
      latestError = error;
      if (error.response) {
        switch (error.response.status) {
          case HTTP_STATUS.BAD_GATEWAY:
            retryOnErrorCount--;
            break;
          case HTTP_STATUS.NOT_FOUND:
            showErrorMessage = true;
            break;
          default:
            retryOnErrorCount = 0;
            break;
        }
      } else {
        retryOnErrorCount = 0;
        if (error.hasOwnProperty('notFound')) {
          showErrorMessage = !error.notFound;
        }
      }
    }
  } while (retryOnErrorCount > 0);

  if (
    latestError &&
    showErrorMessage &&
    !isCancelledRequestException(latestError)
  ) {
    let title = actionTitle || latestError.title || 'An error occurred';
    let message =
      (latestError.showToUser && latestError.message) ||
      errorMessageDescription ||
      `The operation ${actionTitle || ''} could not be performed.`;

    if (actionTitle && latestError.title) {
      title = `${actionTitle} (${latestError.title})`;
    }

    latestError.title = title;
    latestError.message = message;
    showErrorNotificationFromResponse(latestError);
  }

  return latestError;
}

export const customTakeLatest = (pattern, saga, ...args) =>
  fork(function* () {
    const patterns = [];
    let timer;
    while (true) {
      const action = yield take(pattern);
      if (!patterns.includes(action.type)) {
        patterns.push(action.type);
        yield fork(saga, ...args.concat(action));

        clearTimeout(timer);
        timer = setTimeout(() => {
          patterns.length = 0;
        }, 1000);
      }
    }
  });
