import React from 'react';

import { Checkbox } from 'components/common/Inputs';
import parse from 'html-react-parser';
import { SubTitle } from 'components/common/Typography';

const { Form } = require('antd');

function InvalidItem({ fieldName, address }) {
  return (
    <>
      <SubTitle>
        The address provided could not be verified by the USPS. <br />
        <p style={{ marginTop: '10px' }}>{parse(address)}</p>
      </SubTitle>
      <Form.Item
        name={fieldName}
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: 'This field is required.',
            transform: value => value || undefined,
            type: 'boolean'
          }
        ]}
        initialValue={false}
      >
        <Checkbox>
          I confirm that I want to provide this address as is.
        </Checkbox>
      </Form.Item>
    </>
  );
}

export default InvalidItem;
