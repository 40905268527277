import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { FormItem } from 'components/common/Form';
import { SpacedMaskedInput } from 'components/common/Inputs';

function SSN({
  form,
  required_rules,
  required = true,
  fieldsPrefix,
  extra = null,
  ...props
}) {
  const inputRef = useRef(null);
  const ssnProperties = {
    pattern: '^[A-Z]{3}-?[A-Z]{2}-?[0-9]{4}$',
    // pattern: '^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$',
    // mask: '000-00-0000'
    mask: 'XXX-XX-0000'
  };
  const [isProtected, setIsProtected] = useState(true);
  const [hasError, setHasError] = useState(false);

  const ssn_rules = [
    {
      message: 'The SSN entered is not correct',
      pattern: ssnProperties.pattern
    }
  ];

  if (required) {
    ssn_rules.push(required_rules);
  }

  useEffect(() => {
    if (!isProtected) {
      inputRef.current.input.focus({
        cursor: 'end'
      });
    }
  }, [isProtected]);

  const handleOnBlur = e => {
    setIsProtected(true);
  };

  const handleOnFocus = e => {
    setIsProtected(false);
  };

  const handleShouldUpdate = useCallback(
    (prevValues, currentValues) => {
      setHasError(form.getFieldError(`${fieldsPrefix}SSN`).length > 0);

      return (
        prevValues[`${fieldsPrefix}SSN`] !== currentValues[`${fieldsPrefix}SSN`]
      );
    },
    [fieldsPrefix, form]
  );

  const protectedClasses = classNames(
    'ssn-input',
    isProtected ? 'protected' : '',
    hasError ? 'with-errors' : ''
  );

  const unprotectedClasses = classNames(
    'ssn-input',
    !isProtected ? 'unprotected' : '',
    hasError ? 'with-errors' : ''
  );

  return (
    <FormItem
      label="SSN"
      required
      shouldUpdate={handleShouldUpdate}
      extra={extra}
      className="force-block"
      style={{ marginBottom: '0px' }}
    >
      {() => {
        return (
          <>
            <FormItem
              validateTrigger={'onBlur'}
              name={`${fieldsPrefix}SSN`}
              required={required}
              rules={ssn_rules}
            >
              <SpacedMaskedInput
                key={`${fieldsPrefix}unprotectedSSN`}
                autoComplete="off"
                placeholder="SSN"
                mask={ssnProperties.mask}
                className={unprotectedClasses}
                ref={inputRef}
                hidden={isProtected}
                onBlur={handleOnBlur}
              />
            </FormItem>
            <SpacedMaskedInput
              key={`${fieldsPrefix}protectedSSN`}
              mask="XXX-XX-••••"
              onFocus={handleOnFocus}
              className={protectedClasses}
            />
          </>
        );
      }}
    </FormItem>
  );
}

export default memo(SSN);
