export const XXS = 'xxs';
export const XS = 'xs';
export const SM = 'sm';
export const MD = 'md';
export const LG = 'lg';
export const XL = 'xl';
export const XXL = 'xxl';

export const BREAKPOINT_XXS = 370;
export const BREAKPOINT_XS = 480;
export const BREAKPOINT_SM = 576;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 992;
export const BREAKPOINT_XL = 1200;
export const BREAKPOINT_XXL = 1600;

export const RESPONSIVE_BREAKPOINTS = {
  [XXS]: BREAKPOINT_XXS,
  [XS]: BREAKPOINT_XS,
  [SM]: BREAKPOINT_SM,
  [MD]: BREAKPOINT_MD,
  [LG]: BREAKPOINT_LG,
  [XL]: BREAKPOINT_XL,
  [XXL]: BREAKPOINT_XXL
};

export const WIDTH_BREAKPOINT_XXS = `${BREAKPOINT_XXS}px`;
export const WIDTH_BREAKPOINT_XS = `${BREAKPOINT_XS}px`;
export const WIDTH_BREAKPOINT_SM = `${BREAKPOINT_SM}px`;
export const WIDTH_BREAKPOINT_MD = `${BREAKPOINT_MD}px`;
export const WIDTH_BREAKPOINT_LG = `${BREAKPOINT_LG}px`;
export const WIDTH_BREAKPOINT_XL = `${BREAKPOINT_XL}px`;
export const WIDTH_BREAKPOINT_XXL = `${BREAKPOINT_XXL}px`;

export const RESPONSIVE_BREAKPOINT_WIDTHS = {
  [XXS]: WIDTH_BREAKPOINT_XXS,
  [XS]: WIDTH_BREAKPOINT_XS,
  [SM]: WIDTH_BREAKPOINT_SM,
  [MD]: WIDTH_BREAKPOINT_MD,
  [LG]: WIDTH_BREAKPOINT_LG,
  [XL]: WIDTH_BREAKPOINT_XL,
  [XXL]: WIDTH_BREAKPOINT_XXL
};

export const MAIN_COLOR = '#007F45';
export const BACKGROUND_COLOR_LIGHT = '#008C59';
export const BACKGROUND_COLOR_DARK = MAIN_COLOR;
export const ACTION_COLOR = 'rgba(218,61,29,1)';
export const ACTION_COLOR_HOVER = 'rgba(195,76,23,1)';

export const GRAY_BACKGROUND = '#fafafa';
export const BACKGROUND_DEFAULT = '#FFFFFF';
export const BACKGROUND_AREA = 'rgba(250,250,250,1)';
export const BACKGROUND_INFO = '#fffbe6';
export const COLOR_ICON_INFO = '#faad14';
export const TRANSITION_DEFAULT =
  'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)';

// FONTS
export const FONT_FAMILY = `Roboto,'Open sans',sans-serif`;
export const FONT_BASE = '16px';
export const FONT_BIG_SIZE = '1rem';
export const FONT_SIZE = '16px';
export const FONT_SIZE_BUTTON = '0.9em';
export const FONT_SIZE_SMALL = '0.9em';
export const FONT_SIZE_SMALLEST = '0.7em';
export const LINE_HEIGHT = '1.5';

export const FONT_CLASSIC = 'Yellowtail';
export const FONT_ELEGANT = 'Great Vibes';
export const FONT_FORMAL = 'Dancing Script';

// ----FONT COLORS
export const PEN_COLOR = 'blue';
export const FONT_COLOR_GRAY = '#878787';
export const FONT_COLOR = '#1C1E20';
export const FONT_COLOR_LIGHT = '#485963';
export const LABEL_FONT_COLOR = '#737373';
export const FONT_IN_DARK = 'rgba(255, 255, 255, 0.8)';
export const FONT_IN_BG = 'rgba(255, 255, 255, 0.95)';

export const LABEL_COLOR = 'rgba(255, 255, 255, 0.8)';

export const LOGO_COLOR_GRAY = 'rgb(31,25,26)';
export const FONT_HEADER_COLOR = 'rgba(77,77,77,.8)';
export const FONT_HEADER_LIGHT_COLOR = 'rgba(255,255,255,.8)';
export const FONT_TITLE_COLOR = 'rgba(0, 0, 0, .5)';
export const LABEL_DARK_THEME = '#9EB5B3';
export const VALUE_DARK_THEME = '#D3DEDD';

export const COMPANY_NAME_GREEN = '#007F45';
export const COMPANY_NAME_BLACK = '#231f20';

export const MOBILE_BREAKPOINT_TO_SIZE = '900';
export const SIGNER_AREA_HEIGHT = 'calc(100vh - 335px)';
export const SIGNER_AREA_HEIGHT_MOBILE = 'calc(100vh - 210px)';
export const WIDTH_CENTER_CONTENT = '75%';

// LAYER

// ADA COMPLIANCE COLOR FOCUS;
export const BORDER_BLACK = 'rgba(0, 0, 0, 1)';

export const LAYER_1 = '1000';
export const LAYER_2 = '100';
export const LAYER_3 = '10';
export const LAYER_4 = '600';

//DISABLE STYLE
export const DISABLE_COLOR = '#F5F5F5';
export const DISABLE_BORDER = '1px solid #D9DEE4';

// Backgrounds

export const BACKGROUND_LIGHT = 'rgba(243, 243, 243, 1);';
export const BACKGROUND_DARK = '#231f20';
export const BACKGROUND_SOLID = 'rgba(248,248,248,1)';
// export const BACKGROUND_DARK = 'rgb(50, 54, 57)';
export const HEADER_LIGHT = '#f9f9f9';
export const HEADER_DARK = BACKGROUND_DARK;
export const READ_BACKGROUND = '#f9f9f9';
export const BACKGROUND_PDF = '#F4F4F4';

export const bg_grad_document = `repeating-linear-gradient( 130deg, rgba(166,157,160,0.1), rgba(166,157,160,0.1) 0, rgba(166,157,160,0.1) 4px, rgba(255,255,255,0.1) 0, rgba(255,255,255,0.1) 8px ), linear-gradient( 273deg, ${BACKGROUND_DARK} 13%, ${BACKGROUND_DARK} 100% )`;

// BORDERS
export const BORDER_COLOR = '#D9DEE4';
export const BORDER_DEFAULT = `1px solid ${BORDER_COLOR}`;
export const BORDER_LIGHT = `1px solid #ededed`;
export const BORDER_RADIUS = `4px`;
export const BORDER_ACTION = `1.5px solid rgba(255,94,0,.5)`;

export const AREA_BORDERED = `1px dashed ${MAIN_COLOR}`;
export const BORDER_STRONG = '2px solid rgba(5, 29, 18, 0.87)';

export const HOVER_COLOR = 'rgba(0,127,69,.1)';
export const PLACEHOLDER_COLOR = 'rgba(77, 77,77, 0.6)';
export const FONT_COLOR_SOFT = 'rgba(77,77,77,.8)';
export const FONT_COLOR_DISABLED = 'rgba(77,77,77,.6)';
export const FONT_SIZE_TABLE = '12px';
export const FONT_WEIGHT = '600';
export const FONT_SIZE_BIG_TEXT = '18px';

//PADDINGS
export const PADDING_DESKTOP = '40px';
export const PADDING_MOBILE = '20px';

// SHADOWS
export const BOX_SHADOW =
  '0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05)';

// ICONS
export const ICON_SIZE = '26px';

// BUTTONS
export const BUTTON_DISABLED_COLOR = 'rgba(123,123,123,.5)';

// SPACE
export const SIZE_BETWEEN_ELEMENTS = 8;
export const GUTTER_DEFAULT = [
  { xs: 0, sm: 16 },
  { sm: 1, xs: 0 }
];

// DATE
export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';
export const DATE_FORMAT = 'MM/DD/YYYY';

// STATE COLORS
export const SUCCESS_COLOR = '#1A9768';
export const WARNING_COLOR = '#FFC15E';
// export const ERROR_COLOR = '#B3001B';
export const ERROR_COLOR = 'red';
export const INFO_COLOR = '#231F20';
