import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExceptionPage from 'components/common/Pages/ExceptionPage';
import Close from '../Form/Components/Close';
import { selectErrorMessage } from 'redux/app/selectors';
import { replace } from 'connected-react-router';
import { ROUTES } from 'consts/routes';

const ErrorPage = () => {
  const dispatch = useDispatch();
  const message = useSelector(selectErrorMessage);

  useEffect(() => {
    if (!message) {
      dispatch(replace(ROUTES.HOME));
    }
  }, [message]);

  return (
    <ExceptionPage
      titlePage="We're sorry, something happend."
      message={message}
    />
  );
};

export default ErrorPage;
