import styled from 'styled-components';

import { Card as AntCard } from 'antd';

import { BORDER_DEFAULT, FONT_SIZE } from 'theme/variables';

const Card = styled(AntCard)``;

export const AddressCard = styled(AntCard)`
  &.ant-card {
    border-bottom: ${BORDER_DEFAULT};
  }
  .ant-card-head {
    min-height: 12px;
    padding: 0;
    font-size: ${FONT_SIZE};
    border-bottom: ${BORDER_DEFAULT};
  }
  .ant-card-head-title {
    padding: 5px 0;
  }
  .ant-card-body {
    padding: 5px;
  }
`;

export default Card;
