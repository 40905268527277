import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectMessage } from 'redux/app/selectors';
import { replace } from 'connected-react-router';
import { ROUTES } from 'consts/routes';
import TwoColumns from 'components/common/Content/TwoColumns';
import { Result } from 'antd';

import verification_success_img from 'images/verification_success.svg';
import verification_failed_img from 'images/verification_failed.svg';

import {
  MESSAGE_ERROR_STATUS_NOMENCLATURE,
  MESSAGE_SUCCESS_STATUS_NOMENCLATURE
} from 'redux/app/constants';

const MessagePage = () => {
  const dispatch = useDispatch();
  const message = useSelector(selectMessage);

  const IMAGE_BY_STATUS = {
    [MESSAGE_SUCCESS_STATUS_NOMENCLATURE]: verification_success_img,
    [MESSAGE_ERROR_STATUS_NOMENCLATURE]: verification_failed_img
  };

  useEffect(() => {
    if (!message) {
      dispatch(replace(ROUTES.HOME));
    }
  }, [message]);

  return (
    <TwoColumns showSecondColumn={false} md={18}>
      <Result
        status={message.status}
        title={message.title}
        subTitle={message.subTitle}
        icon={
          <img
            style={{ width: '200px' }}
            src={IMAGE_BY_STATUS[message.status]}
            alt="bla"
          />
        }
      />
    </TwoColumns>
  );
};

export default MessagePage;
