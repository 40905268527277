import React from 'react';
import { useDispatch } from 'react-redux';

import { Col, Divider, Row, Typography } from 'antd';

import {
  getCustomerIdentity,
  resetDataCustomerIdentity,
  saveApplyFormValues,
  enterApplicantDataManually,
  skipInputForm
} from 'redux/app/actions';

import { Form, FormItem } from 'components/common/Form';
import ButtonLink, { Button } from 'components/common/Buttons';
import SSN from '../Apply/Form/Components/SSN';
import Phone from '../Apply/Form/Components/Phone';

import {
  APPLICANT_PREFIX,
  BORROWER_NOMENCLATURE,
  PRIVACY_POLICY_URL
} from 'redux/app/constants';
import { GUTTER_DEFAULT } from 'theme/variables';
import { ROUTES } from 'consts/routes';
import { replace } from 'connected-react-router';
import { Text } from 'components/common/Typography';

function FormBe() {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const handleOnValueChange = (changedValues, previousValues) => {
    dispatch(saveApplyFormValues({ ...previousValues, ...changedValues }));
  };

  const handleApplyForm = () => {
    const applicantType = BORROWER_NOMENCLATURE;

    dispatch(skipInputForm());
    dispatch(enterApplicantDataManually({ applicantType, value: true }));
    dispatch(replace(ROUTES.APPLY_FORM));
  };

  const handleOnFinish = async e => {
    const applicantType = BORROWER_NOMENCLATURE;

    try {
      const ssnFieldName = `${APPLICANT_PREFIX}SSN`;
      const phoneFieldName = `${APPLICANT_PREFIX}PhoneNumber`;

      const { [ssnFieldName]: ssn, [phoneFieldName]: phoneNumber } =
        await form.validateFields([ssnFieldName, phoneFieldName]);
      dispatch(resetDataCustomerIdentity({ applicantType }));
      dispatch(getCustomerIdentity({ applicantType, requiredId: false }));

      dispatch(replace(ROUTES.APPLY_FORM));
    } catch (error) {
      console.log('An error has occurred. Please contact us.');
    }
  };

  const required_rules = {
    required: true,
    message: 'This field is required.'
  };

  return (
    <>
      <Typography.Text level={1} strong>
        Please enter the following information:
      </Typography.Text>
      <Divider margin={'10px 0'} />

      <Form
        form={form}
        layout="vertical"
        onValuesChange={handleOnValueChange}
        onFinish={handleOnFinish}
      >
        <Row gutter={GUTTER_DEFAULT}>
          <Col xs={24} md={12} style={{ height: '88px' }}>
            <SSN
              fieldsPrefix={APPLICANT_PREFIX}
              required_rules={required_rules}
              form={form}
            />
          </Col>
          <Col xs={24} md={12} style={{ height: '88px' }}>
            <Phone
              label="Mobile Number"
              fieldsPrefix={APPLICANT_PREFIX}
              required={true}
              required_rules={required_rules}
              placeholder="Mobile Number"
              form={form}
            />
          </Col>
          <Col xs={24}>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%', marginTop: '20px' }}
              >
                Continue
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Form>

      <Divider margin={'10px 0'} />
      <Typography.Text>
        <strong>Don’t have a mobile phone?</strong>{' '}
        <ButtonLink onClick={handleApplyForm}>Click here</ButtonLink>
      </Typography.Text>
      <div>
        <Text style={{ fontSize: '14px' }}>
          You authorize Service Finance to send you a one-time text to the
          mobile phone you provided and for your wireless carrier to use or
          disclose information about your account and your wireless device, is
          available, to Service Finance or its service provider for the duration
          of your business relationship, solely to help them identify you or
          your wireless device and to prevent fraud. See our{' '}
          <ButtonLink
            href={PRIVACY_POLICY_URL}
            target="_blank"
            style={{ fontSize: '14px' }}
          >
            Privacy Policy
          </ButtonLink>{' '}
          for how we treat your data. Standard text messaging rates may apply.
        </Text>
      </div>
    </>
  );
}

export default FormBe;
