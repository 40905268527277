import React, { useState } from 'react';
import styled from 'styled-components';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  StreetViewPanorama,
  OverlayView,
  Marker
} from 'react-google-maps';

import { Radio, Spin } from 'antd';

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2)
});

const SatelliteMap = compose(
  withProps({
    googleMapURL: process.env.REACT_APP_MAPS_URL,
    loadingElement: (
      <Spin spinning>
        <div style={{ height: `300px` }} />
      </Spin>
    ),
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <>
    <GoogleMap
      mapTypeId={'satellite'}
      defaultZoom={8}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
      {props.showMarker ? (
        <Marker position={{ lat: props.lat, lng: props.lng }} />
      ) : null}
    </GoogleMap>
  </>
));

const StreetViewMap = compose(
  withProps({
    googleMapURL: process.env.REACT_APP_MAPS_URL,
    loadingElement: (
      <Spin spinning>
        <div style={{ height: `400px` }} />
      </Spin>
    ),
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={8} defaultCenter={props.center}>
    <StreetViewPanorama
      defaultPosition={{ lat: props.lat, lng: props.lng }}
      visible
    >
      <OverlayView
        position={{ lat: props.lat, lng: props.lng }}
        mapPaneName={OverlayView.OVERLAY_LAYER}
        getPixelPositionOffset={getPixelPositionOffset}
      >
        <Marker position={{ lat: props.lat, lng: props.lng }} />
      </OverlayView>
    </StreetViewPanorama>
  </GoogleMap>
));

const RadioGroupAntd = Radio.Group;

const RadioGroup = styled(RadioGroupAntd)`
  &.ant-radio-group {
    margin: 10px 0 0 15px;
    position: absolute;
    z-index: 2;
  }
`;

function Map({ lat, lng, ...props }) {
  const nomenclatures = {
    satellite: 'satellite',
    streetview: 'streetview'
  };

  const [showStreetView, setShowStreetView] = useState(false);
  const [radioOption, setRadioOption] = useState(nomenclatures.satellite);

  const handleViewChange = e => {
    const value = e.target.value;
    setShowStreetView(value === nomenclatures.streetview);
    setRadioOption(value);
  };

  return (
    <div {...props}>
      <RadioGroup value={radioOption} onChange={handleViewChange} size="small">
        <Radio.Button value={nomenclatures.satellite}>Satellite</Radio.Button>
        <Radio.Button value={nomenclatures.streetview}>
          Street view
        </Radio.Button>
      </RadioGroup>
      {showStreetView ? (
        <StreetViewMap lat={lat} lng={lng} />
      ) : (
        <SatelliteMap lat={lat} lng={lng} showMarker />
      )}
    </div>
  );
}

export default Map;
