import React from 'react';
import { SubTitle } from 'components/common/Typography';

function HomeDefaultMessage() {
  return (
    <SubTitle style={{ marginTop: '10px' }}>
      This web page is not an advertisement to the general public and is not
      intended for review by consumers. This web page is designed for potential
      and approved Service Finance Company, LLC Dealers, for their consideration
      of financing opportunities in working with their consumers. If you are not
      an authorized dealer, please exit this site and immediately contact your
      local dealer for assistance. Thank You.
    </SubTitle>
  );
}

export default HomeDefaultMessage;
