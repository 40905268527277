import React from 'react';
import { useSelector } from 'react-redux';

import { Col } from 'antd';
import SalesRepInformationForm from '../SalesRepInformationForm';

import {
  selectIsForBDCode,
  selectIsForCustomer,
  selectIsForQRCode,
  selectIsForBECode
} from 'redux/app/selectors';

function SalesRepInformation({ required_rules, form }) {
  const isForCustomer = useSelector(selectIsForCustomer);
  const isForQRCode = useSelector(selectIsForQRCode);
  const isForBDCode = useSelector(selectIsForBDCode);
  const isForBECode = useSelector(selectIsForBECode);

  if (isForCustomer || isForQRCode || isForBDCode || isForBECode) {
    return null;
  }

  return (
    <Col
      sm={{ span: 24 }}
      md={{ span: 24 }}
      xl={{ span: 20 }}
      xxl={{ span: 16 }}
    >
      <SalesRepInformationForm
        title="Sales Rep Information"
        required_rules={required_rules}
        form={form}
      />
    </Col>
  );
}

export default SalesRepInformation;
