import React from 'react';
import { Link } from 'react-router-dom';

import ExceptionPage from '../ExceptionPage';

import { ROUTES } from 'consts/routes';

const NotAuthorized = ({
  message = 'Sorry, you are not authorized to access this document.'
}) => {
  return (
    <ExceptionPage
      titlePage="Not authorized"
      message={
        <>
          {message}
          <br />
          <Link to={ROUTES.HOME}>Go to homepage</Link>
        </>
      }
    />
  );
};

export default NotAuthorized;
