import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'components/common/Buttons';

import { addCoApplicant, showCoApplicantModal } from 'redux/app/actions';

function RemoveCoApplicantButton({ ...props }) {
  const dispatch = useDispatch();
  const handleRemoveCoApplicantOnClick = useCallback(() => {
    // const values = Object.keys(form.getFieldsValue(true)).reduce(
    //   (output, key) => {
    //     return key.includes(CO_APPLICANT_PREFIX)
    //       ? { ...output, [key]: null }
    //       : output;
    //   },
    //   {}
    // );
    // form.setFieldsValue(values);
    dispatch(addCoApplicant(false));
    dispatch(showCoApplicantModal(false));
    // removeCoApplicantConfirmedAddress();
    // }, [dispatch, form, removeCoApplicantConfirmedAddress]);
  }, [dispatch]);

  return (
    <Button
      type="secondary"
      onClick={handleRemoveCoApplicantOnClick}
      {...props}
    >
      Remove Co-Applicant
    </Button>
  );
}

export default memo(RemoveCoApplicantButton);
