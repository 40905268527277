import React from 'react';

import Result from 'components/common/Result';

import { result_conf_to_show } from './variables';
import verification_success_img from 'images/verification_success.svg';
import TwoColumns from 'components/common/Content/TwoColumns';

function StopInCurrentView() {
  return (
    <TwoColumns md={18} showSecondColumn={false}>
      <Result
        status={result_conf_to_show.status}
        title={result_conf_to_show.message}
        subTitle={result_conf_to_show.subtitle}
        icon={
          <img
            style={{ width: '200px' }}
            src={verification_success_img}
            alt="thanks_for_apply"
          />
        }
      />
    </TwoColumns>
  );
}

export default StopInCurrentView;
