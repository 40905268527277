import React, { memo, useCallback, useEffect } from 'react';

import { Row, Col, Input, Select, Alert } from 'antd';
import { FormItem } from 'components/common/Form';

import { citizenshipData } from './DataSet';

import { GUTTER_DEFAULT } from 'theme/variables';
import {
  APPLICANT_PREFIX,
  BORROWER_NOMENCLATURE,
  COBORROWER_NOMENCLATURE,
  CO_APPLICANT_PREFIX
} from 'redux/app/constants';

import SSN from './Components/SSN';
import Phone from './Components/Phone';
import Address from './Components/Address';
import MonthlyIncome from './Components/MonthlyIncome';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectApplyFormValues,
  selectConfirmedAddresses
} from 'redux/app/selectors';
import { setConfirmedAddresseses } from 'redux/app/actions';
import DayOfBirth from './Components/DayOfBirth';
import {
  useCustomerIdentityAlerts,
  useCustomerIdentityError,
  useCustomerIdentityIsComplete,
  useCustomerIdentityIsExpired,
  useEnterApplicantDataManuallyData,
  usePrevious
} from 'hooks/data';
import CustomerIdentityForm from 'components/views/CustomerIdentityForm';
import { useState } from 'react';
import CoApplicantForm from './CoApplicantForm';
import Fieldset from './Components/Fieldset';
import RemoveCoApplicantButton from './Components/RemoveCoApplicantButton';
import { Message } from 'components/common/Text';
import { Title } from 'components/common/Typography';
import EnterDataOption from '../Components/EnterDataOption';
import LabelInfo from '../Components/LabelInfo';
import Email from './Components/Email';
import Alerts from 'components/common/Alerts';

const { Option } = Select;

function ApplicantManualForm({
  title,
  fieldsPrefix,
  required_rules,
  form,
  applicantType,
  TCPA = false
}) {
  const dispatch = useDispatch();
  const confirmedAddresses = useSelector(selectConfirmedAddresses);
  const customerIdentityAlerts = useCustomerIdentityAlerts(applicantType);
  const customerIdentityErrorMessage = useCustomerIdentityError(applicantType);
  const customerIdentityIsExpired = useCustomerIdentityIsExpired(applicantType);
  const customerIdentityIsComplete =
    useCustomerIdentityIsComplete(applicantType);

  const [showEmailPhoneMessage, setShowEmailPhoneMessage] = useState(false);
  const [configShowEmailPhoneMessage, setConfigShowEmailPhoneMessage] =
    useState(true);

  const isApplicant = applicantType === BORROWER_NOMENCLATURE;
  const isCoApplicant = applicantType === COBORROWER_NOMENCLATURE;

  const handleAddressChange = useCallback(() => {
    if (confirmedAddresses) {
      let newConfirmedAddresses = { ...confirmedAddresses };
      newConfirmedAddresses[fieldsPrefix] = false;
      dispatch(setConfirmedAddresseses(newConfirmedAddresses));
    }
  }, [confirmedAddresses, dispatch, fieldsPrefix]);

  const handleTurnOnShowMessage = () => {
    setConfigShowEmailPhoneMessage(true);
    setShowEmailPhoneMessage(true);
  };

  const handleTurnOffShowMessage = () => {
    setConfigShowEmailPhoneMessage(false);
  };

  const handleShowMessage = () => {
    setShowEmailPhoneMessage(true);
  };

  const showPhoneEmailInfo =
    showEmailPhoneMessage && configShowEmailPhoneMessage;

  return (
    <>
      <Fieldset title={title}>
        {customerIdentityErrorMessage ? (
          <Alert
            style={{ marginBottom: '5px' }}
            type="error"
            message={customerIdentityErrorMessage}
            closable
          />
        ) : null}
        {customerIdentityIsComplete || customerIdentityIsExpired ? (
          <Alerts
            alerts={customerIdentityAlerts}
            style={{ marginBottom: '5px' }}
          />
        ) : null}
        <EnterDataOption applicantType={applicantType} />
        <Row gutter={GUTTER_DEFAULT}>
          {/* First Name */}
          <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 16 }}>
            <FormItem
              label="First Name"
              name={`${fieldsPrefix}FirstName`}
              rules={[required_rules]}
              required
            >
              <Input autoComplete="off" placeholder="First Name" allowClear />
            </FormItem>
          </Col>

          {/* MI */}
          <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 2 }}>
            <FormItem label="MI" name={`${fieldsPrefix}MI`}>
              <Input autoComplete="off" placeholder="MI" />
            </FormItem>
          </Col>

          {/* Last Name */}
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <FormItem
              label="Last Name"
              name={`${fieldsPrefix}LastName`}
              rules={[required_rules]}
              required
            >
              <Input autoComplete="off" placeholder="Last Name" allowClear />
            </FormItem>
          </Col>
        </Row>
        <Address
          fieldsPrefix={fieldsPrefix}
          required_rules={required_rules}
          form={form}
          onAddressChange={handleAddressChange}
        />
        <Row gutter={GUTTER_DEFAULT}>
          {/* Date of Birth */}
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <DayOfBirth
              fieldsPrefix={fieldsPrefix}
              required_rules={required_rules}
            />
          </Col>

          {/* SSN */}
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <SSN
              fieldsPrefix={fieldsPrefix}
              required_rules={required_rules}
              form={form}
            />
          </Col>

          {/* Citizenship */}
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
            <FormItem
              label="Citizenship"
              name={`${fieldsPrefix}Citizenship`}
              initialValue={citizenshipData[0].value}
            >
              <Select placeholder="Select Citizenship">
                {citizenshipData.map((item, index) => {
                  return (
                    <Option
                      key={`citizenshipStatus-${index}`}
                      value={item.value}
                    >
                      {item.text}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={GUTTER_DEFAULT}>
          {/* Monthly income */}
          <Col xs={{ span: 24 }}>
            <MonthlyIncome
              fieldsPrefix={fieldsPrefix}
              form={form}
              required_rules={required_rules}
              required
            />
          </Col>
        </Row>
        <Row gutter={GUTTER_DEFAULT}>
          {/* Email Address */}
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Email
              label={
                TCPA ? (
                  <LabelInfo
                    text="Email Address"
                    condition={!showPhoneEmailInfo}
                    handleFunction={handleTurnOnShowMessage}
                  />
                ) : (
                  'Email Address'
                )
              }
              fieldsPrefix={fieldsPrefix}
              placeholder="Email Address"
              form={form}
              handleOnChange={handleShowMessage}
            />
          </Col>

          {/* Mobile Number */}
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Phone
              label={
                TCPA ? (
                  <LabelInfo
                    text="Mobile Number"
                    condition={!showPhoneEmailInfo}
                    handleFunction={handleTurnOnShowMessage}
                  />
                ) : (
                  'Mobile Number'
                )
              }
              fieldsPrefix={fieldsPrefix}
              placeholder="Mobile Number"
              form={form}
              handleOnChange={handleShowMessage}
            />
          </Col>
          {showPhoneEmailInfo && TCPA ? (
            <Col xs={{ span: 24 }}>
              <Message
                title={<Title>TCPA consent</Title>}
                closable
                style={{ marginBottom: '10px' }}
                onClose={handleTurnOffShowMessage}
              >
                For each <b>phone number</b> and <b>e-mail address</b> you
                provide, you agree that [dealer] and its affiliates, agents, and
                assignees (including Service Finance) may call, send text
                messages (including calling and sending text messages using
                equipment to automatically dial telephone numbers) and/or send
                emails to you from time to time for any reason about your
                account, including but not limited to, calls for collection or
                payment purposes. You understand and agree that we may always
                communicate with you in any manner permitted by law that does
                not require your prior consent.
              </Message>
            </Col>
          ) : null}
        </Row>
        {isCoApplicant ? (
          <Row gutter={GUTTER_DEFAULT}>
            <Col xs={{ span: 24 }}>
              <RemoveCoApplicantButton />
            </Col>
          </Row>
        ) : null}
      </Fieldset>
      {isApplicant ? (
        <CoApplicantForm
          required_rules={required_rules}
          form={form}
          fieldsPrefix={CO_APPLICANT_PREFIX}
        />
      ) : null}
    </>
  );
}

function ApplicantForm({
  title,
  required_rules,
  form,
  applicantType,
  fieldsPrefix = APPLICANT_PREFIX
}) {
  const [formValuesReloaded, setFormValuesReloaded] = useState(false);

  const applyFormValues = useSelector(selectApplyFormValues);

  const applicantDataManually =
    useEnterApplicantDataManuallyData(applicantType);
  const previousApplicantDataManually = usePrevious(applicantDataManually);

  useEffect(() => {
    if (!previousApplicantDataManually && applicantDataManually) {
      setFormValuesReloaded(false);
    }
  }, [applicantDataManually, previousApplicantDataManually]);

  useEffect(() => {
    if (!formValuesReloaded && applicantDataManually) {
      const formValues = form.getFieldsValue();
      form.resetFields();
      form.setFieldsValue({ ...formValues, ...applyFormValues });
      setFormValuesReloaded(true);
    }
  }, [
    formValuesReloaded,
    previousApplicantDataManually,
    applicantDataManually,
    applyFormValues,
    form
  ]);

  return applicantDataManually ? (
    <ApplicantManualForm
      required_rules={required_rules}
      form={form}
      fieldsPrefix={fieldsPrefix}
      applicantType={applicantType}
      title={title}
    />
  ) : (
    <CustomerIdentityForm
      required_rules={required_rules}
      form={form}
      applicantType={applicantType}
      fieldsPrefix={fieldsPrefix}
      title={title}
    />
  );
}

export default memo(ApplicantForm);
