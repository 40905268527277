import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBlank } from 'utils/functions';

import { Alert, Form, Skeleton, Space, Spin } from 'antd';
import { Button } from 'components/common/Buttons';
import { Radio } from 'components/common/Inputs';
import { Title, SubTitle } from 'components/common/Typography';

import { FormItem } from 'components/common/Form';

import {
  resetApp,
  putVerifyProductByDealer,
  getVerifyProductByDealer
} from 'redux/app/actions';
import {
  selectDealerDataToVerify,
  selectDealerNumberFormVerifyDataError,
  selectIsFetchingPutVerifyDealerData,
  selectIsFetchinVerifyProductByDealerData,
  selectVerifyProductByDealerData
} from 'redux/app/selectors';
import { selectDealerNumber } from 'redux/tokens/selectors';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ACTION_COLOR, FONT_WEIGHT } from 'theme/variables';

function DealerDataToVerifyForm() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(true);

  const dealerDataToVerify = useSelector(selectDealerDataToVerify);
  const isFetchingPutVerifyDealerData = useSelector(
    selectIsFetchingPutVerifyDealerData
  );

  const isFetchinVerifyProductByDealerData = useSelector(
    selectIsFetchinVerifyProductByDealerData
  );

  const handleOnChangeDealer = () => {
    dispatch(resetApp());
  };

  const handleOnFinish = values => {
    dispatch(putVerifyProductByDealer(values.dealerData));
  };

  const handleOnChange = e => {
    setDisable(isBlank(form.getFieldValue('dealerData')));
  };

  return (
    <Form
      style={{ marginTop: '10px' }}
      layout="vertical"
      form={form}
      scrollToFirstError
      onFinish={handleOnFinish}
    >
      <Spin spinning={isFetchinVerifyProductByDealerData}>
        <FormItem
          name={'dealerData'}
          rules={[
            {
              required: true,
              message: 'This field is required.'
            }
          ]}
          required
        >
          <Radio.Group onChange={handleOnChange} style={{ marginTop: '20px' }}>
            <Space direction="vertical">
              {dealerDataToVerify.map((item, index) => (
                <Radio
                  key={`dealerData-${index}`}
                  value={item.id}
                  defaultChecked={false}
                >
                  <SubTitle>{item.name}</SubTitle>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </FormItem>
      </Spin>
      <Space size={[8, 16]} wrap style={{ marginTop: '20px' }}>
        <Button
          htmlType="button"
          onClick={handleOnChangeDealer}
          type="secondary"
        >
          Back
        </Button>
        <FormItem style={{ margin: 0 }}>
          <Button
            htmlType="submit"
            type="primary"
            disabled={disable}
            loading={isFetchingPutVerifyDealerData}
          >
            Next
          </Button>
        </FormItem>
      </Space>
    </Form>
  );
}

function DealerDataToVerify() {
  const dispatch = useDispatch();
  const dealerNumber = useSelector(selectDealerNumber);
  const dealerNumberFormVerifyDataError = useSelector(
    selectDealerNumberFormVerifyDataError
  );

  const verifyProductByDealerData = useSelector(
    selectVerifyProductByDealerData
  );

  const isFetchinVerifyProductByDealerData = useSelector(
    selectIsFetchinVerifyProductByDealerData
  );

  const handleOnChangeDealer = () => {
    dispatch(resetApp());
  };

  const handleGetDataToVerify = () => {
    dispatch(getVerifyProductByDealer(dealerNumber));
  };

  return (
    <>
      <Title>
        As a security measure please select the product that most closely
        represents your bussiness.
      </Title>
      {dealerNumberFormVerifyDataError ? (
        <Alert
          style={{ margin: '15px 0' }}
          type="error"
          message={dealerNumberFormVerifyDataError}
        />
      ) : null}
      {!verifyProductByDealerData ? (
        <>
          {isFetchinVerifyProductByDealerData ? (
            <Skeleton />
          ) : (
            <Paragraph>
              <blockquote>
                Sorry an error occurred. If the problem persists, do not
                hesitate to contact us. <br />
                If you wish, you can{' '}
                <Button
                  type="link"
                  onClick={handleGetDataToVerify}
                  style={{
                    padding: 0,
                    fontSize: 'inherit',
                    color: ACTION_COLOR,
                    fontweight: FONT_WEIGHT
                  }}
                >
                  try again
                </Button>
                . We regret the inconvenience.
              </blockquote>
              {/* <pre>{blockContent}</pre> */}
            </Paragraph>
          )}
          <Space size={[8, 16]} wrap style={{ marginTop: '20px' }}>
            <Button
              htmlType="button"
              onClick={handleOnChangeDealer}
              type="secondary"
            >
              Back
            </Button>
          </Space>
        </>
      ) : (
        <DealerDataToVerifyForm />
      )}
    </>
  );
}

export default memo(DealerDataToVerify);
