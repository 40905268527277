import React, { memo } from 'react';

import { Alert, Alert as AlertAndt, Typography } from 'antd';
import { isEmpty } from 'lodash';
import {
  ERROR_ALERT_NOMENCLATURE,
  SUCCESS_ALERT_NOMENCLATURE,
  WARNING_ALERT_NOMENCLATURE
} from 'redux/app/constants';
import { FONT_SIZE_SMALL } from 'theme/variables';
import { InfoCircleFilled } from '@ant-design/icons';

const { Paragraph, Text } = Typography;

export const ALERT_ERROR = {
  type: 'error',
  value: 1,
  closable: true,
  title: 'The following errors were found:'
};
export const ALERT_WARNING = {
  type: 'warning',
  value: 2,
  closable: true,
  title: 'Please, pay attention to the following information:'
};
export const ALERT_INFO = {
  type: 'info',
  value: 3,
  closable: true,
  title: 'Information:'
};
export const ALERTS = [ALERT_ERROR, ALERT_WARNING, ALERT_INFO];

function AlertListMessage({ alerts, ...props }) {
  return (
    <Paragraph style={{ margin: 0, fontSize: FONT_SIZE_SMALL }}>
      <ul style={{ margin: 0 }}>
        {alerts.map((alert, index) => (
          <li
            key={`${props.type}-${++index}`}
            style={{ listStyleType: 'none', margin: 0 }}
          >
            <Text strong>{alert.message} </Text>{' '}
            {props.isFetchingAlert ? <br /> : null}
            {alert.description}
          </li>
        ))}
      </ul>
    </Paragraph>
  );
}

function RenderAlerts({ alerts, ...props }) {
  return props.isFetchingAlert ? (
    <AlertListMessage alerts={alerts} {...props} />
  ) : (
    <Alert
      message={<AlertListMessage alerts={alerts} {...props} />}
      showIcon={false}
      closable
      {...props}
    />
  );
}

function Alerts({ alerts, ...props }) {
  if (isEmpty(alerts)) {
    return null;
  }

  var errorAlerts = [];
  var warningAlerts = [];
  var successAlerts = [];
  var infoAlerts = [];

  alerts.forEach(alert => {
    switch (alert.type?.id) {
      case ERROR_ALERT_NOMENCLATURE:
        errorAlerts.push({
          message: alert.message?.name,
          description: alert.message?.description,
          isValid: alert.message?.isValid
        });
        break;
      case WARNING_ALERT_NOMENCLATURE:
        warningAlerts.push({
          message: alert.message?.name,
          description: alert.message?.description,
          isValid: alert.message?.isValid
        });
        break;
      case SUCCESS_ALERT_NOMENCLATURE:
        successAlerts.push({
          message: alert.message?.name,
          description: alert.message?.description,
          isValid: alert.message?.isValid
        });
        break;

      default:
        infoAlerts.push({
          message: alert.message?.name,
          description: alert.message?.description,
          isValid: alert.message?.isValid
        });
        break;
    }
  });

  return (
    <>
      {!isEmpty(errorAlerts) ? (
        <RenderAlerts
          alerts={errorAlerts}
          type="error"
          showIcon
          icon={<InfoCircleFilled />}
          {...props}
        />
      ) : null}
      {!isEmpty(warningAlerts) ? (
        <RenderAlerts
          alerts={warningAlerts}
          type="warning"
          showIcon
          icon={<InfoCircleFilled />}
          {...props}
        />
      ) : null}
      {!isEmpty(successAlerts) ? (
        <RenderAlerts
          alerts={successAlerts}
          type="success"
          showIcon
          icon={<InfoCircleFilled />}
          {...props}
        />
      ) : null}
      {!isEmpty(infoAlerts) ? (
        <RenderAlerts
          alerts={infoAlerts}
          type="warning"
          showIcon
          icon={<InfoCircleFilled />}
          {...props}
        />
      ) : null}
    </>
  );
}

export default memo(Alerts);
