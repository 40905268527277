import React from 'react';
import styled from 'styled-components';

import { Row, Typography } from 'antd';

import ContainerFluid from '../Content/ContainerFluid';
import { BigText } from '../Typography';

import {
  BREAKPOINT_LG,
  BACKGROUND_DEFAULT,
  BACKGROUND_COLOR_LIGHT,
  FONT_IN_BG
} from 'theme/variables';

const RowInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: ${BREAKPOINT_LG}px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 33.33%;

  @media (max-width: ${BREAKPOINT_LG}px) {
    width: 100%;
  }
`;

const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ColumnNumber = styled.div`
  color: ${FONT_IN_BG};
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircleStep = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${BACKGROUND_DEFAULT};
  color: #008c59;
  font-size: 40px;
  text-align: center;
  line-height: 75px;
  font-weight: bold;
`;

const ColumnInfo = styled.div`
  padding: 20px;
  box-sizing: border-box;
`;

export const LayerInfoBd = () => {
  return (
    <Row style={{ background: BACKGROUND_COLOR_LIGHT, textAlign: 'center' }}>
      <ContainerFluid mg="auto" pd="40px">
        <Typography.Title
          level={2}
          style={{ color: FONT_IN_BG, marginBottom: '5px' }}
        >
          Financing built to save you time!
        </Typography.Title>
        <Typography.Title
          level={3}
          style={{ color: FONT_IN_BG, margin: 0, fontWeight: 400 }}
        >
          Ready to get started? Here’s how our fast online process works.
        </Typography.Title>
        <RowInfo>
          <Column>
            <WrapperInfo>
              <ColumnNumber>
                <CircleStep>1</CircleStep>
              </ColumnNumber>
              <ColumnInfo>
                <Typography.Title level={3} style={{ color: FONT_IN_BG }}>
                  Prequalify Online
                </Typography.Title>
                <BigText whiteColor>
                  There is no impact to your credit score and takes minutes!
                </BigText>
              </ColumnInfo>
            </WrapperInfo>
          </Column>
          <Column>
            <WrapperInfo>
              <ColumnNumber>
                <CircleStep>2</CircleStep>
              </ColumnNumber>
              <ColumnInfo>
                <Typography.Title level={3} style={{ color: FONT_IN_BG }}>
                  Complete Your Application
                </Typography.Title>
                <BigText whiteColor>
                  Once prequalified, choose to answer a few more questions to
                  get pre-approved.
                </BigText>
              </ColumnInfo>
            </WrapperInfo>
          </Column>
          <Column>
            <WrapperInfo>
              <ColumnNumber>
                <CircleStep>3</CircleStep>
              </ColumnNumber>
              <ColumnInfo>
                <Typography.Title level={3} style={{ color: FONT_IN_BG }}>
                  Dealer will Contact You
                </Typography.Title>
                <BigText whiteColor>
                  Once pre-approved, an authorized dealer will help you finish
                  your application.
                </BigText>
              </ColumnInfo>
            </WrapperInfo>
          </Column>
        </RowInfo>
      </ContainerFluid>
    </Row>
  );
};
