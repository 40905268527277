import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel
} from '@microsoft/signalr';

import * as CONSTANTS from 'redux/WebSockets/constants';
import {
  start,
  onReconnecting,
  onReconnected,
  receiveNotification,
  receiveOpenItemNotification,
  receiveCloseItemNotification,
  onClose,
  onError,
  init
} from './helpers';

export const initSignalr = store => init(store);

export const createSignalrMiddleware = () => {
  const connection = new HubConnectionBuilder()
    .withUrl(CONSTANTS.WS_HUB_URL)
    .configureLogging(LogLevel.Information)
    .build();

  // the middleware part of this function
  return store => next => action => {
    const payload = action.payload;

    switch (action.type) {
      case CONSTANTS.WS_INIT:
        if (
          connection !== null &&
          connection.state === HubConnectionState.Disconnected
        ) {
          connection.onreconnecting(onReconnecting(store, connection));
          connection.onreconnected(onReconnected(store, connection));
          connection.onclose(onClose(store, connection));
          // Subscribe to Notification channel
          connection.on(CONSTANTS.WS_HUB_NOTIFY, receiveNotification(store));
          connection.on(
            CONSTANTS.WS_HUB_OPEN_ITEM_DATA,
            receiveOpenItemNotification(store)
          );
          connection.on(
            CONSTANTS.WS_HUB_CLOSE_ITEM_DATA,
            receiveCloseItemNotification(store)
          );

          start(store, connection)();
        }

        break;
      case CONSTANTS.WS_STOP:
        if (
          connection !== null &&
          connection.state === HubConnectionState.Connected
        ) {
          connection.stop();
        }
        break;
      case CONSTANTS.WS_SEND_NOTIFICATION:
        if (connection.state === HubConnectionState.Connected) {
          connection.invoke(CONSTANTS.WS_HUB_NOTIFY, ...payload).catch(onError);
        }
        break;
      default:
        return next(action);
    }
  };
};
