import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

import ButtonLink from 'components/common/Buttons';

import { ROUTES } from 'consts/routes';
import {
  legalLinksName,
  PRIVACY_POLICY_URL,
  CONSUMER_RIGHTS_NOTICE_URL
} from 'redux/app/constants';

function LegalLink({ route, ...props }) {
  const dispatch = useDispatch();

  const handleGoTo = (e, params) => {
    dispatch(replace(params));
  };

  switch (route) {
    case ROUTES.PRIVACY_POLICY:
      return (
        <ButtonLink
          params={route}
          target="_blank"
          href={PRIVACY_POLICY_URL}
          {...props}
        >
          {legalLinksName[route]}
        </ButtonLink>
      );

    case ROUTES.CONSUMER_RIGHTS_NOTICE:
      return (
        <ButtonLink
          params={route}
          target="_blank"
          href={CONSUMER_RIGHTS_NOTICE_URL}
          {...props}
        >
          {legalLinksName[route]}
        </ButtonLink>
      );

    default:
      return (
        <ButtonLink params={route} onClick={handleGoTo} {...props}>
          {legalLinksName[route]}
        </ButtonLink>
      );
  }
}

export default memo(LegalLink);
