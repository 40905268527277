import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtonLink from 'components/common/Buttons';
import {
  enterApplicantDataManually,
  getCustomerIdentity,
  getCustomerIdentityStop
} from 'redux/app/actions';
import { FONT_COLOR } from 'theme/variables';
import {
  selectIsForBDCode,
  selectIsForBECode,
  selectIsForQRCode
} from 'redux/app/selectors';

function RequestOptions({
  applicantType,
  resend = false,
  cancel = false,
  ...props
}) {
  const dispatch = useDispatch();
  const isForQRCode = useSelector(selectIsForQRCode);
  const isForBDCode = useSelector(selectIsForBDCode);
  const isForBECode = useSelector(selectIsForBECode);

  const handleResendRequest = () => {
    dispatch(getCustomerIdentity({ applicantType, requiredId: false }));
  };

  const handleCancelRequest = () => {
    dispatch(getCustomerIdentityStop({ applicantType }));

    if (!isForQRCode && !isForBDCode && !isForBECode) {
      dispatch(enterApplicantDataManually({ applicantType, value: true }));
    }
  };

  return (
    <div {...props}>
      {resend ? (
        <ButtonLink onClick={handleResendRequest}>Resend Message</ButtonLink>
      ) : null}
      {resend && cancel ? (
        <span style={{ margin: '0 10px', color: FONT_COLOR }}>|</span>
      ) : null}
      {cancel ? (
        <ButtonLink onClick={handleCancelRequest}>Cancel Request</ButtonLink>
      ) : null}
    </div>
  );
}

export default memo(RequestOptions);
