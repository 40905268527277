import React from 'react';

import { LogoContainer } from 'components/common/Content';

import { ROUTES } from 'consts/routes';
import { replace } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import logoImg from 'images/SFC_Truist.jpg';
import logoImgApplyNow from 'images/applyNow.png';

import { SubTitle, Title } from '../Typography';
import isEmpty from 'lodash/isEmpty';

const Logo = ({ title = false, subtitle = false, logoForBd, ...props }) => {
  const dispatch = useDispatch();

  function handleOnClick() {
    dispatch(replace(ROUTES.HOME));
  }

  return (
    <LogoContainer>
      {logoForBd ? (
        <img
          className="logo-applyNow"
          src={logoImgApplyNow}
          alt="Apply Now Logo"
        />
      ) : (
        <img
          className="logo"
          src={logoImg}
          alt="Service Finance Company Logo"
        />
      )}

      {!isEmpty(title) ? (
        <Title style={{ margin: '10px 0 0' }}>{title}</Title>
      ) : null}
      {!isEmpty(subtitle) ? (
        <SubTitle style={{ margin: '10px 0' }}>{subtitle}</SubTitle>
      ) : null}
    </LogoContainer>
  );
};

export default Logo;
