import { HubConnectionState } from '@microsoft/signalr';

import { MAX_RETRY, RETRY_TIME } from 'redux/WebSockets/constants';
import * as actions from 'redux/WebSockets/actions';

let retryCount = 0;
let retryTime = RETRY_TIME;

/**
 * Handles reconnecting event
 * @param {*} store
 * @param {} connection
 */
export const onReconnecting = (store, connection) => error => {
  console.assert(connection.state === HubConnectionState.Reconnecting);

  store.dispatch(actions.wsReConnecting(error));

  console.info(`Connection lost due to error "${error}". Reconnecting...`);
};

/**
 * Handles reconected event
 * @param {*} store
 * @param {*} connection
 */
export const onReconnected = (store, connection) => connectionId => {
  console.assert(connection.state === HubConnectionState.Connected);

  console.info(
    `Connection reestablished. Connected with connectionId "${connectionId}".`
  );

  store.dispatch(actions.wsReConnected(connectionId));
  // If we can connect and all in use was active, then stop it.
};

/**
 * Handles on close event
 * @param {*} store
 * @param {*} connection
 */
export const onClose = (store, connection) => error => {
  console.assert(connection.state === HubConnectionState.Disconnected);

  console.error(
    `Connection closed due to error "${error}". Try refreshing this page to restart the connection.`
  );

  store.dispatch(actions.wsClosed(error));
};

/**
 * Handles on error event
 * @param {*} store
 */
export const onError = store => error => {
  store.dispatch(actions.wsError(error));
  console.error(error.toString());
};

export const start = (store, connection) => {
  const startAsync = async () => {
    try {
      if (retryCount === MAX_RETRY) {
        console.warn(
          `Max WS connection attempts (${MAX_RETRY}) reached. Activating All In Use and Contract Validation Queue polling.`
        );
        return;
      }

      await connection.start();
      console.assert(connection.state === HubConnectionState.Connected);
      console.log('WS connected');
      store.dispatch(actions.wsConnected());

      // If we can connect and all in use was active, then stop it.
    } catch (err) {
      console.log(err);
      console.assert(connection.state === HubConnectionState.Disconnected);
      retryCount++;
      retryTime *= retryCount;
      store.dispatch(actions.wsError(err));

      setTimeout(() => {
        store.dispatch(actions.wsError(err));
        startAsync();
      }, retryTime);
    }
  };

  return startAsync;
};

export const receiveNotification =
  store =>
  (
    appInst = null,
    notificationId,
    elementId = null,
    elementNumber = null,
    userName = null,
    description = null,
    data = null
  ) => {
    store.dispatch(
      actions.wsNotificationReceived({
        appInst,
        notificationId,
        elementId,
        elementNumber,
        userName,
        description,
        data
      })
    );
  };

export const receiveOpenItemNotification =
  store =>
  (
    appInst = null,
    elementId = null,
    areaId = null,
    userName = null,
    createdDate = null,
    expirationDate = null,
    description = null,
    msg = null
  ) => {
    if (!store) {
      return;
    }
    store.dispatch(
      actions.wsOpenItemDataReceived({
        appInst,
        elementId,
        areaId,
        userName,
        createdDate,
        expirationDate,
        description,
        msg
      })
    );
  };

export const receiveCloseItemNotification =
  store =>
  (
    appInst = null,
    elementId = null,
    areaId = null,
    userName = null,
    msg = null
  ) => {
    if (!store) {
      return;
    }
    store.dispatch(
      actions.wsCloseItemDataReceived({
        appInst,
        elementId,
        areaId,
        userName,
        msg
      })
    );
  };

export const init = store => {
  store.dispatch(actions.wsInit());
};
