import React, { memo } from 'react';

import { FormItem } from 'components/common/Form';
import { Input } from 'antd';

export function FirstName({ fieldsPrefix, required_rules }) {
  return (
    <FormItem
      label="First Name"
      name={`${fieldsPrefix}FirstName`}
      rules={[required_rules]}
      required
    >
      <Input autoComplete="off" placeholder="First Name" allowClear />
    </FormItem>
  );
}

export function MiddleName({ fieldsPrefix }) {
  return (
    <FormItem label="MI" name={`${fieldsPrefix}MI`}>
      <Input autoComplete="off" placeholder="MI" />
    </FormItem>
  );
}

export function LastName({ fieldsPrefix, required_rules }) {
  return (
    <FormItem
      label="Last Name"
      name={`${fieldsPrefix}LastName`}
      rules={[required_rules]}
      required
    >
      <Input autoComplete="off" placeholder="Last Name" allowClear />
    </FormItem>
  );
}

export default memo(FirstName);
