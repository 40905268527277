import React from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { useIsTabletOrMobile } from 'hooks/responsive';

import DealersTable from './Components/Table';
import DealersList from './Components/List';
import ModalMap from './Components/Map';
import { Title } from 'components/common/Text';
import { CardContent } from 'components/common/Content';
import { Reload } from './Components';

import {
  selectAssociationCallReportItems,
  selectAssociationReloadCallReportIsFetching,
  selectSearchText
} from 'redux/app/selectors';

import { BACKGROUND_AREA } from 'theme/variables';

function DealersSet({ form }) {
  const searchText = useSelector(selectSearchText);
  const dataSource = useSelector(selectAssociationCallReportItems);
  const dataSourceIsFetching = useSelector(
    selectAssociationReloadCallReportIsFetching
  );
  const isTabletOrMobile = useIsTabletOrMobile();

  const dataSourceFiltered = dataSource?.filter(item => {
    const text = searchText?.toLowerCase();

    if (!item) {
      return null;
    }

    return (
      item.companyName?.toLowerCase().includes(text) ||
      item.contact?.email?.email?.toLowerCase().includes(text) ||
      item.contact?.phone?.display?.toLowerCase().includes(text) ||
      item.contact?.address?.address?.toLowerCase().includes(text)
    );
  });

  return (
    <>
      <CardContent
        title={<Title>Dealers</Title>}
        bordered={false}
        headStyle={{
          background: BACKGROUND_AREA,
          padding: 10
        }}
        bodyStyle={{
          padding: 0,
          ...(!isTabletOrMobile && {
            position: 'relative',
            ...(isEmpty(dataSourceFiltered) && { padding: '60px 0 0' })
          }),
          ...(isTabletOrMobile && {
            padding: '10px 0'
          })
        }}
        extra={<Reload form={form} />}
      >
        {isTabletOrMobile ? (
          <DealersList
            dataSource={dataSourceFiltered}
            dataSourceIsFetching={dataSourceIsFetching}
          />
        ) : (
          <DealersTable
            dataSource={dataSourceFiltered}
            dataSourceIsFetching={dataSourceIsFetching}
          />
        )}
      </CardContent>
      <ModalMap />
    </>
  );
}

export default DealersSet;
