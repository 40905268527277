import React from 'react';

import { Input } from 'antd';

function ZipCodeInput({ placeholder = 'Zip code', onChange, ...props }) {
  const pattern = /^\d*$/;

  const handleChange = e => {
    const value = e.target.value;
    if (pattern.test(value) && value.length <= 5) {
      if (typeof onChange === 'function') {
        onChange(value);
      }
    }
  };

  return (
    <Input
      placeholder={placeholder}
      {...props}
      maxLength={5}
      onChange={handleChange}
      style={{ padding: '2px 11px' }}
    />
  );
}

export default ZipCodeInput;
