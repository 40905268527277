import { encodeQueryData } from 'utils/functions';
import { regularAxios, authorizedAxios, regularHeimdalAxios } from './axios';
import mocks, {
  getVerifyProductByDealerNumberData,
  customerResponse,
  authPath
} from './mocks';
import qs from 'qs';
import { callReportAssociation } from './mocks/callReport';

/**
 * Posts pull check request
 *
 * @param {object} data
 * @returns {Promise}
 */
export const postPullCheckRequest = data => {
  return regularAxios.post('/softPullCheckV2', data, {
    headers: { nls: '30c9a298-ea5c-4005-96cf-fb1a373252f5' }
  });
  // return new Promise(resolve =>
  //   setTimeout(() => {
  //     resolve({
  //       data: mocks,
  //       isSuccess: true
  //     });
  //   }, 1000)
  // );
};

/**
 * Posts address for validation
 * @param {object} data
 * @returns {Promise}
 */
export const putAddressValidationVerify = data => {
  return regularHeimdalAxios.put('/v1/AddressValidation/Verify', data);
};

/**
 * Get data to validate dealer number
 * @param {int} dealerNumber
 * @returns {Promise}
 */
export const getVerifyProductByDealerNumber = (dealerNumber, applyFrom) => {
  return regularHeimdalAxios.get(
    `/v2/Dealer/VerifyProduct?DealerNumber=${dealerNumber}&applyFrom=${applyFrom}`
  );
  // return new Promise(resolve =>
  //   setTimeout(() => {
  //     resolve({
  //       data: getVerifyProductByDealerNumberData,
  //       isSuccess: false
  //     });
  //   }, 1000)
  // );
};

/**
 * Put data to validate dealer number
 * @param {Object} data
 * @returns {Promise}
 */
export const putVerifyProductByDealerNumber = data => {
  return regularHeimdalAxios.put(`/v2/Dealer/VerifyProduct`, data);
  // return new Promise(resolve =>
  //   setTimeout(() => {
  //     resolve({
  //       data: getVerifyProductByDealerNumberData,
  //       isSuccess: false
  //     });
  //   }, 1000)
  // );
};

/**
 * Put vfp token as notification to server that the verification was done
 * @param {String} vfp
 * @returns {Promise}
 */
export const putMobileVerificationAsSuccessfulRequest = ({
  requestId,
  vfp
}) => {
  return regularHeimdalAxios.put(
    `/v1/CustomerIdentity/AuthPath?RequestId=${requestId}&vfp=${vfp}`
  );

  // return new Promise(resolve =>
  //   setTimeout(() => {
  //     resolve({
  //       data: authPath,
  //       isSuccess: true
  //     });
  //   }, 1000)
  // );
};

/**
 * Get customer data
 * @param {String} ssn
 * @param {String} phoneNumber
 * @param {Int} applicantType
 * @param {String} DOB
 * @param {String} requestId
 * @param {String} FirstName
 * @param {String} LastName
 * @param {Int} applyFrom
 * @param {Int} dealerNumber
 *
 * @returns {Promise}
 */

export const getCustomerIdentityRequest = payload => {
  const dataEncoded = encodeQueryData(payload);

  return regularHeimdalAxios.get(`/v1/CustomerIdentity?${dataEncoded}`);
};

//get call report
export const getCallReportRequest = payload => {
  return regularHeimdalAxios.post(`/v1/ReportInteg/CallReport`, payload);
  // return new Promise(resolve =>
  //   setTimeout(() => {
  //     resolve({
  //       data: callReportAssociation,
  //       isSuccess: true,
  //       status: 200
  //       // isSuccess: false,
  //       // status: 400
  //     });
  //   }, 1000)
  // );
};
