import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Message } from 'components/common/Text';
import { Space } from 'antd';
import { Text } from 'components/common/Typography';

export default function MonthlyIncomeMessage({
  annualIncome,
  condition,
  handleOnClose
}) {
  if (!condition) {
    return null;
  }
  return (
    <Message style={{ marginBottom: 10 }} closable onClose={handleOnClose}>
      <Space direction="vertical">
        <Text>
          Your annual income is about{' '}
          <b>
            <FormattedNumber
              value={annualIncome}
              style="currency"
              currency="USD"
            />
          </b>
        </Text>
        <Text>
          <u>Alimony</u>, <u>child support</u>, or separate{' '}
          <u>maintenance income</u> need not be revealed if you do not wish to
          have it considered as a basis for repaying this obligation.
        </Text>
      </Space>
    </Message>
  );
}
