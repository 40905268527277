import React from 'react';

import { Result } from 'antd';
import TwoColumns from 'components/common/Content/TwoColumns';

const ExceptionPage = ({ titlePage, icon, message, action = null }) => {
  return (
    <TwoColumns showSecondColumn={false} md={18}>
      <Result icon={icon} title={titlePage} subTitle={message} extra={action} />
    </TwoColumns>
  );
};

export default ExceptionPage;
