import React, { memo } from 'react';

import { Row } from 'antd';

import { LayerAboutUs } from 'components/common/LayerAboutUs';
import Footer from 'components/layouts/Footer';
import Logo from 'components/common/Logo';
import ContainerFluid from './ContainerFluid';
import { LayerInfoBd } from '../LayerInfoFormBd';
import { LayerInfoBe } from '../LayerInfoFormBe';

import { BACKGROUND_DEFAULT } from 'theme/variables';

function LayoutCentered({
  headerTitle = '',
  headerSubtitle,
  children,
  footerLinks = true,
  ...props
}) {
  return (
    <>
      <Row style={{ background: BACKGROUND_DEFAULT }}>
        <ContainerFluid mg="auto" pd="16px 0 16px 40px" bg="#fff">
          <Logo title={headerTitle} subtitle={headerSubtitle} logoForBd />
        </ContainerFluid>
      </Row>
      {children}
      {props.type === 'formBd' ? (
        <>
          <LayerInfoBd />
          <LayerAboutUs />
        </>
      ) : (
        <LayerInfoBe />
      )}

      <Footer footerLinks={footerLinks} />
    </>
  );
}

export default memo(LayoutCentered);
