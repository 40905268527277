import React, { memo } from 'react';

import { FormItem } from 'components/common/Form';
import { SpacedMaskedInput } from 'components/common/Inputs';

// /^(?:\d{10}|\d{3}-\d{3}-\d{4}|\d{3}\s\d{3}\s\d{4}|)$/, another pattern

const phone_pattern = /^([0-9]{3})?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

function Phone({
  label,
  fieldsPrefix,
  placeholder = false,
  required = false,
  required_rules,
  handleOnChange
}) {
  const phoneProperties = {
    pattern: required
      ? phone_pattern
      : /^((_{3}-_{3}-_{4})|([0-9]{3})?[-. ]?([0-9]{3})[-. ]?([0-9]{4}))$/, //default mask value and phone_pattern
    mask: '000-000-0000'
  };

  let phoneRules = [
    {
      message: 'The phone number entered is not correct',
      pattern: phoneProperties.pattern
    }
  ];

  if (required) {
    phoneRules.push(required_rules);
  }

  return (
    <FormItem
      label={label}
      name={`${fieldsPrefix}PhoneNumber`}
      rules={phoneRules}
      required={required}
      validateTrigger={'onBlur'}
      style={{ marginBottom: '0px' }}
    >
      <SpacedMaskedInput
        autoComplete="off"
        placeholder={placeholder}
        mask={phoneProperties.mask}
        className="phone-input"
        allowClear
        onChange={handleOnChange}
      />
    </FormItem>
  );
}

export default memo(Phone);
