import React, { useMemo } from 'react';
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  APPLICANT_NOMENCLATURE_PREFIX_MAP,
  STATUS_AUTH_PENDING,
  STATUS_SMS_SEND
} from 'redux/app/constants';
import {
  makeSelectCustomerExpirationDate,
  makeSelectCustomerIdentity,
  makeSelectCustomerIdentityAddressSelection,
  makeSelectCustomerIdentityAlerts,
  makeSelectCustomerIdentityError,
  makeSelectCustomerIdentityIsComplete,
  makeSelectCustomerIdentityIsExpired,
  makeSelectCustomerIdentityIsFetching,
  makeSelectEnterApplicantDataManuallyData,
  makeSelectFetchingProgressStatusId,
  makeSelectFetchingProgressStatusTitle,
  makeSelectPhoneProvided
} from 'redux/app/selectors';

export function useGetData(
  dataSelector,
  dataIsFetchingSelector,
  dataAction,
  dataActionParams = [],
  condition = true
) {
  const dispatch = useDispatch();
  const [actionCalled, setActionCalled] = useState(false);

  const data = useSelector(dataSelector);
  const isFetching = useSelector(dataIsFetchingSelector);

  const canDispatchAction = !data && !isFetching && condition && !actionCalled;

  useEffect(() => {
    if (canDispatchAction) {
      dispatch(dataAction(...dataActionParams));
      setActionCalled(true);
    }
  }, [canDispatchAction, dataAction, dataActionParams, dispatch]);

  return [data, isFetching];
}

/**
 * Hook to get customer identity data
 *
 * @param {string} applicantType
 */
export const useEnterApplicantDataManuallyData = applicantType => {
  const selectEnterApplicantDataManuallyData = useMemo(
    makeSelectEnterApplicantDataManuallyData,
    []
  );

  const enterApplicantDataManuallyData = useSelector(state =>
    selectEnterApplicantDataManuallyData(state, applicantType)
  );

  return enterApplicantDataManuallyData;
};

/**
 * Hook to get customer identity data
 *
 * @param {string} applicantType
 */
export const useCustomerIdentity = applicantType => {
  const selectCustomerIdentity = useMemo(makeSelectCustomerIdentity, []);

  const customerIdentity = useSelector(state =>
    selectCustomerIdentity(state, applicantType)
  );

  return customerIdentity;
};

/**
 * Hook to get customer identity data
 *
 * @param {string} applicantType
 */
export const useCustomerIdentityIsFetching = applicantType => {
  const selectCustomerIdentityIsFetching = useMemo(
    makeSelectCustomerIdentityIsFetching,
    []
  );

  const isFetching = useSelector(state =>
    selectCustomerIdentityIsFetching(state, applicantType)
  );

  return isFetching;
};

/**
 * Hook to get customer identity error message if has it
 *
 * @param {string} applicantType
 */
export const useCustomerIdentityError = applicantType => {
  const selectCustomerIdentityError = useMemo(
    makeSelectCustomerIdentityError,
    []
  );

  const errorMessage = useSelector(state =>
    selectCustomerIdentityError(state, applicantType)
  );

  return errorMessage;
};

export const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useGetURLParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Hook to get processing status description by customerId
 *
 * @param {string} applicantType
 */
export const useProgressStatusTitle = applicantType => {
  const selectFetchingProgressStatusTitle = useMemo(
    makeSelectFetchingProgressStatusTitle,
    []
  );
  const fetchingProgressStatusTitle = useSelector(state =>
    selectFetchingProgressStatusTitle(state, applicantType)
  );

  const selectFetchingProgressStatusId = useMemo(
    makeSelectFetchingProgressStatusId,
    []
  );
  const fetchingProgressStatusId = useSelector(state =>
    selectFetchingProgressStatusId(state, applicantType)
  );

  const selectPhoneProvided = useMemo(makeSelectPhoneProvided, []);
  const phoneProvided = useSelector(state =>
    selectPhoneProvided(state, applicantType)
  );

  switch (fetchingProgressStatusId) {
    case STATUS_SMS_SEND:
    case STATUS_AUTH_PENDING:
      if (phoneProvided && !phoneProvided.includes('_')) {
        let last4Digit = phoneProvided.slice(phoneProvided.length - 4);
        fetchingProgressStatusTitle.subtitle = `A text message with a link was just sent to the phone ending in ${last4Digit}`;
      }
      break;

    default:
      break;
  }

  return fetchingProgressStatusTitle;
};

/**
 * Hook to get customer identity getting data is completed
 *
 * @param {string} applicantType
 */
export const useCustomerIdentityIsComplete = applicantType => {
  const selectCustomerIdentityIsComplete = useMemo(
    makeSelectCustomerIdentityIsComplete,
    []
  );

  const customerIdentityIsComplete = useSelector(state =>
    selectCustomerIdentityIsComplete(state, applicantType)
  );

  return customerIdentityIsComplete;
};

/**
 * Hook to get customer identity getting data is completed
 *
 * @param {string} applicantType
 */
export const useCustomerIdentityIsExpired = applicantType => {
  const selectCustomerIdentityIsExpired = useMemo(
    makeSelectCustomerIdentityIsExpired,
    []
  );

  const customerIdentityIsExpired = useSelector(state =>
    selectCustomerIdentityIsExpired(state, applicantType)
  );

  return customerIdentityIsExpired;
};

/**
 * Hook to get customer identity multiple address to select one
 *
 * @param {string} applicantType
 */
export const useCustomerIdentityAddressSelection = applicantType => {
  const selectCustomerIdentityAddressSelection = useMemo(
    makeSelectCustomerIdentityAddressSelection,
    []
  );

  const customerIdentityAddressSelection = useSelector(state =>
    selectCustomerIdentityAddressSelection(state, applicantType)
  );

  return customerIdentityAddressSelection;
};

/**
 * Hook to get customer identity Alerts
 *
 * @param {string} applicantType
 */

export const useCustomerIdentityAlerts = applicantType => {
  const selectCustomerIdentityAlerts = useMemo(
    makeSelectCustomerIdentityAlerts,
    []
  );

  const customerIdentityAlerts = useSelector(state =>
    selectCustomerIdentityAlerts(state, applicantType)
  );

  return customerIdentityAlerts;
};

/**
 * Hook to get customer expiration date request
 *
 * @param {string} applicantType
 */
export const useCustomerExpirationDate = applicantType => {
  const selectCustomerExpirationDate = useMemo(
    makeSelectCustomerExpirationDate,
    []
  );

  const customerExpirationDate = useSelector(state =>
    selectCustomerExpirationDate(state, applicantType)
  );

  return customerExpirationDate;
};
