import React, { memo } from 'react';
import moment from 'moment';
import IMask from 'imask';

import { FormItem } from 'components/common/Form';
import { DATE_FORMAT } from 'theme/variables';
import DatePicker from 'components/common/DatePicker';

function defaultValue(current) {
  return moment().subtract(18, 'years');
}

function DayOfBirth({ required_rules, required = true, fieldsPrefix }) {
  const dob_rules = [];

  if (required) {
    dob_rules.push(required_rules);
  }

  return (
    <FormItem
      label="Date of Birth"
      name={`${fieldsPrefix}DateOfBirth`}
      rules={dob_rules}
      required={required}
    >
      <DatePicker
        style={{ width: '100%' }}
        showToday={false}
        format={DATE_FORMAT}
        defaultPickerValue={defaultValue()}
      />
    </FormItem>
  );
}

export default memo(DayOfBirth);
