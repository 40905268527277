import { ROUTES } from 'consts/routes';
export const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
export const CONSUMER_RIGHTS_NOTICE_URL =
  process.env.REACT_APP_CONSUMER_RIGHTS_NOTICE_URL;
export const DISTANCE_OPTIONS = process.env.REACT_APP_DISTANCE_OPTIONS;

export const APP_NAME = 'applynow';

export const MESSAGE_SUCCESS_STATUS_NOMENCLATURE = 'success';
export const MESSAGE_ERROR_STATUS_NOMENCLATURE = 'error';

export const TIMES = {
  MINUTE: '60000',
  HALF_MINUTE: '30000',
  QUARTER_MINUTE: '15000',
  SECOND: '1000',
  FIVE_MINUTES: '300000',
  MINUTES_2: '120000'
};

export const UNKNOWN_ALERT_NOMENCLATURE = 0;
export const ERROR_ALERT_NOMENCLATURE = 1;
export const WARNING_ALERT_NOMENCLATURE = 2;
export const INFORMATION_ALERT_NOMENCLATURE = 3;
export const SUCCESS_ALERT_NOMENCLATURE = 4;

export const APPLICANT_PREFIX = 'applicant';
export const CO_APPLICANT_PREFIX = 'coApplicant';
export const SALES_REP_PREFIX = 'salesRep';
export const APPLICATION_DETAILS_PREFIX = 'applicationDetails';

export const BORROWER_NOMENCLATURE = '18';
export const COBORROWER_NOMENCLATURE = '28';

export const APPLY_FROM_DEALER_NOMENCLATURE = 1;
export const APPLY_FROM_CUSTOMER_NOMENCLATURE = 2;
export const APPLY_FROM_QR_CODE_NOMENCLATURE = 3;
export const APPLY_FROM_IDENTITY_VERIFICATION_NOMENCLATURE = 4;
export const APPLY_FROM_BD_CODE_NOMENCLATURE = 5;
export const APPLY_FROM_BE_CODE_NOMENCLATURE = 6;

export const GETTING_DATA_STATUS = {
  OFF: 'Off',
  IN_PROGRESS: 'In progress',
  COMPLETED: 'Completed'
};

export const GETTING_DATA_COMPLETED_STATUS = 'Completed';

export const APPLICANT_TYPES = [BORROWER_NOMENCLATURE, COBORROWER_NOMENCLATURE];

export const APPLICANT_TITLE = {
  [BORROWER_NOMENCLATURE]: 'Applicant Information',
  [COBORROWER_NOMENCLATURE]: 'Co-Applicant Information'
};

export const APPLICANT_NOMENCLATURE_PREFIX_MAP = {
  [BORROWER_NOMENCLATURE]: APPLICANT_PREFIX,
  [COBORROWER_NOMENCLATURE]: CO_APPLICANT_PREFIX
};

export const legalLinks = [
  ROUTES.STATEMENT,
  ROUTES.DISCLOSURES,
  ROUTES.COPPA_DISCLOSURE,
  ROUTES.PRIVACY_POLICY,
  ROUTES.CONSUMER_RIGHTS_NOTICE
];

export const legalLinksName = {
  [ROUTES.STATEMENT]:
    'Service Finance’s Electronic Records Disclosure and Consent',
  [ROUTES.DISCLOSURES]: 'General Application Disclosures',
  [ROUTES.COPPA_DISCLOSURE]: 'COPPA Disclosure',
  [ROUTES.PRIVACY_POLICY]: 'Privacy Policy',
  [ROUTES.CONSUMER_RIGHTS_NOTICE]: 'Consumer Rights Notice'
};

export const APPLY_FORM_RESULT_SUCCESS = 'Success';
export const APPLY_FORM_RESPONSE_COLOR_GREEN = 'GREEN';
export const APPLY_FORM_RESPONSE_COLOR_RED = 'RED';
export const APPLY_FORM_RESPONSE_COLOR_ORANGE = 'ORANGE';
export const APPLY_FORM_RESPONSE_COLOR_BLACK = 'BLACK';

export const RESET_APP = 'applynow/App/RESET_APP';

export const ADD_COAPPLICANT = 'applynow/App/ADD_COAPPLICANT';
export const SAVE_APPLY_FORM_VALUES = 'applynow/App/SAVE_APPLY_FORM_VALUES';

export const VALIDATE_ADDRESS = 'applynow/App/VALIDATE_ADDRESS';
export const VALIDATE_ADDRESS_SUCCESS = 'applynow/App/VALIDATE_ADDRESS_SUCCESS';
export const VALIDATE_ADDRESS_FAILED = 'applynow/App/VALIDATE_ADDRESS_FAILED';

export const SUBMIT_APPLY_FORM = 'applynow/App/SUBMIT_APPLY_FORM';
export const SUBMIT_APPLY_FORM_SUCCESS =
  'applynow/App/SUBMIT_APPLY_FORM_SUCCESS';
export const SUBMIT_APPLY_FORM_FAILED = 'applynow/App/SUBMIT_APPLY_FORM_FAILED';

export const SHOW_ADDRESS_VALIDATION = 'applynow/App/SHOW_ADDRESS_VALIDATION';
export const RESET_VALIDATED_ADDRESS = 'applynow/App/RESET_VALIDATED_ADDRESS';
export const CONFIRMED_ADDRESSES = 'applynow/App/CONFIRMED_ADDRESSES';

export const RESET_RESULTS = 'applynow/App/RESET_RESULTS';

export const GET_VERIFY_PRODUCT_BY_DEALER =
  'applynow/App/GET_VERIFY_PRODUCT_BY_DEALER';
export const GET_VERIFY_PRODUCT_BY_DEALER_SUCCESS =
  'applynow/App/GET_VERIFY_PRODUCT_BY_DEALER_SUCCESS';
export const GET_VERIFY_PRODUCT_BY_DEALER_FAILED =
  'applynow/App/GET_VERIFY_PRODUCT_BY_DEALER_FAILED';

export const PUT_VERIFY_PRODUCT_BY_DEALER =
  'applynow/App/PUT_VERIFY_PRODUCT_BY_DEALER';
export const PUT_VERIFY_PRODUCT_BY_DEALER_SUCCESS =
  'applynow/App/PUT_VERIFY_PRODUCT_BY_DEALER_SUCCESS';
export const PUT_VERIFY_PRODUCT_BY_DEALER_FAILED =
  'applynow/App/PUT_VERIFY_PRODUCT_BY_DEALER_FAILED';

export const ENTER_APPLICANT_DATA_MANUALLY =
  'applynow/App/ENTER_APPLICANT_DATA_MANUALLY';

export const SKIP_INPUT_FORM = 'applynow/App/SKIP_INPUT_FORM';

export const GET_CUSTOMER_IDENTITY = 'applynow/App/GET_CUSTOMER_IDENTITY';
export const GET_CUSTOMER_IDENTITY_SUCCESS =
  'applynow/App/GET_CUSTOMER_IDENTITY_SUCCESS';
export const GET_CUSTOMER_IDENTITY_DONE =
  'applynow/App/GET_CUSTOMER_IDENTITY_DONE';
export const GET_CUSTOMER_IDENTITY_STOP =
  'applynow/App/GET_CUSTOMER_IDENTITY_STOP';
export const GET_CUSTOMER_IDENTITY_FAILED =
  'applynow/App/GET_CUSTOMER_IDENTITY_FAILED';
export const SAVE_CUSTOMER_IDENTITY_ADDRESS =
  'applynow/App/SAVE_CUSTOMER_IDENTITY_ADDRESS';

export const STATUS_NONE = 0;
export const STATUS_CREATE = 1;
export const STATUS_AUTH_PENDING = 2;
export const STATUS_SMS_SEND = 3;
export const STATUS_AUTH_COMPLETED = 4;
export const STATUS_AUTH_FAIL_BY_PATH = 5;
export const STATUS_SEARCHING = 6;
export const STATUS_NOT_ELEGIBLE = 7;
export const STATUS_COMPLETED = 8;

export const IN_PROGRESS_STATUS_STARTING_TOOLTIP_TEXT = {
  title: 'Starting the process to find your information.',
  subtitle: 'Please wait a few seconds.'
};

export const IN_PROGRESS_STATUS_UNKNOWN_TOOLTIP_TEXT = {
  title: `We're currently unable to determine the status of your information request.`,
  subtitle: 'Please enter the information manually or contact us.'
};

export const IN_PROGRESS_STATUS_TOOLTIP_TEXT = {
  [STATUS_NONE]: {
    title: 'The status of your information request is currently unknown.',
    subtitle: 'Please contact us.'
  },
  [STATUS_CREATE]: {
    title: 'We are initiating the process to search for your information.',
    subtitle: 'Please wait a few seconds.'
  },
  [STATUS_AUTH_PENDING]: {
    title: 'Verification of your mobile carrier information is required.',
    subtitle: 'A text message with a link was just sent to the phone provided.'
  },
  [STATUS_SMS_SEND]: {
    title: 'We need to confirm your mobile carrier details.',
    subtitle: 'Please check the message sent to the phone provided.'
  },
  [STATUS_AUTH_FAIL_BY_PATH]: {
    title:
      'The verification sent to your provided phone number has encountered an issue.',
    subtitle: 'Please try again or contact us.'
  },
  [STATUS_SEARCHING]: {
    title: 'We are currently processing your information.',
    subtitle: 'Please wait a few seconds.'
  },
  [STATUS_NOT_ELEGIBLE]: {
    title: `We couldn't locate any information associated with your data.`,
    subtitle: 'Enter the information manually.'
  },
  [STATUS_COMPLETED]: {
    title: 'Your information search has been successfully completed.',
    subtitle: 'Thank you very much for your patience.'
  }
};

export const IN_PROGRESS_STATES = [
  STATUS_CREATE,
  STATUS_AUTH_PENDING,
  STATUS_SMS_SEND,
  STATUS_AUTH_COMPLETED,
  STATUS_SEARCHING
];

export const COMPLETED_STATES = [
  STATUS_NONE,
  STATUS_AUTH_FAIL_BY_PATH,
  STATUS_NOT_ELEGIBLE,
  STATUS_COMPLETED
];

export const SET_CUSTOMER_IDENTITY_ERROR_MESSAGE =
  'applynow/App/SET_CUSTOMER_IDENTITY_ERROR_MESSAGE';

export const RESET_DATA_CUSTOMER_IDENTITY =
  'applynow/App/RESET_DATA_CUSTOMER_IDENTITY';

export const PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL =
  'applynow/App/PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL';
export const PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_SUCCESS =
  'applynow/App/PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_SUCCESS';
export const PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_FAILED =
  'applynow/App/PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_FAILED';
export const SET_VERIFICATION_PARAMS = 'applynow/App/SET_VERIFICATION_PARAMS';
export const RESET_VERIFICATION_PARAMS =
  'applynow/App/RESET_VERIFICATION_PARAMS';

export const SHOW_ADDRESS_LIST_TO_SELECT =
  'applynow/App/SHOW_ADDRESS_LIST_TO_SELECT';
export const CLOSE_ADDRESS_LIST_TO_SELECT =
  'applynow/App/CLOSE_ADDRESS_LIST_TO_SELECT';

export const SHOW_ADD_COAPPLICANT_MODAL =
  'applynow/App/SHOW_ADD_COAPPLICANT_MODAL';

export const NOT_CALLED = 'NOT_CALLED';
export const CALLING = 'CALLING';
export const CALLED_WITH_SUCCESS = 'CALLED_WITH_SUCCESS';
export const CALLED_WITH_ERROR = 'CALLED_WITH_ERROR';
export const CALLED_WITH_SUCCESS_BUT_NOT_VERIFIED =
  'CALLED_WITH_SUCCESS_BUT_NOT_VERIFIED';
export const LOADING_STATES = [NOT_CALLED, CALLING];

export const SET_FOR_CUSTOMER = 'applynow/App/SET_FOR_CUSTOMER';
export const SET_FOR_QR_CODE = 'applynow/App/SET_FOR_QR_CODE';
export const SET_FOR_BD_CODE = 'applynow/App/SET_FOR_BD_CODE';
export const SET_FOR_BE_CODE = 'applynow/App/SET_FOR_BE_CODE';

export const SET_FOR_VERIFICATION_CODE =
  'applynow/App/SET_FOR_VERIFICATION_CODE';

export const STOPPED_CURRENT_APPLICATION =
  'applynow/App/STOPPED_CURRENT_APPLICATION';

export const INIT_RETRIEVE_APPLICATION_DATA =
  'applynow/App/INIT_RETRIEVE_APPLICATION_DATA';

export const INIT_RETRIEVE_APPLICATION_DATA_SUCCESS =
  'applynow/App/INIT_RETRIEVE_APPLICATION_SUCCESS';

export const INIT_RETRIEVE_APPLICATION_DATA_FAILED =
  'applynow/App/INIT_RETRIEVE_APPLICATION_FAILED';

export const SAVE_PREVIOUS_DATA = 'applynow/App/SAVE_PREVIOUS_DATA';
export const SET_ERROR_MESSAGE = 'applynow/App/SET_ERROR_MESSAGE';
export const SET_MESSAGE = 'applynow/App/SET_MESSAGE';
export const SET_APPLICANT_CONSENT_APPROVED =
  'applynow/App/SET_APPLICANT_CONSENT_APPROVED';

//call report
export const GET_CALL_REPORT = 'applynow/App/GET_CALL_REPORT';
export const RELOAD_CALL_REPORT = 'applynow/App/RELOAD_CALL_REPORT';
export const GET_CALL_REPORT_SUCCESS = 'applynow/App/GET_CALL_REPORT_SUCCESS';
export const GET_CALL_REPORT_FAILED = 'applynow/App/GET_CALL_REPORT_FAILED';

export const SET_SEARCH_TEXT = 'applynow/App/SET_SEARCH_TEXT';
export const SET_ASSOCIATION_FORM_INFO =
  'applynow/App/SET_ASSOCIATION_FORM_INFO';
export const SET_CURRENT_ASSOCIATION_NUMBER =
  'applynow/App/SET_CURRENT_ASSOCIATION_NUMBER';

export const ASSOCIATION_SERVICE_NAME = 'DealersByZipCodeAndAss';
export const CALL_REPORT_OPERATOR_TYPE = {
  EQUAL_TO: 1,
  NOT_EQUAL_TO: 2,
  GREATER_THAN: 3,
  LESS_THAN: 4,
  GREATER_THAN_OR_EQUAL_TO: 5,
  LESS_THAN_OR_EQUAL_TO: 6,
  IN: 7,
  NOT_IN: 19,
  STARTS_WITH: 8,
  ENDS_WITH: 9,
  CONTAINS: 10,
  BETWEEN: 11,
  DOES_NOT_CONTAIN: 12,
  IS_EMPTY: 13,
  IS_NOT_EMPTY: 14,
  IS_NOT_NULL: 15,
  IS_NOT_NULL_NOR_WHITE_SPACE: 16,
  IS_NULL: 17,
  IS_NULL_OR_WHITE_SPACE: 18
};

export const DEFAULT_PAGE_SIZE = 5;
export const DEFAULT_PAGE = 1;
export const PAGINATION_CONFIG = {
  position: ['topRight'],
  size: 'small',
  showSizeChanger: true,
  pageSizeOptions: ['5', '10', '20', '30', '40', '50']
};

export const GET_MAP_BY_COORDINATES = 'applynow/App/GET_MAP_BY_COORDINATES';
export const CLOSE_ADDRESS_MAP = 'applynow/App/CLOSE_ADDRESS_MAP';
