import React from 'react';
import { Checkbox } from 'antd';
import { FormItem } from 'components/common/Form';
import { Text } from 'components/common/Typography';
import { FONT_SIZE_SMALL } from 'theme/variables';

function PhoneConcentVerified({
  fieldsPrefix,
  required,
  required_rules,
  onChangeFunction
}) {
  let phoneRules = [];

  if (required) {
    phoneRules.push(required_rules);
  }

  return (
    <>
      <FormItem
        name={`${fieldsPrefix}PhoneVerified`}
        valuePropName="checked"
        rules={phoneRules}
      >
        <Checkbox
          onChange={onChangeFunction}
          style={{ fontSize: FONT_SIZE_SMALL }}
        >
          By clicking "I agree to the mobile carrier disclosure":
        </Checkbox>
      </FormItem>
      <Text style={{ fontSize: FONT_SIZE_SMALL }}>
        You authorize Service Finance to send you a one-time text to the mobile
        phone you provided and for your wireless carrier to use or disclose
        information about your account and your wireless device, is available,
        to Service Finance or its service provider for the duration of your
        business relationship, solely to help them identify you or your wireless
        device and to prevent fraud. See our Privacy Policy for how we treat
        your data. Standard text messaging rates may apply.
      </Text>
    </>
  );
}

export default PhoneConcentVerified;
