import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useProcessedDealerId = () => {
  const { dealerId } = useParams();

  return dealerId;
};

/**
 * Hook to get element height from top, adjusted by 100vh - height.
 * Includes computed style top and bottom properties in calculation
 * @param {Object} elementRef - Ref to the element
 * @returns {Number} - Height after subtracting distance from 100vh
 */
// export const useElementDistanceFromTop = (elementRef = {}) => {
//   const [adjustedHeight, setAdjustedHeight] = useState(0);
//   const { current } = elementRef;

//   useEffect(() => {
//     if (current) {
//       const rect = current.getBoundingClientRect();
//       const computedStyle = window.getComputedStyle(current);

//       // Calculate the total distance from top, including margin, border, and padding
//       let heightFromTop = rect.top;
//       heightFromTop += parseInt(computedStyle.marginTop, 10);
//       heightFromTop += parseInt(computedStyle.borderTopWidth, 10);
//       heightFromTop += parseInt(computedStyle.paddingTop, 10);

//       // Calculate the remaining height after subtracting from 100vh
//       const viewportHeight = window.innerHeight;
//       const heightAfterCalculation = viewportHeight - heightFromTop - 220;

//       setAdjustedHeight(heightAfterCalculation);
//     }
//   }, [current]);

//   return adjustedHeight;
// };

export const useElementDistanceFromTop = (elementRef = {}) => {
  const [distance, setDistance] = useState(0);
  const { current } = elementRef || {};

  useEffect(() => {
    if (current) {
      const heightFromTop = current?.getBoundingClientRect()?.top;
      let value = heightFromTop;

      const computedStyled = window.getComputedStyle(current);
      value += parseInt(computedStyled.marginTop);
      value += parseInt(computedStyled.marginBottom);
      value += parseInt(computedStyled.borderTop);
      value += parseInt(computedStyled.borderBottom);
      value += parseInt(computedStyled.paddingTop);
      value += parseInt(computedStyled.paddingBottom);

      setDistance(value);
    }
  }, [current]);

  const viewportHeight = window.innerHeight;
  const heightAfterCalculation = viewportHeight - distance - 220;

  return heightAfterCalculation;
};
