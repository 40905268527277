import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useCustomerIdentityAlerts,
  useCustomerIdentityIsComplete,
  useCustomerIdentityIsExpired,
  useCustomerIdentityIsFetching
} from 'hooks/data';

import { Col, Row } from 'antd';
import { SubTitle } from 'components/common/Typography';

import SSN from '../Apply/Form/Components/SSN';
import Phone from '../Apply/Form/Components/Phone';
import { FirstName, LastName } from '../Apply/Form/Components/Names';

import Fieldset from '../Apply/Form/Components/Fieldset';
import RemoveCoApplicantButton from '../Apply/Form/Components/RemoveCoApplicantButton';

import { COBORROWER_NOMENCLATURE } from 'redux/app/constants';
import { GUTTER_DEFAULT } from 'theme/variables';
import DayOfBirth from '../Apply/Form/Components/DayOfBirth';
import EnterDataOption from '../Apply/Components/EnterDataOption';
import Alerts from 'components/common/Alerts';
import FetchingCustomer from './FetchingCustomer';
import {
  selectIsForBDCode,
  selectIsForQRCode,
  selectIsForBECode,
  selectPhoneConsentApproved
} from 'redux/app/selectors';
import { FindCustomerDataButton } from '../Apply/Form/FormButtons';
import PhoneConcentVerified from '../Apply/Form/Components/PhoneConcentVerified';
import { setPhoneConsentApproved } from 'redux/app/actions';

function CustomerIdentityForm({
  form,
  fieldsPrefix,
  required_rules,
  applicantType,
  title
}) {
  const dispatch = useDispatch();
  const customerIdentityAlerts = useCustomerIdentityAlerts(applicantType);
  const customerIdentityIsExpired = useCustomerIdentityIsExpired(applicantType);
  const customerIdentityIsComplete =
    useCustomerIdentityIsComplete(applicantType);
  const isForQRCode = useSelector(selectIsForQRCode);
  const isForBDCode = useSelector(selectIsForBDCode);
  const isForBECode = useSelector(selectIsForBECode);

  const isCoApplicant = applicantType === COBORROWER_NOMENCLATURE;

  const customerIdentityIsFetching =
    useCustomerIdentityIsFetching(applicantType);

  const phoneConsentApproved = useSelector(selectPhoneConsentApproved);

  const handlePhoneApproved = () => {
    dispatch(setPhoneConsentApproved());
  };

  return (
    <Fieldset title={title}>
      <FetchingCustomer resend cancel applicantType={applicantType}>
        {customerIdentityIsComplete || customerIdentityIsExpired ? (
          <Alerts
            alerts={customerIdentityAlerts}
            style={{ marginBottom: '5px' }}
          />
        ) : null}
        <EnterDataOption applicantType={applicantType} />
        {!isForBDCode && !isForBECode && (
          <Row gutter={GUTTER_DEFAULT}>
            {/* First Name */}
            <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 16 }}>
              {/* FIRST NAME */}
              <FirstName
                fieldsPrefix={fieldsPrefix}
                required={true}
                required_rules={required_rules}
                form={form}
              />
            </Col>

            {/* Last Name */}
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <LastName
                fieldsPrefix={fieldsPrefix}
                required={true}
                required_rules={required_rules}
                form={form}
              />
            </Col>
          </Row>
        )}

        <Row gutter={GUTTER_DEFAULT}>
          {/* SSN */}
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <SSN
              fieldsPrefix={fieldsPrefix}
              required_rules={required_rules}
              form={form}
              // extra={
              //   <SubTitle>
              //     By providing your social security number, Service Finance will
              //     attempt to find your information to expedite your request.
              //   </SubTitle>
              // }
            />
          </Col>

          {/* Mobile Number */}
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Phone
              label="Mobile Number"
              placeholder="Mobile Number"
              fieldsPrefix={fieldsPrefix}
              required_rules={required_rules}
              required={true}
              // extra={ VERSION 1
              // <SubTitle>
              //   You consent to Service Finance sending you a one-time text to
              //   the mobile phone number you have provided. Standard text
              //   messaging rates may apply.
              // </SubTitle>
              // }
              form={form}
            />
          </Col>

          {!isForBDCode && !isForBECode && (
            <>
              <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <DayOfBirth
                  fieldsPrefix={fieldsPrefix}
                  required_rules={required_rules}
                  required={false}
                />
              </Col>
              <Col xs={{ span: 24 }}>
                <PhoneConcentVerified
                  fieldsPrefix={fieldsPrefix}
                  required={true}
                  required_rules={required_rules}
                  onChangeFunction={handlePhoneApproved}
                />
              </Col>
            </>
          )}
          {!isForQRCode && !isForBDCode && (
            <Col xs={{ span: 24 }}>
              <FindCustomerDataButton
                fieldsPrefix={fieldsPrefix}
                form={form}
                applicantType={applicantType}
                loading={customerIdentityIsFetching}
                disabled={!phoneConsentApproved}
                style={{ marginTop: 20 }}
              />
              {isCoApplicant ? (
                <RemoveCoApplicantButton style={{ marginLeft: '10px' }} />
              ) : null}
            </Col>
          )}
        </Row>
      </FetchingCustomer>
    </Fieldset>
  );
}

export default memo(CustomerIdentityForm);
