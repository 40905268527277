import styled from 'styled-components';

import { Tabs as TabsAnt } from 'antd';
import { ACTION_COLOR_HOVER, FONT_COLOR, FONT_WEIGHT } from 'theme/variables';

const Tabs = styled(TabsAnt)`
  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: ${FONT_COLOR};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${FONT_COLOR};
    font-weight: ${FONT_WEIGHT};
  }

  .ant-tabs-tab {
    text-transform: uppercase;
    &:hover {
      color: ${ACTION_COLOR_HOVER};
    }
  }
`;

export default Tabs;
