import React from 'react';
import styled from 'styled-components';

import { Typography, Row } from 'antd';
import ContainerFluid from '../Content/ContainerFluid';
import { BigText } from '../Typography';

import inscription from 'images/inscription.jpeg';

import { BREAKPOINT_MD, BREAKPOINT_LG, BREAKPOINT_XL } from 'theme/variables';

const WrapperImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  background: url(${inscription});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  min-height: 590px;
  height: auto;
  width: 100%;

  box-sizing: border-box;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(0, 212, 255, 0) 78%
    );
  }

  @media (max-width: ${BREAKPOINT_LG}px) {
    flex-direction: column;
    height: 540px;
  }

  @media (max-width: ${BREAKPOINT_MD}px) {
    height: auto;
  }
`;

const ColumnMainTitle = styled.div`
  box-sizing: border-box;
  text-align: left;
  max-width: 645px;
  width: 100%;

  @media (max-width: ${BREAKPOINT_XL}px) {
    max-width: 380px;
  }

  @media (max-width: ${BREAKPOINT_LG}px) {
    display: none;
  }
`;

const ColumnForm = styled.div`
  padding: 0;
`;

const WrapperForm = styled.div`
  position: absolute;
  top: 30px;
  right: 40px;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 470px;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
  }

  &:before {
    top: -101px;
    height: 48px;
    border-radius: 0 0 5px 5px;
    background: #008c59;
  }

  @media (max-width: ${BREAKPOINT_LG}px) {
    &:before {
      display: none;
    }
    position: relative;
    top: auto;
    margin: 0 auto;
    right: 0;
    left: 0;
  }
`;

export const LayerForm = ({ children, ...props }) => {
  return (
    <Row align="middle">
      <WrapperImg>
        <ContainerFluid mg="0 auto" pd="40px">
          <ColumnMainTitle>
            <Typography.Title level={1} style={{ marginBottom: 20 }}>
              Financing made <u>simple</u>!
            </Typography.Title>
            <Typography.Title level={3} style={{ marginBottom: 10 }}>
              See if you prequalify with no impact to your credit.
            </Typography.Title>
            <BigText>
              Service Finance Company, LLC is a nationally licensed sales
              finance company, providing financing solutions to home improvement
              contractors enrolled in the SFC Financing Program.
            </BigText>
          </ColumnMainTitle>
          <ColumnForm>
            <WrapperForm>{children}</WrapperForm>
          </ColumnForm>
        </ContainerFluid>
      </WrapperImg>
    </Row>
  );
};
