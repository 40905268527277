import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { Spin, Statistic, Typography } from 'antd';
import Alerts from 'components/common/Alerts';

import RequestOptions from '../Apply/Components/RequestOptions';

import {
  useCustomerExpirationDate,
  useCustomerIdentityAlerts,
  useCustomerIdentityIsFetching
} from 'hooks/data';

import {
  DATE_TIME_FORMAT,
  FONT_COLOR_LIGHT,
  FONT_SIZE_SMALL
} from 'theme/variables';
import { getCustomerIdentity } from 'redux/app/actions';
import moment from 'moment';

const { Paragraph, Text } = Typography;
const { Countdown } = Statistic;

function FetchingCustomer({ children, applicantType, ...props }) {
  const dispatch = useDispatch();
  const customerIdentityAlerts = useCustomerIdentityAlerts(applicantType);
  const customerIdentityIsFetching =
    useCustomerIdentityIsFetching(applicantType);
  const customerExpirationDate = useCustomerExpirationDate(applicantType);

  const [showCountdown, setShowCountdown] = useState(true);

  useEffect(() => {
    if (
      customerIdentityIsFetching ||
      (customerExpirationDate &&
        moment(customerExpirationDate, DATE_TIME_FORMAT).isAfter(moment()))
    ) {
      setShowCountdown(true);
    }
  }, [customerExpirationDate, customerIdentityIsFetching]);

  const handleOnFinishTime = () => {
    setShowCountdown(false);
    dispatch(getCustomerIdentity({ applicantType }));
  };

  return (
    <Spin
      key={customerExpirationDate}
      spinning={customerIdentityIsFetching}
      wrapperClassName="customer-container-spin"
      size="large"
      tip={
        <>
          {!isEmpty(customerIdentityAlerts) ? (
            <div style={{ maxHeight: '100px', overflow: 'auto' }}>
              <Alerts alerts={customerIdentityAlerts} isFetchingAlert />
            </div>
          ) : (
            <Paragraph style={{ fontSize: FONT_SIZE_SMALL }}>
              <Text strong>Sending data.. </Text>
            </Paragraph>
          )}
          {customerExpirationDate ? (
            showCountdown ? (
              <Countdown
                key={customerExpirationDate}
                value={customerExpirationDate}
                format={'m:ss'}
                onFinish={handleOnFinishTime}
                prefix="Expire in"
                suffix="min."
                valueStyle={{
                  marginTop: '10px',
                  color: FONT_COLOR_LIGHT,
                  fontSize: FONT_SIZE_SMALL
                }}
              />
            ) : (
              <Paragraph
                style={{
                  margin: '10px 0 0',
                  color: FONT_COLOR_LIGHT,
                  fontSize: FONT_SIZE_SMALL
                }}
              >
                <Text>The time is expired</Text>{' '}
              </Paragraph>
            )
          ) : null}
          <RequestOptions applicantType={applicantType} {...props} />
        </>
      }
    >
      {children}
    </Spin>
  );
}

export default memo(FetchingCustomer);
