import { LOCATION_CHANGE } from 'connected-react-router';
import { fromJS, Map } from 'immutable';
import { combineReducers } from 'redux-immutable';
import {
  formatCustomerIdentity,
  formatCustomerIdentityAddress,
  formatPreviousCustomerIdentity
} from 'utils/functions';

import {
  RESET_APP,
  ADD_COAPPLICANT,
  SUBMIT_APPLY_FORM,
  SUBMIT_APPLY_FORM_FAILED,
  SUBMIT_APPLY_FORM_SUCCESS,
  VALIDATE_ADDRESS_SUCCESS,
  VALIDATE_ADDRESS,
  VALIDATE_ADDRESS_FAILED,
  RESET_VALIDATED_ADDRESS,
  SAVE_APPLY_FORM_VALUES,
  CO_APPLICANT_PREFIX,
  SHOW_ADDRESS_VALIDATION,
  CONFIRMED_ADDRESSES,
  RESET_RESULTS,
  GET_VERIFY_PRODUCT_BY_DEALER_FAILED,
  GET_VERIFY_PRODUCT_BY_DEALER_SUCCESS,
  GET_VERIFY_PRODUCT_BY_DEALER,
  PUT_VERIFY_PRODUCT_BY_DEALER,
  PUT_VERIFY_PRODUCT_BY_DEALER_SUCCESS,
  PUT_VERIFY_PRODUCT_BY_DEALER_FAILED,
  ENTER_APPLICANT_DATA_MANUALLY,
  GET_CUSTOMER_IDENTITY_SUCCESS,
  GET_CUSTOMER_IDENTITY,
  GET_CUSTOMER_IDENTITY_FAILED,
  BORROWER_NOMENCLATURE,
  COBORROWER_NOMENCLATURE,
  SET_CUSTOMER_IDENTITY_ERROR_MESSAGE,
  GET_CUSTOMER_IDENTITY_DONE,
  GET_CUSTOMER_IDENTITY_STOP,
  PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL,
  PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_SUCCESS,
  PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_FAILED,
  SHOW_ADDRESS_LIST_TO_SELECT,
  CLOSE_ADDRESS_LIST_TO_SELECT,
  SAVE_CUSTOMER_IDENTITY_ADDRESS,
  SHOW_ADD_COAPPLICANT_MODAL,
  SET_VERIFICATION_PARAMS,
  CALLING,
  NOT_CALLED,
  CALLED_WITH_ERROR,
  CALLED_WITH_SUCCESS,
  SET_FOR_CUSTOMER,
  SET_FOR_QR_CODE,
  SET_FOR_BD_CODE,
  SET_FOR_BE_CODE,
  SET_FOR_VERIFICATION_CODE,
  RESET_DATA_CUSTOMER_IDENTITY,
  STOPPED_CURRENT_APPLICATION,
  APPLY_FROM_CUSTOMER_NOMENCLATURE,
  APPLY_FROM_QR_CODE_NOMENCLATURE,
  APPLY_FROM_DEALER_NOMENCLATURE,
  INIT_RETRIEVE_APPLICATION_DATA,
  INIT_RETRIEVE_APPLICATION_DATA_SUCCESS,
  INIT_RETRIEVE_APPLICATION_DATA_FAILED,
  SAVE_PREVIOUS_DATA,
  SET_ERROR_MESSAGE,
  GETTING_DATA_STATUS,
  SET_APPLICANT_CONSENT_APPROVED,
  SET_MESSAGE,
  APPLY_FROM_IDENTITY_VERIFICATION_NOMENCLATURE,
  APPLY_FROM_BD_CODE_NOMENCLATURE,
  STATUS_COMPLETED,
  CALLED_WITH_SUCCESS_BUT_NOT_VERIFIED,
  RESET_VERIFICATION_PARAMS,
  SKIP_INPUT_FORM,
  APPLY_FROM_BE_CODE_NOMENCLATURE,
  GET_CALL_REPORT,
  GET_CALL_REPORT_SUCCESS,
  GET_CALL_REPORT_FAILED,
  RELOAD_CALL_REPORT,
  SET_SEARCH_TEXT,
  SET_ASSOCIATION_FORM_INFO,
  SET_CURRENT_ASSOCIATION_NUMBER,
  CLOSE_ADDRESS_MAP,
  GET_MAP_BY_COORDINATES
} from './constants';

const hasCoApplicantReducer = (state = false, action) => {
  switch (action.type) {
    case ADD_COAPPLICANT:
      return action.payload;
    case RESET_APP:
    case SET_FOR_QR_CODE:
    case SET_FOR_BD_CODE:
    case SET_FOR_BE_CODE:
      return false;
    default:
      return state;
  }
};

const applyFormIsFetchingReducer = (state = false, action) => {
  switch (action.type) {
    case SUBMIT_APPLY_FORM:
      return true;
    case SUBMIT_APPLY_FORM_FAILED:
    case SUBMIT_APPLY_FORM_SUCCESS:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const applyFormErrorReducer = (state = null, action) => {
  switch (action.type) {
    case SUBMIT_APPLY_FORM:
    case RESET_APP:
    case SUBMIT_APPLY_FORM_SUCCESS:
      return null;
    case SUBMIT_APPLY_FORM_FAILED:
      return action.error;
    default:
      return state;
  }
};

const applyFormResultReducer = (state = null, action) => {
  switch (action.type) {
    case SUBMIT_APPLY_FORM_SUCCESS:
      return fromJS(action.payload);
    case SUBMIT_APPLY_FORM:
    case SUBMIT_APPLY_FORM_FAILED:
    case RESET_RESULTS:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const isValidatingAllAdressesReducer = (state = false, action) => {
  switch (action.type) {
    case VALIDATE_ADDRESS:
      return true;
    case VALIDATE_ADDRESS_SUCCESS:
    case VALIDATE_ADDRESS_FAILED:
    case SUBMIT_APPLY_FORM_FAILED:
    case SUBMIT_APPLY_FORM_SUCCESS:
    case RESET_APP:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
};

const validatedAddressesReducer = (state = null, action) => {
  switch (action.type) {
    case VALIDATE_ADDRESS_SUCCESS:
      return action.payload;
    case VALIDATE_ADDRESS:
    case VALIDATE_ADDRESS_FAILED:
    case RESET_VALIDATED_ADDRESS:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const confirmedAddressesReducer = (state = null, action) => {
  switch (action.type) {
    case CONFIRMED_ADDRESSES:
      return action.payload;
    case RESET_VALIDATED_ADDRESS:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const applyFormValuesReducer = (state = null, action) => {
  switch (action.type) {
    case SAVE_PREVIOUS_DATA:
      return fromJS({
        ...formatPreviousCustomerIdentity(action.payload)
      });
    case GET_CUSTOMER_IDENTITY_DONE:
      return fromJS({
        ...state.toJS(),
        ...formatCustomerIdentity(action.payload)
      });
    case SAVE_CUSTOMER_IDENTITY_ADDRESS:
      const {
        payload: { applicantType, customerAddress }
      } = action;
      return fromJS({
        ...state.toJS(),
        ...formatCustomerIdentityAddress(applicantType, customerAddress)
      });
    case SAVE_APPLY_FORM_VALUES:
      return fromJS(action.payload);
    case ADD_COAPPLICANT:
      if (!action.payload) {
        const source = state?.toJS();
        if (source) {
          const value = Object.keys(source).reduce(
            (output, key) =>
              key.includes(CO_APPLICANT_PREFIX)
                ? output
                : { ...output, [key]: source[key] },
            {}
          );

          return fromJS(value);
        } else return state;
      }

      return state;
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

export const showAddressValidationReducer = (state = false, action) => {
  switch (action.type) {
    case SHOW_ADDRESS_VALIDATION:
      return action.payload;
    case SUBMIT_APPLY_FORM:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const verifyProductByDealerReducer = (state = null, action) => {
  switch (action.type) {
    case GET_VERIFY_PRODUCT_BY_DEALER_SUCCESS:
      return fromJS(action.payload);
    case PUT_VERIFY_PRODUCT_BY_DEALER_SUCCESS:
    case GET_VERIFY_PRODUCT_BY_DEALER_FAILED:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const isFetchingVerifyProductByDealerReducer = (state = false, action) => {
  switch (action.type) {
    case GET_VERIFY_PRODUCT_BY_DEALER:
      return true;
    case GET_VERIFY_PRODUCT_BY_DEALER_SUCCESS:
    case GET_VERIFY_PRODUCT_BY_DEALER_FAILED:
    case RESET_APP:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
};

const dealerNumberFormErrorReducer = (state = null, action) => {
  switch (action.type) {
    case GET_VERIFY_PRODUCT_BY_DEALER_FAILED:
    case PUT_VERIFY_PRODUCT_BY_DEALER_FAILED:
      return action.error;
    case RESET_APP:
    case GET_VERIFY_PRODUCT_BY_DEALER_SUCCESS:
    case PUT_VERIFY_PRODUCT_BY_DEALER_SUCCESS:
      return null;
    default:
      return state;
  }
};

const dealerNumberFormVerifyDataErrorReducer = (state = null, action) => {
  switch (action.type) {
    case PUT_VERIFY_PRODUCT_BY_DEALER_FAILED:
      return action.error;
    case RESET_APP:
    case LOCATION_CHANGE:
    case GET_VERIFY_PRODUCT_BY_DEALER_FAILED:
    case PUT_VERIFY_PRODUCT_BY_DEALER_SUCCESS:
      return null;
    default:
      return state;
  }
};

const isFetchingPutVerifyDealerDataReducer = (state = false, action) => {
  switch (action.type) {
    case PUT_VERIFY_PRODUCT_BY_DEALER:
      return true;
    case PUT_VERIFY_PRODUCT_BY_DEALER_SUCCESS:
    case PUT_VERIFY_PRODUCT_BY_DEALER_FAILED:
    case RESET_APP:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
};

const manuallyInit = {
  [BORROWER_NOMENCLATURE]: false,
  [COBORROWER_NOMENCLATURE]: false
};

const enterApplicantDataManuallyDataReducer = (
  state = new Map(manuallyInit),
  action
) => {
  const { type, payload: { applicantType, value } = {} } = action;

  switch (type) {
    case ENTER_APPLICANT_DATA_MANUALLY:
      return state.set(+applicantType, value);
    case RESET_APP:
      return new Map(manuallyInit);
    default:
      return state;
  }
};

const customerIdentityReducer = (state = new Map(), action) => {
  const { type, payload } = action;

  switch (type) {
    case INIT_RETRIEVE_APPLICATION_DATA:
    case GET_CUSTOMER_IDENTITY_DONE:
    case GET_CUSTOMER_IDENTITY_SUCCESS:
      const { applicantType } = payload;
      return state.set(applicantType, fromJS(payload));
    case RESET_DATA_CUSTOMER_IDENTITY:
    case GET_CUSTOMER_IDENTITY_STOP:
    case GET_CUSTOMER_IDENTITY_FAILED:
      return state.delete(payload?.applicantType);
    case RESET_APP:
      return new Map();
    default:
      return state;
  }
};

const customerIdentityIsFetchingReducer = (state = new Map(), action) => {
  const { type, payload: { applicantType } = {} } = action;

  switch (type) {
    case GET_CUSTOMER_IDENTITY:
      return state.set(+applicantType, true);
    case GET_CUSTOMER_IDENTITY_DONE:
    case GET_CUSTOMER_IDENTITY_STOP:
    case GET_CUSTOMER_IDENTITY_FAILED:
      return state.set(+applicantType, false);
    case RESET_APP:
      return new Map();
    default:
      return state;
  }
};

const customerIdentityErrorReducer = (state = new Map(), action) => {
  const { type, payload: { applicantType, message } = {} } = action;

  switch (type) {
    case GET_CUSTOMER_IDENTITY:
      return state.set(+applicantType, null);
    case SET_CUSTOMER_IDENTITY_ERROR_MESSAGE:
      return state.set(+applicantType, message);
    case RESET_APP:
    case SAVE_APPLY_FORM_VALUES:
      return new Map();
    default:
      return state;
  }
};

const customerIdentityInProgressRequestReducer = (
  state = new Map(),
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case INIT_RETRIEVE_APPLICATION_DATA:
    case GET_CUSTOMER_IDENTITY_SUCCESS:
      return state.set(payload.applicantType, {
        requestId: fromJS(payload.requestId),
        status: fromJS(payload.status?.Id)
      });
    case GET_CUSTOMER_IDENTITY_DONE:
      return state.set(payload.applicantType, {
        requestId: null,
        status: fromJS(payload.status?.Id)
      });
    case ENTER_APPLICANT_DATA_MANUALLY:
      return state.delete(payload.applicantType);
    case RESET_APP:
      return new Map();
    default:
      return state;
  }
};

const selectMobileVerificationStateReducer = (state = NOT_CALLED, action) => {
  switch (action.type) {
    case PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_SUCCESS:
      return action.payload?.status?.Id === STATUS_COMPLETED
        ? CALLED_WITH_SUCCESS
        : CALLED_WITH_SUCCESS_BUT_NOT_VERIFIED;
    case PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_FAILED:
      return CALLED_WITH_ERROR;
    case PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL:
      return CALLING;
    case RESET_APP:
    case LOCATION_CHANGE:
      return NOT_CALLED;
    default:
      return state;
  }
};

const putMobileVerificationAsSuccessfulIsFetchingReducer = (
  state = true,
  action
) => {
  switch (action.type) {
    case PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL:
      return true;
    case PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_SUCCESS:
    case PUT_MOBILE_VERIFICATION_AS_SUCCESSFUL_FAILED:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const verificationParamsReducer = (state = null, action) => {
  switch (action.type) {
    case SET_VERIFICATION_PARAMS:
      return action.payload;
    case RESET_VERIFICATION_PARAMS:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

export const showMultipleAddressSelectionReducer = (state = null, action) => {
  switch (action.type) {
    case SHOW_ADDRESS_LIST_TO_SELECT:
      return action.payload;
    case CLOSE_ADDRESS_LIST_TO_SELECT:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

export const showAddCoApplicantModalReducer = (state = false, action) => {
  switch (action.type) {
    case SHOW_ADD_COAPPLICANT_MODAL:
      return action.payload;
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const stoppedCurrentApplicationReducer = (state = false, action) => {
  switch (action.type) {
    case STOPPED_CURRENT_APPLICATION:
      return true;
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const retrieveApplicationDataReducer = (
  state = GETTING_DATA_STATUS.OFF,
  action
) => {
  switch (action.type) {
    case INIT_RETRIEVE_APPLICATION_DATA:
      return GETTING_DATA_STATUS.IN_PROGRESS;
    case INIT_RETRIEVE_APPLICATION_DATA_SUCCESS:
    case INIT_RETRIEVE_APPLICATION_DATA_FAILED:
      return GETTING_DATA_STATUS.COMPLETED;
    case RESET_APP:
      return GETTING_DATA_STATUS.OFF;
    default:
      return state;
  }
};

export const applyFromReducer = (
  state = APPLY_FROM_DEALER_NOMENCLATURE,
  action
) => {
  switch (action.type) {
    case SET_FOR_CUSTOMER:
      return APPLY_FROM_CUSTOMER_NOMENCLATURE;
    case SET_FOR_QR_CODE:
      return APPLY_FROM_QR_CODE_NOMENCLATURE;
    case SET_FOR_VERIFICATION_CODE:
      return APPLY_FROM_IDENTITY_VERIFICATION_NOMENCLATURE;
    case SET_FOR_BD_CODE:
      return APPLY_FROM_BD_CODE_NOMENCLATURE;
    case SET_FOR_BE_CODE:
      return APPLY_FROM_BE_CODE_NOMENCLATURE;
    case RESET_APP:
      return APPLY_FROM_DEALER_NOMENCLATURE;
    default:
      return state;
  }
};

export const errorMessageReducer = (state = null, action) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return action.payload;
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

export const messageReducer = (state = null, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return action.payload;
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const phoneConsentApprovedReducer = (state = false, action) => {
  switch (action.type) {
    case SET_APPLICANT_CONSENT_APPROVED:
      return !state;
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

export const skipFormReducer = (state = null, action) => {
  switch (action.type) {
    case RESET_APP:
      return false;
    case SKIP_INPUT_FORM:
      return true;
    default:
      return state;
  }
};

//assocaitions call report
const associationCallReportIsFetchingReducer = (state = false, action) => {
  switch (action.type) {
    case GET_CALL_REPORT:
      return true;
    case GET_CALL_REPORT_SUCCESS:
    case GET_CALL_REPORT_FAILED:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const associationReloadCallReportIsFetchingReducer = (
  state = false,
  action
) => {
  switch (action.type) {
    case RELOAD_CALL_REPORT:
      return true;
    case GET_CALL_REPORT_SUCCESS:
    case GET_CALL_REPORT_FAILED:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const errorCallReportReducer = (state = null, action) => {
  switch (action.type) {
    case GET_CALL_REPORT_FAILED:
      return action.payload;
    case GET_CALL_REPORT:
    case RELOAD_CALL_REPORT:
    case GET_CALL_REPORT_SUCCESS:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};
const associationCallReportReducer = (state = null, action) => {
  switch (action.type) {
    case GET_CALL_REPORT_SUCCESS:
      return action.payload;
    case GET_CALL_REPORT_FAILED:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};
const searchTextReducer = (state = '', action) => {
  switch (action.type) {
    case SET_SEARCH_TEXT:
      return action.payload;
    case RESET_APP:
      return '';
    default:
      return state;
  }
};
const associationFormInfoReducer = (
  state = { distance: 25, zipCode: '' },
  action
) => {
  switch (action.type) {
    case SET_ASSOCIATION_FORM_INFO:
      return action.payload;
    // case RESET_APP:
    //   return { distance: 25, zipCode: '' };
    default:
      return state;
  }
};

const associationNumberReducer = (state = null, action) => {
  switch (action.type) {
    case SET_CURRENT_ASSOCIATION_NUMBER:
      return action.payload;
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const addressMapReducer = (
  state = {
    open: false
  },
  action
) => {
  switch (action.type) {
    case GET_MAP_BY_COORDINATES:
      return {
        open: true,
        data: action.payload
      };

    case CLOSE_ADDRESS_MAP:
    case RESET_APP:
      return {
        open: false
      };
    default:
      return state;
  }
};

export default combineReducers({
  hasCoApplicant: hasCoApplicantReducer,
  applyFormIsFetching: applyFormIsFetchingReducer,
  applyFormResult: applyFormResultReducer,
  applyFormError: applyFormErrorReducer,
  showAddressValidation: showAddressValidationReducer,
  isValidatingAllAdresses: isValidatingAllAdressesReducer,
  validatedAddresses: validatedAddressesReducer,
  confirmedAddresses: confirmedAddressesReducer,
  applyFormValues: applyFormValuesReducer,
  verifyProductByDealer: verifyProductByDealerReducer,
  isFetchingVerifyProductByDealer: isFetchingVerifyProductByDealerReducer,
  dealerNumberFormError: dealerNumberFormErrorReducer,
  dealerNumberFormVerifyDataError: dealerNumberFormVerifyDataErrorReducer,
  isFetchingPutVerifyDealerData: isFetchingPutVerifyDealerDataReducer,
  enterApplicantDataManuallyValue: enterApplicantDataManuallyDataReducer,
  customerIdentity: customerIdentityReducer,
  customerIdentityIsFetching: customerIdentityIsFetchingReducer,
  customerIdentityError: customerIdentityErrorReducer,
  customerIdentityInProgressRequest: customerIdentityInProgressRequestReducer,
  mobileVerificationState: selectMobileVerificationStateReducer,
  putMobileVerificationAsSuccessfulIsFetching:
    putMobileVerificationAsSuccessfulIsFetchingReducer,
  verificationParams: verificationParamsReducer,
  showMultipleAddressSelection: showMultipleAddressSelectionReducer,
  showAddCoApplicantModal: showAddCoApplicantModalReducer,
  stoppedCurrentApplication: stoppedCurrentApplicationReducer,
  applyFrom: applyFromReducer,
  retrieveApplicationData: retrieveApplicationDataReducer,
  errorMessage: errorMessageReducer,
  phoneConsentApproved: phoneConsentApprovedReducer,
  message: messageReducer,
  skipForm: skipFormReducer,
  associationCallReportIsFetching: associationCallReportIsFetchingReducer,
  associationReloadCallReportIsFetching:
    associationReloadCallReportIsFetchingReducer,
  associationCallReport: associationCallReportReducer,
  searchText: searchTextReducer,
  associationFormInfo: associationFormInfoReducer,
  errorCallReport: errorCallReportReducer,
  associationNumber: associationNumberReducer,
  addressMap: addressMapReducer
});
