import React from 'react';
import styled from 'styled-components';

import Spin from 'components/common/Spin';
import Footer from 'components/layouts/Footer';

import {
  BOX_SHADOW,
  BACKGROUND_DEFAULT,
  BORDER_RADIUS,
  WIDTH_BREAKPOINT_SM,
  WIDTH_BREAKPOINT_MD
} from 'theme/variables';

export const DocumentsContainer = styled.div`
  flex: 1;
  min-height: 100vh;
  overflow: hidden;
`;

export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
`;

const CenteredDiv = styled.div`
  width: ${props => (props.width ? props.width : '90%')};
  height: ${props => (props.height ? props.height : '90%')};
  background: ${props => (props.bg ? props.bg : BACKGROUND_DEFAULT)};
  box-shadow: ${BOX_SHADOW};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${BORDER_RADIUS};
  margin: 30px auto;

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
    & {
      width: 100%;
      height: 100%;
    }
  }
`;

const FooterDiv = styled.div``;
const Content = styled.div`
  flex: auto;
  height: 90%;
  // padding-right: 20px;
  ${props => (props.applyOverflow ? 'overflow-y: auto;' : '')}

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_MD}) {
    padding-right: 0;
  }
`;

const Centered = ({
  withSpinner = false,
  loading = false,
  applyOverflow = false,
  footerLinks = true,
  ...props
}) => {
  return (
    <CenteredContainer className="centered">
      <CenteredDiv {...props}>
        <Content applyOverflow={applyOverflow}>
          {withSpinner ? (
            <Spin spinning={loading}>{props.children}</Spin>
          ) : (
            props.children
          )}
        </Content>
        <FooterDiv>
          <Footer footerLinks={footerLinks} />
        </FooterDiv>
      </CenteredDiv>
    </CenteredContainer>
  );
};

export default Centered;
