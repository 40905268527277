import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from 'hooks/responsive';

import { Result, Spin } from 'antd';
import TwoColumns from 'components/common/Content/TwoColumns';

import { putMobileVerificationAsSuccessful } from 'redux/app/actions';

import verification_success_img from 'images/verification_success.svg';
import verification_failed_img from 'images/verification_failed.svg';

import {
  selectMobileVerificationHasError,
  selectMobileVerificationIsLoading,
  selectMobileVerificationSuccesful,
  selectMobileVerificationURLError,
  selectVerificationParams,
  selectMobileVerificationSuccesfulNoVerificated
} from 'redux/app/selectors';
import { Button } from 'components/common/Buttons';
import { replace } from 'connected-react-router';
import { ROUTES } from 'consts/routes';

function MessageComponent() {
  const dispatch = useDispatch();

  const verificationParams = useSelector(selectVerificationParams);

  const isLoading = useSelector(selectMobileVerificationIsLoading);
  const finishedWithError = useSelector(selectMobileVerificationHasError);
  const finishedWithSuccess = useSelector(selectMobileVerificationSuccesful);
  const finishedWithSuccessNoVerificated = useSelector(
    selectMobileVerificationSuccesfulNoVerificated
  );
  const hasURLError = useSelector(selectMobileVerificationURLError);

  const requestId = verificationParams?.requestId;
  const vfp = verificationParams?.vfp;
  const hasParams = !!requestId && !!vfp;

  const isMobile = useIsMobile();
  const image_width = isMobile ? 200 : 348;

  useEffect(() => {
    if (hasParams) {
      dispatch(putMobileVerificationAsSuccessful({ requestId, vfp }));
    }
  }, []);

  if (hasURLError) {
    return (
      <Result
        title="Sorry, you must enter a valid verification URL."
        subTitle="Or you can start our application in this option."
        status="error"
        extra={
          <Button type="primary" onClick={() => dispatch(replace(ROUTES.HOME))}>
            Init Apply Form
          </Button>
        }
      />
    );
  }

  return (
    <Result
      title="We are verifying your data."
      subTitle="Please wait a few seconds."
      icon={<Spin size="large" spinning={true} />}
      status="info"
    />
  );

  // if (isLoading) {
  //   return (
  //     <Result
  //       title="We are checking your verification."
  //       subTitle="Please wait a few seconds."
  //       icon={<Spin size="large" spinning={true} />}
  //       status="info"
  //     />
  //   );
  // }

  // if (finishedWithError) {
  // return (
  //   <Result
  //     title="We're sorry, something was failed."
  //     subTitle="Please retry and if the problem persist contact our company."
  //     icon={
  //       <img
  //         alt="Failed verification"
  //         src={verification_failed_img}
  //         width={image_width}
  //       />
  //     }
  //     status="error"
  //   />
  // );
  // }

  // create new message for success for apply from 4

  // if (finishedWithError || finishedWithSuccessNoVerificated) {
  //   return (
  //     <Result
  //       title="Identity Verification was unsuccessful."
  //       subTitle="Customer Identity could not be verified at this time."
  //       icon={
  //         <img
  //           alt="Unsuccessful verification"
  //           src={verification_failed_img}
  //           width={image_width}
  //         />
  //       }
  //     />
  //   );
  // }

  // if (finishedWithSuccess) {
  //   return (
  //     <Result
  //       title="Identity Verification was successful."
  //       subTitle="Mobile Identity verification completed."
  //       icon={
  //         <img
  //           alt="Successful verification"
  //           src={verification_success_img}
  //           width={image_width}
  //         />
  //       }
  //     />
  //   );
  // }

  // if (hasURLError) {
  //   return (
  //     <Result
  //       title="Sorry, you must enter a valid verification URL."
  //       subTitle="Or you can start our application in this option."
  //       status="error"
  //       extra={
  //         <Button type="primary" onClick={() => dispatch(replace(ROUTES.HOME))}>
  //           Init Apply Form
  //         </Button>
  //       }
  //     />
  //   );
  // }

  // return (
  //   <Result
  //     title="We're sorry, a problem has occured."
  //     subTitle="Retry, or please contact our company for help."
  //     status="info"
  //     extra={
  //       <Button type="primary" onClick={() => dispatch(replace(ROUTES.HOME))}>
  //         Init Apply Form
  //       </Button>
  //     }
  //   />
  // );
}

function VerificationTreatment() {
  return (
    <TwoColumns md={18} showSecondColumn={false}>
      <MessageComponent />
    </TwoColumns>
  );
}

export default memo(VerificationTreatment);
