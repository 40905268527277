import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';

import { getDistanceText } from '../../utils';

import { Divider, Space, Typography } from 'antd';

import Collapse from 'components/common/Collapse';
import List from 'components/common/List';
import NoData from './NoData';
import { ApplyNowButton, ContactInfo, DataSourceTotal, Search } from '.';

import { FONT_SIZE_SMALL } from 'theme/variables';
import { PAGINATION_CONFIG } from 'redux/app/constants';
import ShowInMap from './ShowInMap';

const { Panel } = Collapse;
const { Text } = Typography;

function DealersList({ dataSource, dataSourceIsFetching, ...props }) {
  const [activeKey, setActiveKey] = useState(0);

  const renderListItems = item => {
    const { contact = {}, distance = null } = item;
    const { address = {} } = contact;

    const distanceText = getDistanceText(distance);
    const addressText = address?.fullAddress || 'Unknown address';

    return (
      <List.Item key={uuidv4()} style={{ padding: '5px 0' }}>
        <Collapse
          collapsible="header"
          defaultActiveKey={activeKey}
          expandIconPosition="end"
          onChange={() => setActiveKey(item.dealerNumber)}
        >
          <Panel
            key={item.dealerNumber}
            header={
              <>
                {item.companyName || 'Unknown company'}
                {distanceText[true] && (
                  <Text strong style={{ display: 'block' }}>
                    {distanceText[true]}
                  </Text>
                )}
              </>
            }
            collapsible={true}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <div>
                <Text
                  strong
                  style={{ fontSize: FONT_SIZE_SMALL, display: 'block' }}
                >
                  {address?.city || 'Unknown city'}
                </Text>
                <Text style={{ fontSize: FONT_SIZE_SMALL, display: 'block' }}>
                  {addressText}
                </Text>
                <ShowInMap
                  address={addressText}
                  gpsCoordinates={address?.gpsCoordinates}
                  isValid={address?.isValid}
                  style={{ padding: 0 }}
                />
              </div>
              <ContactInfo contact={contact} />
              <Divider style={{ margin: 0 }} />
              <ApplyNowButton dealerNumber={item.dealerNumber} />
            </Space>
          </Panel>
        </Collapse>
      </List.Item>
    );
  };

  const pagination = {
    ...PAGINATION_CONFIG,
    position: 'top',
    total: dataSource?.length,
    showTotal: total => total,
    simple: true,
    size: 'small',
    style: { fontSize: FONT_SIZE_SMALL },
    pageSize: 5,
    showSizeChanger: false
  };

  return (
    <Space direction="vertical" size={5} style={{ display: 'flex' }}>
      <Search
        style={{
          width: '100%'
        }}
      />
      <Divider style={{ margin: 0 }} />
      {!isEmpty(dataSource) ? (
        <>
          <DataSourceTotal
            total={dataSource?.length}
            style={{ position: 'absolute', margin: '10px 0 0' }}
          />
          <List
            dataSource={dataSource}
            renderItem={item => renderListItems(item)}
            pagination={pagination}
            loading={dataSourceIsFetching}
            split={false}
            itemLayout="vertical"
            style={{ overflowY: 'auto', height: '100vh', paddingBottom: 450 }}
            {...props}
          />
        </>
      ) : (
        <NoData />
      )}
    </Space>
  );
}

export default DealersList;
