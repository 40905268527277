// Websocket general constants
export const WS_INIT = 'merlin/Signalr/WS_INIT';
export const WS_CONNECTED = 'merlin/Signalr/WS_CONNECTED';
export const WS_RETRY_CONNECTION = 'merlin/Signalr/WS_RETRY_CONNECTION';
export const WS_RECONNECTING = 'merlin/Signalr/WS_RECONNECTING';
export const WS_RECONNECTED = 'merlin/Signalr/WS_RECONNECTED';
export const WS_CLOSED = 'merlin/Signalr/WS_CLOSE';
export const WS_STOP = 'merlin/Signalr/WS_STOP';
export const WS_ERROR = 'merlin/Signalr/WS_ERROR';
export const WS_HUB_URL = process.env.REACT_APP_API_FORSETI_PATH;
export const MAX_RETRY = 5;
export const RETRY_TIME = 2000;

export const WS_STATUS_DISCONNECTED = 'disconnected';
export const WS_STATUS_CONNECTED = 'connected';

// Hubs
export const WS_HUB_NOTIFY = 'Notify';
export const WS_HUB_OPEN_ITEM_DATA = 'OpenIdentityData';
export const WS_HUB_CLOSE_ITEM_DATA = 'CloseIdentityData';
export const WS_HUB_MULTIPLE_OPEN_ITEM_DATA = 'MOpenIData';
export const WS_HUB_MULTIPLE_CLOSE_ITEM_DATA = 'MCloseIData';

// Notifications
export const WS_SEND_NOTIFICATION = 'merlin/Signalr/WS_SEND_NOTIFICATION';
export const WS_NOTIFICATION_RECEIVED =
  'merlin/Signalr/WS_NOTIFICATION_RECEIVED';
export const WS_NOTIFICATION_SIMPLE_STORAGE_DELETE = 4;
export const WS_NOTIFICATION_SIMPLE_STORAGE_UPDATE = 3;
export const WS_NOTIFICATION_SIMPLE_STORAGE_CREATE = 2;
export const WS_NOTIFICATION_CONTRACT_VALIDATION_UPDATE = 5;
export const WS_NOTIFICATION_CUSTOMER_IDENTITY_PROCESS = 6;

// Open Item Data
export const WS_OPEN_ITEM_DATA_RECEIVED =
  'merlin/Signalr/WS_OPEN_ITEM_DATA_RECEIVED';
export const WS_SAVE_OPEN_ITEM_DATA_RECEIVED =
  'merlin/Signalr/WS_SAVE_OPEN_ITEM_DATA_RECEIVED';

// Close Item Data
export const WS_CLOSE_ITEM_DATA_RECEIVED =
  'merlin/Signalr/WS_CLOSE_ITEM_DATA_RECEIVED';
