import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import LayoutCentered from 'components/common/Content/LayoutCentered';
import FormBd from './FormBd';
import { LayerForm } from 'components/common/LayerForm';

import { resetApp, setForBdCode } from 'redux/app/actions';
import { useProcessedDealerId } from 'utils/hooks';
import { saveDealerNumber } from 'redux/tokens/actions';

function LandingPageForBd({ ...props }) {
  const dispatch = useDispatch();
  const dealerId = useProcessedDealerId();

  useEffect(() => {
    dispatch(resetApp());
    dispatch(saveDealerNumber(dealerId));
    dispatch(setForBdCode());
  }, []);

  return (
    <LayoutCentered type="formBd">
      <LayerForm>
        <FormBd />
      </LayerForm>
    </LayoutCentered>
  );
}

export default LandingPageForBd;
