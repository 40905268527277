import { useEffect, useRef } from 'react';

export function usePortal(id) {
  const rootElemRef = useRef(document.createElement('div'));

  useEffect(() => {
    const element = rootElemRef.current;
    // Look for existing target dom element to append to
    const parentElem = document.querySelector(`#${id}`);

    if (parentElem) {
      // Add the detached element to the parent
      parentElem.appendChild(rootElemRef.current);
      // This function is run on unmount
      return () => {
        element.remove();
      };
    }
  }, [id]);

  return rootElemRef.current;
}
