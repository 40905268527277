import React from 'react';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';
import history from 'utils/history';
import store from 'redux/store';
import enUS from 'antd/es/locale/en_US';
import { IntlProvider } from 'react-intl';
import { configAxiosInstances } from 'api/axios';

import GlobalStyles from 'theme/global-styles';

import { ConfigProvider } from 'antd';

import { MainContainer } from 'components/layouts';
import NotFound from 'components/common/Pages/NotFound';
import NotAuthorized from 'components/common/Pages/NotAuthorized';

import { ServiceWorkerProvider } from 'components/services/ServiceWorker';
import NewVersion from 'components/services/ServiceWorker/NewVersion';

import { ROUTES } from 'consts/routes';
import Apply from './views/Apply';
import Dealer from './views/Dealer';
import Centered from './layouts/Centered';
import Home from './views/Home';
import LegalInformation from './views/LegalInformation';
import {
  CONSUMER_RIGHTS_NOTICE_URL,
  PRIVACY_POLICY_URL
} from 'redux/app/constants';
import Verification from './views/Verification';
import VerificationTreatment from './views/Verification/VerificationTreatment';
import ErrorPage from './views/Apply/ResultMessage/ErrorPage';
import MessagePage from './views/Apply/ResultMessage/MessagePage';
import LandingPageForBd from './views/LandingPageForBd';
import LandingPageForBe from './views/LandingPageForBe';
import Associations from './views/Associations';

configAxiosInstances(store);

function WrapperCenter({ children }) {
  return (
    <MainContainer>
      <Centered>{children}</Centered>
    </MainContainer>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <IntlProvider locale="en-US" defaultLocale="en-US">
          <ConfigProvider locale={enUS}>
            <>
              <GlobalStyles />
              <ServiceWorkerProvider>
                <NewVersion />
              </ServiceWorkerProvider>
              <Switch>
                <Route path={ROUTES.VERIFICATION_WP}>
                  <WrapperCenter>
                    <Verification />
                  </WrapperCenter>
                </Route>
                <Route exact path={ROUTES.VERIFICATION_WOP}>
                  <WrapperCenter>
                    <VerificationTreatment />
                  </WrapperCenter>
                </Route>
                <Route exact path={ROUTES.APPLY_FORM}>
                  <WrapperCenter>
                    <Apply />
                  </WrapperCenter>
                </Route>
                <Route exact path={ROUTES.DEALER_ID}>
                  <WrapperCenter>
                    <Dealer />
                  </WrapperCenter>
                </Route>
                <Route exact path={ROUTES.CUSTOMER_ID}>
                  <WrapperCenter>
                    <Dealer forCustomer />
                  </WrapperCenter>
                </Route>
                <Route exact path={ROUTES.QR_CODE}>
                  <WrapperCenter>
                    <Dealer forQRCode />
                  </WrapperCenter>
                </Route>
                <Route exact path={ROUTES.BE_CODE}>
                  <LandingPageForBe />
                </Route>
                <Route exact path={ROUTES.BD_CODE}>
                  <LandingPageForBd />
                </Route>
                <Route exact path={ROUTES.ASSOCIATION_NUMBER}>
                  <WrapperCenter>
                    <Associations />
                  </WrapperCenter>
                </Route>
                <Route exact path={ROUTES.HOME}>
                  <WrapperCenter>
                    <Home />
                  </WrapperCenter>
                </Route>
                <Route exact path={ROUTES.NOT_AUTHORIZED}>
                  <WrapperCenter>
                    <NotAuthorized />
                  </WrapperCenter>
                </Route>
                <Route exact path={ROUTES.ERROR}>
                  <WrapperCenter>
                    <ErrorPage />
                  </WrapperCenter>
                </Route>
                <Route exact path={ROUTES.MESSAGE}>
                  <WrapperCenter>
                    <MessagePage />
                  </WrapperCenter>
                </Route>
                <Route
                  exact
                  path={[
                    ROUTES.STATEMENT,
                    ROUTES.DISCLOSURES,
                    ROUTES.COPPA_DISCLOSURE
                  ]}
                >
                  <LegalInformation />
                </Route>
                <Route
                  exact
                  path={ROUTES.PRIVACY_POLICY}
                  component={() => {
                    window.location.href = PRIVACY_POLICY_URL;
                    return null;
                  }}
                />
                <Route
                  exact
                  path={ROUTES.CONSUMER_RIGHTS_NOTICE}
                  component={() => {
                    window.location.href = CONSUMER_RIGHTS_NOTICE_URL;
                    return null;
                  }}
                />
                <Route
                  exact
                  path="/rdr/*"
                  component={() => {
                    fetch(window.location.href)
                      .then(resp => {
                        console.log(resp);
                      })
                      .catch(error => {
                        console.log(error);
                      });
                    return <Redirect to={ROUTES.HOME} />;
                  }}
                />

                <Route component={NotFound} />
              </Switch>
            </>
          </ConfigProvider>
        </IntlProvider>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
