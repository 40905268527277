import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button } from 'components/common/Buttons';
import Content from 'components/common/Content';
import { Title } from 'components/common/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { APPLICANT_PREFIX, CO_APPLICANT_PREFIX } from 'redux/app/constants';
import {
  selectApplicantAddress,
  selectApplicantValidatedAddress,
  selectCoApplicantAddress,
  selectCoApplicantValidatedAddress,
  selectIsApplicantAddressValid,
  selectIsCoApplicantAddressValid,
  selectShowAddressValidation,
  selectValidatedAddresses
} from 'redux/app/selectors';
import {
  setConfirmedAddresseses,
  showAddressValidation,
  submitApplyForm
} from 'redux/app/actions';
import ValidItem from './ValidItem';
import InvalidItem from './InvalidItem';

const { Modal, Form } = require('antd');

function ConfirmationModal({ onValidatedAddressAccepted }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const isCoApplicantAddressValid = useSelector(
    selectIsCoApplicantAddressValid
  );
  const isApplicantAddressValid = useSelector(selectIsApplicantAddressValid);
  const applicantValidatedAddress = useSelector(
    selectApplicantValidatedAddress
  );
  const coApplicantValidatedAddress = useSelector(
    selectCoApplicantValidatedAddress
  );
  const applicantAddress = useSelector(selectApplicantAddress);
  const coApplicantAddress = useSelector(selectCoApplicantAddress);
  const validatedAddresses = useSelector(selectValidatedAddresses);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    const isDisabled = Object.values(form.getFieldsValue(true)).some(
      item => !item
    );
    setIsSubmitDisabled(isDisabled);
  }, [form]);

  const handleClose = () => {
    dispatch(showAddressValidation(false));
  };

  const handleOnValuesChange = (changedValues, allValues) => {
    setIsSubmitDisabled(Object.values(allValues).some(item => !item));
  };

  const handleOnFinish = values => {
    let confirmedAddresses = {};
    if (typeof onValidatedAddressAccepted === 'function') {
      if (values.applicant) {
        if (values.applicant === 'validated') {
          onValidatedAddressAccepted(
            APPLICANT_PREFIX,
            validatedAddresses[0].verified
          );
        }

        confirmedAddresses[APPLICANT_PREFIX] = true;
      }

      if (values.coApplicant) {
        if (values.coApplicant === 'validated') {
          onValidatedAddressAccepted(
            CO_APPLICANT_PREFIX,
            validatedAddresses[1].verified
          );
        }
        confirmedAddresses[APPLICANT_PREFIX] = true;
      }
    }
    dispatch(setConfirmedAddresseses(confirmedAddresses));
    dispatch(submitApplyForm());
    handleClose();
  };

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <Modal
      open
      title={<Title style={{ marginBottom: '0px' }}>Address validation </Title>}
      closable
      centered
      onOk={handleSubmit}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" type="secondary" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          Continue
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOnFinish}
        onValuesChange={handleOnValuesChange}
      >
        {!isApplicantAddressValid ? (
          applicantAddress ? (
            <Content title="Applicant address" bordered={false}>
              {applicantValidatedAddress ? (
                <ValidItem
                  fieldName="applicant"
                  address={applicantAddress}
                  validAddress={applicantValidatedAddress}
                />
              ) : (
                <InvalidItem fieldName="applicant" address={applicantAddress} />
              )}
            </Content>
          ) : null
        ) : null}

        {!isCoApplicantAddressValid ? (
          coApplicantAddress ? (
            <Content title="Co Applicant address" bordered={false}>
              {coApplicantValidatedAddress ? (
                <ValidItem
                  fieldName="coApplicant"
                  address={coApplicantAddress}
                  validAddress={coApplicantValidatedAddress}
                />
              ) : (
                <InvalidItem
                  fieldName="coApplicant"
                  address={coApplicantAddress}
                />
              )}
            </Content>
          ) : null
        ) : null}
      </Form>
    </Modal>
  );
}

function ValidationAddressModal({ onValidatedAddressAccepted }) {
  const visible = useSelector(selectShowAddressValidation);

  return visible ? (
    <ConfirmationModal
      onValidatedAddressAccepted={onValidatedAddressAccepted}
    />
  ) : null;
}

export default memo(ValidationAddressModal);
