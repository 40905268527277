import React, { memo } from 'react';
import parse from 'html-react-parser';

import generalDisclosure from 'assets/Disclosure/General.html';
import patriotDisclosure from 'assets/Disclosure/Patriot.html';
import californiaResidents from 'assets/Disclosure/California.html';
import newYorkResidents from 'assets/Disclosure/NewYorkAndRhodeIsland.html';
import ohioResidents from 'assets/Disclosure/Ohio.html';
import vermontResidents from 'assets/Disclosure/Vermont.html';
import wisconsinResidents from 'assets/Disclosure/Winsconsin.html';

import { Divider } from 'antd';
import Tabs from 'components/common/Tabs';

const residents = [
  {
    label: 'California Residents',
    children: parse(californiaResidents),
    key: '1'
  },
  {
    label: 'New York and Rhode Island Residents',
    children: parse(newYorkResidents),
    key: '2'
  },
  {
    label: 'Ohio Residents',
    children: parse(ohioResidents),
    key: '3'
  },
  {
    label: 'Vermont Residents',
    children: parse(vermontResidents),
    key: '4'
  },
  {
    label: 'MARRIED WISCONSIN RESIDENTS',
    children: parse(wisconsinResidents),
    key: '5'
  }
];

export function Disclosures() {
  return (
    <>
      {parse(generalDisclosure)}
      <Tabs type="card" items={residents} />
      <Divider />
      {parse(patriotDisclosure)}
    </>
  );
}
export default memo(Disclosures);
