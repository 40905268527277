import React, { memo } from 'react';

import { FormItem } from 'components/common/Form';
import { Input } from 'antd';

const emailProperties = {
  pattern:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

function Email({
  label,
  fieldsPrefix,
  placeholder = false,
  required = false,
  required_rules,
  extra,
  handleOnChange
}) {
  let emailRules = [
    {
      message: 'The email entered is not correct',
      pattern: emailProperties.pattern
    }
  ];
  if (required) {
    emailRules.push(required_rules);
  }

  return (
    <FormItem
      label={label}
      required={required}
      extra={extra}
      name={`${fieldsPrefix}EmailAddress`}
      rules={emailRules}
    >
      <Input
        autoComplete="off"
        placeholder={placeholder}
        onChange={handleOnChange}
      />
    </FormItem>
  );
}

export default memo(Email);
