import React, { memo } from 'react';

import { Row, Col } from 'antd';

import Content from 'components/common/Content';

import { GUTTER_DEFAULT } from 'theme/variables';
import { Amount } from './Components/MonthlyIncome';
import { APPLICATION_DETAILS_PREFIX } from 'redux/app/constants';
import TypeOfLoan from './Components/TypeOfLoan';
import { useSelector } from 'react-redux';
import { selectIsForCustomer } from 'redux/app/selectors';

function ApplicationDetails({
  title,
  fieldsPrefix = APPLICATION_DETAILS_PREFIX,
  required_rules,
  form
}) {
  const isForCustomer = useSelector(selectIsForCustomer);
  return (
    <Content title={title ? title : null} bordered={false}>
      <Row gutter={GUTTER_DEFAULT}>
        {/* Requested Amount */}
        {!isForCustomer && (
          <Col xs={{ span: 24 }} lg={{ span: 16 }}>
            <Amount
              title={'RequestedAmount'}
              label={'Requested Amount'}
              placeholder={'Please insert a requested amount.'}
              fieldsPrefix={fieldsPrefix}
              required
              required_rules={required_rules}
              form={form}
            />
          </Col>
        )}
        <Col xs={{ span: 24 }}>
          <TypeOfLoan
            fieldsPrefix={fieldsPrefix}
            required={true}
            required_rules={required_rules}
            form={form}
          />
        </Col>
      </Row>
    </Content>
  );
}

export default memo(ApplicationDetails);
